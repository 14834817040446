import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';


const useStyles = makeStyles((theme) => ({
    blue: {
      '& > *': {
        marginTop: theme.spacing(2),
        width: '100%',
        background:  'linear-gradient(#2999FF, #03254B)',
        color: 'white',
        fontWeight: 'bold',
        fontSize: '16px',
        padding: '7px 0px',
        borderRadius: '10px',

      },
    },
    black: {
      '& > *': {
        marginTop: theme.spacing(2),
        width: '100%',
        background:  'linear-gradient(#6B6B6B, #2C2C2C)',
        color: 'white',
        fontWeight: 'bold',
        fontSize: '16px',
        padding: '7px 0px',
        borderRadius: '10px',
      },
    },
    red: {
      '& > *': {
        marginTop: theme.spacing(2),
        width: '100%',
        background:  'linear-gradient(#FF9797, #C42222)',
        color: 'white',
        fontWeight: 'bold',
        fontSize: '16px',
        padding: '7px 0px',
        borderRadius: '10px',
      },
    },
    orange: {
      '& > *': {
        marginTop: theme.spacing(2),
        width: '100%',
        background:  'linear-gradient(#FFA837, #EE4F24)',
        color: 'white',
        fontWeight: 'bold',
        fontSize: '16px',
        padding: '7px 0px',
        borderRadius: '6px',
      },
    }

  }));
  
export const BlueBtn = ({ name, onClick, className  }) => {
  const classes = useStyles();

  return (
    <div className={classes.blue + ' ' + className} >
      <Button variant="contained"  onClick={onClick} >{ name }</Button>
    </div>
  );
}

export const BlackBtn = ({ name, onClick, className }) => {
  const classes = useStyles();

  return (
    <div className={classes.black + ' ' + className}>
      <Button variant="contained" onClick={onClick}>{ name }</Button>
    </div>
  );
}

export const RedBtn = ({ name, onClick, className }) => {
  const classes = useStyles();

  return (
    <div className={classes.red + ' ' + className} >
      <Button variant="contained" onClick={onClick}>{ name }</Button>
    </div>
  );
}

export const OrangeBtn = ({ name, onClick, className }) => {
  const classes = useStyles();

  return (
    <div className={classes.orange + ' ' + className} >
      <Button variant="contained" onClick={onClick}>{ name }</Button>
    </div>
  );
}