import React, { useState } from 'react';
import { Logo, BellIcon, MessageIcon, LogoutIcon, SettingsIcon } from './Icons';
import { ImageAvatar } from './Common';
import { logoutUser } from '../redux/actions/authActions';
import {
  getMessage,
  viewSingleMessage,
  viewNotification
} from '../redux/actions/userActions';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import localizedStrings from '../utils/localizedStrings';
import MessagePopUp from './MessagePopUp';
import { globalActions } from '../redux/v2/global';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { Email, ExitToApp, Notifications } from '@material-ui/icons';
import DropDownItem from './dropdownitem';
import { IconButtonWrapper } from './dropdownitem/styles';

const Header = ({ propMenu, togglePropMenu }) => {
  const { user } = useSelector(state => state.global);

  const [notifications, setNotifications] = useState([]);
  const [messages, setMessages] = useState([]);

  const dispatch = useDispatch();
  const history = useHistory();

  const logout = async () => {
    await dispatch(globalActions.logout());
    history.push('/login');
  };


  return (
    <header>
      <Link to="/" className="logo-box admin-logo-box">
        {/* <Logo width="250" height="70" /> */}
        <img
          width="250"
          height="70"
          src="/images/mockup/cv-prica-logo.png"
          alt="CV Priča"
        />
      </Link>
      <div
        className={
          propMenu
            ? 'custom-burger hamburger hamburger--emphatic show-burger is-active'
            : 'custom-burger hamburger hamburger--emphatic show-burger'
        }
        id="burger"
        onClick={togglePropMenu}
      >
        <div className="hamburger-box">
          <div className="hamburger-inner"></div>
        </div>
      </div>

      <div className="nav-wrapper">
        <Link to="/settings" className="profile-wrapper">
          <ImageAvatar img={user.profile_image} alt={user.full_name} />
          <p>{user.full_name ? user.full_name : ''}</p>
        </Link>
        <div className="nav-btn-menu">
          <div className="btn-wrapper">
            <DropDownItem
              icon={Notifications}
              items={notifications}
              count={notifications.length}
              emptyMessage="Nemate notifikacije"
            />
          </div>

          <div className="btn-wrapper">
            <DropDownItem
              icon={Email}
              items={messages}
              count={messages.length}
              emptyMessage="Nemate poruka"
            />
          </div>

          <div className="btn-wrapper">
            <IconButtonWrapper
              className="mui-menu-item"
              id="notifications"
              onClick={logout}
            >
              <ExitToApp size="large" />
            </IconButtonWrapper>
          </div>
        </div>
      </div>

      {/* <MessagePopUp
        showModal={showModal}
        showModalProps={showMsgModal}
        singleMessage={single_message}
      /> */}
    </header>
  );
};

export default Header;
