import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function notificationsReducer(
  state = initialState.notifications,
  action
) {
  switch (action.type) {
    case types.LOAD_NOTIFICATIONS_SUCCESS:
      return action.notifications;
    case types.LOAD_NOTIFICATIONS_FAILURE:
      return {};
    default:
      return state;
  }
}
