const initialState = {
  user: {},
  auth: {},
  notifications: {},
  single_message: {},
  dashboard: {},
  messages: {},
  conversation: { messages: [] },
  ads: {},
  single_user: {},
  allUsers: {},
  countries: [],
  cities: [],
  addings: {},
  activeCandidates: {},
  archivedCandidates: {},
  activeUsers: {},
  archivedUsers: {},
  activeCompanies: {},
  archivedCompanies: {},
  single_ad: {},
  search_cities: [],
  deactivate_user: {},
  search_companies: [],
  activeAds: {},
  archivedAds: {}
};
export default initialState;
