import * as types from './actionTypes';
import * as userApi from '../../api/user';

export function updateEminentRequest() {
  return { type: types.UPDATE_EMINENT_REQUEST };
}

export function updateEminentSuccess(data) {
  return { type: types.UPDATE_EMINENT_SUCCESS, data };
}

export function updateEminentFailure(error) {
  return { type: types.UPDATE_EMINENT_FAILURE, error };
}

export function loadActiveCompaniesRequest() {
  return { type: types.LOAD_ACTIVE_COMPANIES_REQUEST };
}

export function loadActiveCompaniesSuccess(data) {
  return { type: types.LOAD_ACTIVE_COMPANIES_SUCCESS, data };
}

export function loadActiveCompaniesFailure(error) {
  return { type: types.LOAD_ACTIVE_COMPANIES_FAILURE, error };
}

export function loadArchivedCompaniesRequest() {
  return { type: types.LOAD_ARCHIVED_COMPANIES_REQUEST };
}

export function loadArchivedCompaniesSuccess(data) {
  return { type: types.LOAD_ARCHIVED_COMPANIES_SUCCESS, data };
}

export function loadArchivedCompaniesFailure(error) {
  return { type: types.LOAD_ARCHIVED_COMPANIES_FAILURE, error };
}

export function getAllActiveCompanies(data) {
  return function (dispatch) {
    dispatch(loadActiveCompaniesRequest());
    return userApi
      .getAllUsers({ ...data, role: 'company', is_archived: 0 })
      .then(res => {
        if (res.data) {
          dispatch(loadActiveCompaniesSuccess(res));
        }
      })
      .catch(error => {
        dispatch(loadActiveCompaniesFailure(error));
      });
  };
}

export function getAllArchivedCompanies(data) {
  return function (dispatch) {
    dispatch(loadArchivedCompaniesRequest());
    return userApi
      .getAllUsers({ ...data, role: 'company', is_archived: 1 })
      .then(res => {
        if (res.data) {
          dispatch(loadArchivedCompaniesSuccess(res));
        }
      })
      .catch(error => {
        dispatch(loadArchivedCompaniesFailure(error));
      });
  };
}


export function updateCompanyEminent(data, company_id) {
  return function (dispatch) {
    dispatch(updateEminentRequest());
    return userApi
      .updateEminent(data, company_id)
      .then(res => {
        if (res.data) {
          dispatch(updateEminentSuccess(res));
        }
      })
      .catch(error => {
        dispatch(updateEminentFailure(error));
      });
  };
}
