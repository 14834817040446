import { InputLabel } from '@material-ui/core';
import { useState } from 'react';
import { useEffect } from 'react';
import { SelectWrapper, FormControlWrapper } from './styles';

const Select = ({
  placeholder,
  label,
  value,
  onChange,
  options,
  native = true,
  disabled,
  ...props
}) => {
  const [shrink, setShrink] = useState(value ? true : false);

  useEffect(() => {
    console.log(value);
  }, [value]);

  return (
    <FormControlWrapper variant="outlined" {...props}>
      {label ? (
        <InputLabel
          htmlFor="outlined-age-native-simple"
          shrink={value || shrink}
        >
          {label}
        </InputLabel>
      ) : (
        ''
      )}
      <SelectWrapper
        disabled={disabled}
        native={native}
        onChange={e => onChange(e.target.value)}
        variant="outlined"
        label={label}
        value={value}
        inputProps={{
          onFocus: () => setShrink(true),
          onBlur: () => setShrink(false)
        }}
      >
        <option value="">{placeholder}</option>
        {options.map(({ value, label, ...other }, y) => (
          <option key={y} value={value} {...other}>
            {label}
          </option>
        ))}
      </SelectWrapper>
    </FormControlWrapper>
  );
};

export default Select;
