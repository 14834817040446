import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function allUsers(state = initialState.allUsers, action) {
  switch (action.type) {
    case types.LOAD_ALL_USERS_DATA_SUCCESS:
      return action.data;
    case types.LOAD_ALL_USERS_DATA_FAILURE:
      return {};

    default:
      return state;
  }
}
