import axios from 'axios';
import { toFormData } from '../../../utils/converter';
import { apiBaseUrl } from '../../config';

export const dashboard = (year) =>
  axios.get(`${apiBaseUrl}/api/v2/admin/dashboard`, { params: { year }}).then(x => x.data.data);

export const update = data =>
  axios.patch(`${apiBaseUrl}/api/v2/admin/update`, data).then(x => x.data.data);

export const updateProfileImage = image =>
  axios.post(
    `${apiBaseUrl}/api/v2/admin/fields/update-profile-image`,
    toFormData({ image })
  ).then(x => x.data.data);

export const users = params =>
  axios.get(`${apiBaseUrl}/api/v2/admin/users`, { params }).then(x => x.data);

export const userCreate = data =>
  axios.post(`${apiBaseUrl}/api/v2/admin/users`, data);

export const userDelete = user_id =>
  axios.delete(`${apiBaseUrl}/api/v2/admin/users/${user_id}`);

export const usersDelete = ids =>
  axios.delete(`${apiBaseUrl}/api/v2/admin/users`, { data: { ids } });

export const userSetArchived = (user_id, is_archived) =>
  axios.patch(`${apiBaseUrl}/api/v2/admin/users/${user_id}/set-archived`, {
    is_archived
  });

export const usersSetArchived = (ids, is_archived) =>
  axios.patch(`${apiBaseUrl}/api/v2/admin/users/set-archived`, {
    is_archived,
    ids
  });

export const userSetHidden = (id, is_hidden, user_id) =>
  axios.patch(`${apiBaseUrl}/api/v2/admin/users/${id}/set-hidden`, {
    is_hidden,
    user_id
  });

export const userReset = user_id =>
  axios.patch(`${apiBaseUrl}/api/v2/admin/users/${user_id}/reset`);

export const usersReset = ids =>
  axios.patch(`${apiBaseUrl}/api/v2/admin/users/reset`, { ids });

export const ads = (params) =>
  axios.get(`${apiBaseUrl}/api/v2/admin/ads`, {
    params
  }).then(x => x.data);

export const createAd = (data, user_id) =>
  axios.post(`${apiBaseUrl}/api/v2/admin/ads`, { ...data, user_id });

export const showAd = ad_id =>
  axios.get(`${apiBaseUrl}/api/v2/admin/ads/${ad_id}`).then(x => x.data.data);

export const updateAd = (ad_id, data) =>
  axios.patch(`${apiBaseUrl}/api/v2/admin/ads/${ad_id}`, data).then(x => x.data.data);

export const deleteAd = ad_id =>
  axios.delete(`${apiBaseUrl}/api/v2/admin/ads/${ad_id}`);

export const deleteAds = ids =>
  axios.delete(`${apiBaseUrl}/api/v2/admin/ads`, { data: { ids } });

export const adSetArchived = (ad_id, is_archived) =>
  axios.patch(`${apiBaseUrl}/api/v2/admin/ads/${ad_id}/set-archived`, {
    is_archived
  });

export const adsSetArchived = (ids, is_archived) =>
  axios.patch(`${apiBaseUrl}/api/v2/admin/ads/set-archived`, {
    is_archived,
    ids
  });

export const adSetHidden = (ad_id, is_hidden) =>
  axios.patch(`${apiBaseUrl}/api/v2/admin/ads/${ad_id}/set-hidden`, {
    is_hidden
  });

export const adReset = (ad_id) =>
  axios.patch(`${apiBaseUrl}/api/v2/admin/ads/${ad_id}/reset`);

export const adsReset = (ids) =>
  axios.patch(`${apiBaseUrl}/api/v2/admin/ads/reset`, { ids });

export const getInfo = () => axios.get(`${apiBaseUrl}/api/v2/admin/info`).then(x => x.data.data);

const AdminAPI = {
  dashboard,
  update,
  updateProfileImage,
  users,
  userCreate,
  userDelete,
  usersDelete,
  userSetArchived,
  usersSetArchived,
  userSetHidden,
  userReset,
  usersReset,
  ads,
  createAd,
  showAd,
  updateAd,
  deleteAd,
  deleteAds,
  adSetArchived,
  adsSetArchived,
  adSetHidden,
  adReset,
  adsReset,
  getInfo
};

export default AdminAPI;
