import { withStyles } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';

export const DatePickerWrapper = withStyles(() => ({
  root: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      borderRadius: 10
    }
  }
}))(KeyboardDatePicker);