import { Button, withStyles } from '@material-ui/core';

export const ButtonWrapper = withStyles(() => ({
  root: {
    cursor: 'pointer',
    borderRadius: 15,
    width: '100%',
    minHeight: 40,
    filter: ({ grayscale }) => (grayscale ? 'grayscale(1)' : 'none'),
    '&.MuiButton-contained': {
      background: ({ color = 'primary' }) =>
        ({
          primary: 'linear-gradient(0deg,#04274f,#2897fb)',
          secondary: 'linear-gradient(0deg, #c20000, #ff0000)'
        }[color]),
      color: '#fff'
    }
  }
}))(Button);