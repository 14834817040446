import React, { useState, useEffect } from 'react';
import {
  deactivateUser,
  loadUser
} from '../../../redux/actions/userActions';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  apiBaseUrl,
  imagePlaceholder,
} from '../../../api/config';
import {
  TrashIcon,
  PenIcon,
  Gradient
} from '../../../components/Icons';
import { Spinner } from '../../../components/Common';
import Confirmation from '../modals/confirmation';
import ShowVideo from '../modals/showVideo';
import { Title } from '../../../components/Title';

const Profile = ({ data, match, deactivateUser, user}) => {
  const [modals, setModals] = useState({ video: false, deactivate: false });
  let history = useHistory();

  const showModal = prop => event => {
    setModals({ ...modals, [prop]: true });
  };

  const closeModal = (prop, answer) => {
    setModals({ ...modals, [prop]: false });

    if (answer && prop === 'deactivate') {
      deactivateUser({ id: user.id });
      history.push('/active-companies');
    }
  };

  const goTo = (event) => {
    event.preventDefault();
    history.push(`/profile/edit`);
  };

  if (data) {
    return (
      <main id="mainContent">
        <Title title="Profil"/>
        <Gradient />
        <header>
          <div className="title-box">
            <h1>Profil</h1>
            <p>Sve informacije o profilu kompanije</p>
          </div>
        </header>

        <div className="container-flex">
          <div className="container-half">
            <div className="container-header">
              <h2>Osnovne informacije</h2>
            </div>
            <div className="container-body">
              <div className="input-wrapper wrapper-w-100">
                <p>
                  <strong>Naziv kompanije</strong>
                </p>
                <p>{user.full_name ? user.full_name : ' - '}</p>
              </div>
              <div className="input-wrapper wrapper-w-100">
                <p>
                  <strong>Djelatnost kompanije</strong>
                </p>
                <p>
                  {' '}
                  {user.company_activities
                    ? user.company_activities.name
                    : ' - '}
                </p>
              </div>

              <div className="input-wrapper wrapper-w-50 uploaded company-logo">
                <p>
                  <strong>Logotip kompanije</strong>
                </p>
                <div className="preview-logo-box">
                  <img
                    src={
                      user.profile_image
                        ? user.profile_image
                        : imagePlaceholder
                    }
                    alt="company logo"
                  />
                </div>
              </div>

              <div className="input-wrapper wrapper-w-100">
                <p>
                  <strong>Opis kompanije</strong>
                </p>

                <div
                  dangerouslySetInnerHTML={{
                    __html: ` ${
                      user.company_description ? user.company_description : '  '
                    } `
                  }}
                />
              </div>
            </div>
          </div>

          <div className="container-half">
            <div className="container-header">
              <h2>Kontakt informacije</h2>
            </div>
            <div className="container-body">
              <div className="input-wrapper wrapper-w-50">
                <p>
                  <strong>Kontakt osoba</strong>
                </p>
                <p>
                  {user.company_users && user.company_users[0]
                    ? user.company_users[0].contact_person
                    : ' - '}
                </p>
              </div>
              <div className="input-wrapper wrapper-w-50">
                <p>
                  <strong>Pozicija kontakt osobe</strong>
                </p>
                <p>
                  {user.company_users && user.company_users[0]
                    ? user.company_users[0].contact_person_position
                    : ' - '}
                </p>
              </div>
              <div className="input-wrapper wrapper-w-50">
                <p>
                  <strong>Kontakt telefon</strong>
                </p>
                <p>
                  {user.phone ? user.phone
                    : ' - '}
                </p>
              </div>
              <div className="input-wrapper wrapper-w-50">
                <p>
                  <strong>Kontakt email</strong>
                </p>
                <p>
                  {user ? user.email
                    : ' - '}
                </p>
              </div>
              <div className="input-wrapper wrapper-w-50">
                <p>
                  <strong>Web adresa</strong>
                </p>
                <p>{user.website ? user.website : ' - '}</p>
              </div>
              <div className="input-wrapper wrapper-w-50">
                <p>
                  <strong>Facebook profil</strong>
                </p>
                <p>{user.facebook ? user.facebook : ' - '}</p>
              </div>
              <div className="input-wrapper wrapper-w-50">
                <p>
                  <strong>Instagram profil</strong>
                </p>
                <p>{user.instagram ? user.instagram : ' - '}</p>
              </div>
              <div className="input-wrapper wrapper-w-50">
                <p>
                  <strong>Država</strong>
                </p>
                <p>{user.country_id ? user.country.name : ' - '}</p>
              </div>
              <div className="input-wrapper wrapper-w-50">
                <p>
                  <strong>Grad</strong>
                </p>
                <p>{user.city_id ? user.city.name : ' - '}</p>
              </div>
              <div className="input-wrapper wrapper-w-50">
                <p>
                  <strong>Adresa kompanije</strong>
                </p>
                <p>{user.address ? user.address : ' - '}</p>
              </div>
              <div className="input-wrapper wrapper-w-50">
                <p>
                  <strong>PIB</strong>
                </p>
                <p>{user.pib ? user.pib : ' - '}</p>
              </div>
              <div className="input-wrapper wrapper-w-50">
                <p>
                  <strong>PDV</strong>
                </p>
                <p>{user.pdv ? user.pdv : ' - '}</p>
              </div>
            </div>
          </div>

          <div className="container-half current-package">
            {/*<div className="text-wrapper">
              <p>Trenutni paket profila:</p>
              <h2>BESPLATNI PAKET</h2>
            </div>
            <button
              className="all-btns blue-btn upper"

            >
              <PackageIcon />
              Izmjeni paket
            </button>*/}
          </div>

          {/* del/save */}
          <div className="container-half btns-container">
            <button
              className="all-btns gray-btn upper"
              onClick={event => goTo(event)}
            >
              <PenIcon />
              Izmijeni profil
            </button>
            <button
              className="all-btns red-btn upper"
              onClick={showModal('deactivate')}
            >
              <TrashIcon />
              Deaktiviraj profil
            </button>
          </div>
        </div>

        <Confirmation
          name="deactivate"
          modal={modals.deactivate}
          closeModal={closeModal}
          title="DEAKTIVIRAJ"
          text="Da li ste sigurni da želite da deaktivirate profil?"
        />

        <ShowVideo
          modal={modals.video}
          closeModal={closeModal}
          video={user.videos ? user.videos.video : ''}
        />
      </main>
    );
  } else {
    return (
      <main id="mainContent">
        <div className="spinner-container" style={{ marginTop: '40%' }}>
          <Spinner />
        </div>
      </main>
    );
  }
};

function mapStateToProps(state) {
  return {
    user: state.user,
    data: state.singleUser
  };
}

const mapDispatchToProps = {
  deactivateUser: deactivateUser,
  getUser: loadUser
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
