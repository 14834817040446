import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  BellIcon,
  HomeIcon,
  MessageIcon,
  RemoveIcon,
  SettingsIcon
} from './Icons';
import { connect } from 'react-redux';

const Footer = () => {

  return (
    <>
      <div className="mobile-nav-container">
        {/* <a href="/" className="btn-wrapper">
          <button className="ico-box active">
            <HomeIcon />
          </button>
        </a> */}
        <div className="btn-wrapper">
          <NavLink
            className="ico-box footer-nav-home"
            exact
            to="/"
            onClick={e => 'goTo(e)'}
          >
            <HomeIcon />
          </NavLink>
        </div>
        <div className="btn-wrapper">
          <button
            className="ico-box"
            onClick={e => "toggleDiv('showNotifications')"}
          >
            <BellIcon />
          </button>
          <div
            className="btn-stats"
          >
            <div style={{ marginLeft: '35%', marginTop: '15%' }}>
              {5}
            </div>
          </div>
        </div>
        <div className="btn-wrapper">
          {/* <button className="ico-box" onClick={e => toggleDiv('showMessages')}>
            <MessageIcon />
          </button> */}
          <NavLink
            className="ico-box footer-nav-messages"
            to="/messages"
            onClick={e => 'goTo(e)'}
          >
            <MessageIcon />
          </NavLink>
          <div
            className="btn-stats"
          >
            <div style={{ marginLeft: '35%', marginTop: '15%' }}>
              {8}
            </div>
          </div>
        </div>
      </div>

      <div
        id="notifications-mobile-popup"
        className="popup-box"
      >
        <div className="popup-container">
          <button
            className="close-box"
            onClick={e => "toggleDiv('showNotifications')"}
          >
            <RemoveIcon />
          </button>
          <div className="popup-header">
            <h5>Obavještenja</h5>
            <button className="settings">
              <SettingsIcon />
            </button>
          </div>

          <div className="popup-body">
            <button className="notification unread">
              <p className="title">Novi oglas za tebe</p>
              <p>Kompanija Lorem ipsum je postavila novi oglas</p>
            </button>
          </div>
        </div>
      </div>

      <div
        id="messages-mobile-popup"
        className="popup-box"
      >
        <div className="popup-container">
          <button
            className="close-box"
            onClick={e => "toggleDiv('showMessages')"}
          >
            <RemoveIcon />
          </button>
          <div className="popup-header">
            <h5>Poruke</h5>
            <button className="settings">
              <SettingsIcon />
            </button>
          </div>
          <div className="popup-body">
            <button className="notification unread">
              <p className="title">Naziv kompanije</p>
              <p>Postovani, hvala Vam na prijavi za...</p>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
