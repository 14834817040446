import React, { useState } from 'react';
import { XIcon } from './Icons';
import localizedStrings from '../utils/localizedStrings';

const Questions = ({ showModalProps, closeModal }) => {
  const [checked, setChecked] = useState([]);
  const questions = [
    {
      name: 'question1',
      text: 'Lorem ipsum dolor sit amet?',
      checked: 'false'
    },
    {
      name: 'question2',
      text: 'Lorem ipsum dolor sit amet?',
      checked: 'false'
    },
    {
      name: 'question3',
      text: 'Lorem ipsum dolor sit amet?',
      checked: 'false'
    },
    {
      name: 'question4',
      text: 'Lorem ipsum dolor sit amet?',
      checked: 'false'
    },
    {
      name: 'question5',
      text: 'Lorem ipsum dolor sit amet?',
      checked: 'false'
    }
  ];

  const handleInputChange = (e, item) => {
    if (e.target.checked) {
      setChecked(prevState => [...prevState, item.text]);
    } else {
      setChecked(prevState =>
        prevState.filter(i => {
          return i.name !== item.name;
        })
      );
    }
  };

  const closeModalUpdated = () => {
    closeModal(checked);
  };

  return (
    <div
      id="savedQuestions"
      className="modal-bg"
      style={{ display: showModalProps ? 'block' : 'none' }}
    >
      <div className="modal">
        <div className="modal-header">
          <h5>{localizedStrings.question_modal.header}</h5>
          <button className="close-btn" onClick={closeModal}>
            <XIcon />
          </button>
          <p>{localizedStrings.question_modal.desc}</p>
        </div>
        <div className="modal-body justify-between">
          <div className="questions-wrapper">
            {questions
              ? questions.map((item, index) => {
                  return (
                    <label
                      htmlFor={item.name}
                      className="radio-wrapper"
                      key={index}
                    >
                      <input
                        onChange={e => handleInputChange(e, item)}
                        type="checkbox"
                        id={item.name}
                        className="radio-input"
                        name={item.name}
                        value={item.name}
                      />
                      <div className="custom-radio-check"></div>
                      <p>
                        {index + 1}. {item.text}
                      </p>
                    </label>
                  );
                })
              : null}
          </div>

          <button
            className="all-btns blue-btn upper"
            onClick={() => closeModalUpdated()}
          >
            {localizedStrings.question_modal.confirm}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Questions;
