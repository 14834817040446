import React, { useEffect, useRef, useState } from 'react';
import {
  PenIcon,
  SaveIcon,
  X2Icon,
  DropdownIcon2
} from '../../components/Icons';
import { connect, useDispatch, useSelector } from 'react-redux';
import { apiBaseUrl, imagePlaceholder } from '../../api/config';
import Password from './modals/password';
import { toast } from 'react-toastify';
import { CustomDropdown } from '../../components/Inputs';
import {
  updateUserProfileImage,
  updateUserData,
  loadUser
} from '../../redux/actions/userActions';
import { Title } from '../../components/Title';
import Input from '../../components/input';
import { AdminAPI, AuthAPI, GlobalAPI } from '../../api/v2';
import Select from '../../components/select';
import Button from '../../components/button/index';
import moment from 'moment';
import { Close, Edit, Save } from '@material-ui/icons';
import { Fragment } from 'react';
import Modal from '../../components/modal';
import { globalActions } from '../../redux/v2/global';

const Settings = () => {
  const { user } = useSelector(state => state.global);

  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const onClose = () => {
    setOpen(false);
  };

  const [values, setValues] = useState({
    full_name: user.full_name,
    email: user.email,
    ui_language: '1'
  });

  const resetDefaults = () => {
    setValues({
      ...values,
      email: user.email,
      full_name: user.full_name,
      ui_language: '1'
    });
    setEdit(false);
  };

  const dispatch = useDispatch();

  const [uiLanguages, setUiLanguages] = useState([]);

  const getUiLanguages = async () => {
    const res = await GlobalAPI.uiLanguages();
    const formatedUiLanguages = res.map(({ id, name }) => ({
      value: id,
      label: name
    }));
    setUiLanguages(formatedUiLanguages);
  };

  useEffect(() => {
    getUiLanguages();
  }, []);

  const updateInfo = async () => {
    if (Object.keys(values).filter(x => !x.trim()).length) {
      return toast.warn('Popunite sva polja');
    }
    try {
      const res = await AdminAPI.update(values);
      console.log(res);
      setEdit(false);
      toast.success('Izmene su sačuvane');
      onClose();
      dispatch(globalActions.setUser({ ...user, ...res }));
    } catch (e) {
      toast.error('Došlo je do greške');
    }
  };

  const uploadImage = async e => {
    const file = e.target.files[0];
    const res = await AdminAPI.updateProfileImage(file);
    dispatch(globalActions.setUser({ ...user, ...res }));
    toast.success('Izmene su sačuvane');
  };

  const uploadRef = useRef();

  return (
    <main id="mainContent">
      <Title title="Podešavanja" />
      <header>
        <div className="title-box">
          <h1>Podešavanja</h1>
          <p>Sva podešavanja naloga</p>
        </div>
      </header>

      <div className="img-side">
        <div className="img-placeholder is-filled">
          <div className="img-holder">
            <img
              src={user.profile_image ? user.profile_image : imagePlaceholder}
              alt="profile"
            />
          </div>

          <input
            type="file"
            id="selectedFile"
            accept="image/png, image/jpeg"
            style={{ display: 'none' }}
            onChange={uploadImage}
            ref={uploadRef}
          />
          <button
            className="add-img-btn yellow-btn"
            onClick={() => uploadRef.current.click()}
          >
            <PenIcon />
          </button>
        </div>
      </div>
      <div className="settings-main">
        <div className="settings-half">
          <Input
            className="force-space-b"
            label="Ime korisnika"
            value={values.full_name}
            onChange={full_name => setValues({ ...values, full_name })}
            disabled={!edit}
          />
          <Input
            className="force-space-b"
            label="Email"
            value={values.email}
            onChange={email => setValues({ ...values, email })}
            disabled={!edit}
          />
          <Select
            className="force-space-b"
            label="Jezik"
            value={values.ui_language}
            onChange={ui_language => setValues({ ...values, ui_language })}
            options={uiLanguages}
            disabled={!edit}
          />
          <Input
            className="force-space-b"
            label="Datum registracije"
            value={moment(user.created_at).format('DD-MM-YYYY')}
            disabled
          />
          {edit ? (
            <Fragment>
              <Button
                className="force-space-b"
                startIcon={<Save />}
                onClick={updateInfo}
              >
                Sačuvaj
              </Button>
              <Button startIcon={<Close />} onClick={resetDefaults} grayscale>
                Odbaci izmene
              </Button>
            </Fragment>
          ) : (
            <Button startIcon={<Edit />} onClick={() => setEdit(true)}>
              Izmeni informacije
            </Button>
          )}
        </div>
        <div className="password-actions">
          <span onClick={() => setOpen(true)}>Izmeni lozinku</span>
        </div>
      </div>
      <Modal title="Izmena lozinke" open={open} onClose={onClose}>
        <ChangePasswordModal onClose={onClose} />
      </Modal>
    </main>
  );
};

const ChangePasswordModal = ({ onClose }) => {
  const [passwords, setPasswords] = useState({
    old_password: '',
    new_password: '',
    repeat_new_password: ''
  });

  const changePassword = async () => {
    if (Object.keys(passwords).filter(x => !x.trim()).length) {
      return toast.warn('Popunite sva polja');
    }
    if (
      passwords.new_password.trim() !== passwords.repeat_new_password.trim()
    ) {
      return toast.warn('Lozinke se ne podudaraju');
    }
    try {
      await AuthAPI.changePassword(passwords);
      toast.success('Lozinka je uspešno promenjena');
      onClose();
    } catch (e) {
      toast.error('Došlo je do greške');
    }
  };

  return (
    <Fragment>
      <Input
        className="force-space-b"
        label="Stara lozinka"
        value={passwords.old_password}
        onChange={old_password => setPasswords({ ...passwords, old_password })}
        type="password"
      />
      <Input
        className="force-space-b"
        label="Nova lozinka"
        value={passwords.new_password}
        onChange={new_password => setPasswords({ ...passwords, new_password })}
        type="password"
      />
      <Input
        className="force-space-b"
        label="Potvrdite novu lozinku"
        value={passwords.repeat_new_password}
        type="password"
        onChange={repeat_new_password =>
          setPasswords({ ...passwords, repeat_new_password })
        }
      />
      <Button onClick={changePassword}>Izmeni lozinku</Button>
    </Fragment>
  );
};

export default Settings;
