import axios from 'axios';
import { apiBaseUrl } from './config';

export const getCountries = () => {
  return axios({
    method: 'get',
    url: `${apiBaseUrl}/api/country/getCountries`
  }).then(res => res.data);
};

export const getCountryCity = data => {
  return axios({
    method: 'post',
    url: `${apiBaseUrl}/api/country/getCountryCity`,
    data
  }).then(res => res.data);
};

export const getSearchCities = data => {
  return axios({
    method: 'get',
    url: `${apiBaseUrl}/api/v2/cities`,
    params: data
  }).then(res => res.data);
};
