import { Badge, IconButton, Menu } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";

export const IconButtonWrapper = withStyles({
  root: {
    background: '#fff',
    color: '#2897fb',
    '&:hover': {
      background: '#2897fb',
      color: '#fff'
    }
  }
})(IconButton);

export const BadgeWrapper = withStyles({
  badge: {
    background: 'linear-gradient(to top, #ee5124, #ffa837)',
    color: '#fff',
    fontSize: 10,
    height: 16,
    minWidth: 16
  }
})(Badge);

export const MenuWrapper = withStyles({
  paper: {
    transform: 'translateX(0px) translateY(10px) !important'
  }
})(Menu);