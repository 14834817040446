import { useEffect, useState } from 'react';
import {
  TableBodyWrapper,
  TableCellWrapper,
  TableHeadWrapper,
  TableRowWrapper,
  TableWrapper,
  TableSortLabelWrapper,
  TableSelectWrapper,
  EmptyTableWrapper
} from './styles';

import Switch from '../switch';
import {
  Edit,
  EditOutlined,
  Folder,
  SentimentVeryDissatisfied
} from '@material-ui/icons';
import { IconButton } from '@material-ui/core';
import { Fragment } from 'react';

const headItems = [
  { key: 'id', id: 1, name: 'ID' },
  { key: 'name', id: 2, name: 'Naziv korisnika' },
  { key: 'email', id: 3, name: 'Email' },
  { key: 'role', id: 4, name: 'Uloga' },
  { key: 'date', id: 5, name: 'Datum' },
  { key: 'status', id: 6, name: 'Status', center: true },
  { key: 'edit', id: 7, name: 'Izmjeni', center: true },
  { key: 'archive', id: 8, name: 'Arhiviraj', center: true }
];

const bodyItems = [
  {
    id: 13,
    name: 'Aleksandar Babic',
    email: 'admin@cvprica.com',
    role: 'admin',
    date: '03.09.2020',
    status: <Switch />,
    edit: (
      <IconButton
        aria-label="delete"
        style={{ fontSize: 24, margin: '0 auto', display: 'block' }}
      >
        <EditOutlined fontSize="inherit" />
      </IconButton>
    ),
    archive: (
      <IconButton
        aria-label="delete"
        style={{ fontSize: 24, margin: '0 auto', display: 'block' }}
      >
        <Folder fontSize="inherit" />
      </IconButton>
    )
  },
  {
    id: 14,
    name: 'Filip Maksimovic',
    email: 'test@cvprica.com',
    role: 'employee',
    date: '03.09.2020',
    status: <Switch />,
    edit: (
      <IconButton
        aria-label="delete"
        style={{ fontSize: 24, margin: '0 auto', display: 'block' }}
      >
        <EditOutlined fontSize="inherit" />
      </IconButton>
    ),
    archive: (
      <IconButton
        aria-label="delete"
        style={{ fontSize: 24, margin: '0 auto', display: 'block' }}
      >
        <Folder fontSize="inherit" />
      </IconButton>
    )
  },
  {
    id: 15,
    name: 'Sale Babic',
    email: 'asd@cvprica.com',
    role: 'company',
    date: '03.09.2020',
    status: <Switch />,
    edit: (
      <IconButton
        aria-label="delete"
        style={{ fontSize: 24, margin: '0 auto', display: 'block' }}
      >
        <EditOutlined fontSize="inherit" />
      </IconButton>
    ),
    archive: (
      <IconButton
        aria-label="delete"
        style={{ fontSize: 24, margin: '0 auto', display: 'block' }}
      >
        <Folder fontSize="inherit" />
      </IconButton>
    )
  }
];

const Table = ({
  head = headItems,
  body = bodyItems,
  sort,
  onRequestSort,
  onSelection
}) => {
  const createSortHandler = key => e => {
    if (onRequestSort) onRequestSort(e, key);
  };

  const [globalSelect, setGlobalSelect] = useState(false);
  const [selects, setSelects] = useState(body.map(x => false));

  const handleSelect = id => {
    const newSelectValues = selects.map((x, y) => (y === id ? !x : x));
    setSelects(newSelectValues);
    setGlobalSelect(
      !newSelectValues.filter(x => x === false).length ? true : false
    );
  };

  const handleGlobalSelect = () => {
    setSelects(body.map(() => (globalSelect ? false : true)));
    setGlobalSelect(!globalSelect);
  };

  const handleSelection = () => {
    const selectedFields = body.filter((x, y) => selects[y] === true);
    console.log(selectedFields);
    if (onSelection) onSelection(selectedFields);
  };

  useEffect(() => {
    handleSelection();
  }, [selects]);

  useEffect(() => {
    setSelects(body.map(x => false));
    setGlobalSelect(false);
  }, [body]);

  return (
    <div
      style={{
        overflowX: 'scroll',
        overflowY: 'hidden',
        width: '100%',
      }}
    >
      <TableWrapper>
        <TableHeadWrapper>
          <TableRowWrapper>
            <TableCellWrapper center>
              <TableSelectWrapper
                selected={globalSelect}
                onClick={handleGlobalSelect}
              />
            </TableCellWrapper>
            {head.map(x => (
              <TableCellWrapper key={x.id} center={x.center}>
                {sort && x.sort ? (
                  <TableSortLabelWrapper
                    active={sort.order_by === x.sort}
                    onClick={createSortHandler(x.sort)}
                    direction={sort.order_by === x.sort ? sort.order_dir : 'asc'}
                  >
                    {x.name}
                  </TableSortLabelWrapper>
                ) : (
                  x.name
                )}
              </TableCellWrapper>
            ))}
          </TableRowWrapper>
        </TableHeadWrapper>
        <TableBodyWrapper>
          {body.map((x, y) => (
            <TableRowWrapper key={y}>
              <TableCellWrapper center>
                <TableSelectWrapper
                  selected={selects[y]}
                  onClick={() => handleSelect(y)}
                />
              </TableCellWrapper>
              {head.map(a => (
                <TableCellWrapper key={a.id} center={a.center}>
                  {x[a.key]}
                </TableCellWrapper>
              ))}
            </TableRowWrapper>
          ))}
        </TableBodyWrapper>
      </TableWrapper>
      {!body.length ? (
        <EmptyTableWrapper>
          <SentimentVeryDissatisfied />
          <h1>Nema rezultata</h1>
          <p>Izgleda da ova tabela nema rezultata!</p>
        </EmptyTableWrapper>
      ) : (
        ''
      )}
    </div>
  );
};

export default Table;
