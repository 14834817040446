import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(1.5),
      width: '320px'
      /*[theme.breakpoints.down('sm')]: {
        width: "100px",
        }*/
    },
    '& label': {
      fontSize: '17px'
    },
    '& label.Mui-focused': {
      color: 'black'
    },
    '& fieldset': {
      borderRadius: '10px',
      fontSize: '16px'
    },
    '& input': {
      fontSize: '16px'
    }
  },
  checkbox: {
    '& > *': {
      width: '100%'
    },
    '& fieldset': {
      borderRadius: '7px',
      fontSize: '16px',
      width: '100%'
    },
    '& input': {
      fontSize: '16px',
      marginTop: '-5px',
      color: 'black',
      width: '260px'
    }
  },
  paper: {
    width: '100%',
    fontSize: '14px'
  },

  rootMultiselect: {
    width: '100%',
    border: 'solid 1px gray',
    //height: '50px',
    background: 'white',
    outline: 'none',
    borderRadius: '7px',
    fontSize: '16px',
    '&  .MuiChip-root	': {
      background: 'transparent',
      border: 'solid 1px gray',
      marginTop: '-5px'
    },
    '& .MuiChip-label': {
      color: 'black',
      fontSize: '14px'
    },
    '& .MuiChip-deleteIcon': {},
    '& > * + *': {
      marginTop: theme.spacing(3),
      height: '100%'
    },
    '& fieldset': {
      border: 'none',
      fontSize: '16px',
      width: '100%'
    },
    '& input': {
      fontSize: '16px',
      color: 'black'
    }
  }
}));

export const ComboBox = ({
  onChange,
  onChangeAutocomplete,
  name,
  value,
  className,
  options,
  label,
  styleOpt,
  comboLabel
}) => {
  const classes = useStyles();

  return (
    <Autocomplete
      className={classes.checkbox}
      options={options}
      onChange={(event, newValue) =>
        onChangeAutocomplete(event, newValue, name)
      }
      disableClearable
      freeSolo
      classes={{ paper: classes.paper }}
      style={styleOpt}
      getOptionLabel={option => option[label] || ''}
      value={value}
      renderInput={params => (
        <TextField
          {...params}
          name={name || ''}
          placeholder={comboLabel || "Odaberite"}
          variant="outlined"
          autoComplete="off"
          className={className}
          onChange={e => onChange ? onChange(e, name) : ''}
        />
      )}
    />
  );
};

export const CustomInput = ({ label, onChange, type, name, value }) => {
  const classes = useStyles();

  return (
    <div className={classes.root} noValidate autoComplete="off">
      <TextField
        variant="outlined"
        label={label}
        onChange={e => onChange(e, name)}
        type={type || 'text'}
        name={name || ''}
        value={value}
        autoComplete="off"
        autofocus={false}
      />
    </div>
  );
};

export const CustomInput2 = ({
  label,
  onChange,
  type,
  name,
  value,
  placeholder,
  id,
  className,
  req,
  index
}) => {
  return (
    <>
      <p>
        <strong>
          {label} {req ? <span>*</span> : null}
        </strong>
      </p>
      <input
        type={type || 'text'}
        placeholder={placeholder || ''}
        name={name || ''}
        value={value || ''}
        onChange={e => onChange(e, name, index)}
        className={className}
        id={id}
        autoCapitalize="new-password"
        autoComplete="new-password"
        autoFocus={false}
      />
    </>
  );
};

export const CustomDropdown = ({
  className,
  name,
  id,
  children,
  onChange,
  index,
  value,
  label,
  dropdownLabel
}) => {
  return (
    <select
      className={className}
      name={name}
      id={id}
      onChange={e => onChange(e, name, index)}
      value={value}
    >
      <option value="" disabled hidden>
        {value ? value : (dropdownLabel || "Odaberite")}
      </option>
      {children && children.length > 0
        ? children.map((e, index) => {
            return (
              <option key={index} value={e.id}>
                {e.name}
              </option>
            );
          })
        : null}
    </select>
  );
};

export const MultiSelectAutocomplete = ({
  onChange,
  onChangeAutocomplete,
  name,
  value,
  className,
  options
}) => {
  const classes = useStyles();

  return (
    <div className={classes.rootMultiselect}>
      <Autocomplete
        multiple
        disableClearable
        freeSolo
        classes={{ paper: classes.paper }}
        options={options}
        value={value}
        onChange={(event, newValue) =>
          onChangeAutocomplete(event, newValue, name)
        }
        getOptionLabel={option => option.name || ''}
        renderInput={params => (
          <TextField
            {...params}
            name={name || ''}
            variant="outlined"
            className={className}
            onChange={e => onChange(e, name)}
          />
        )}
      />
    </div>
  );
};
