import { FormControl, Select, withStyles } from '@material-ui/core';

export const SelectWrapper = withStyles(() => ({
  root: {
    width: '100%',
    fontSize: 16,
    borderRadius: 10,
    color: ({ value, disabled }) => (value && !disabled ? '#000' : '#9c9e9f'),
    '& > *': {
      color: '#000'
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 10
    }
  }
}))(Select);

export const FormControlWrapper = withStyles(() => ({
  root: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      borderRadius: 10
    },
  }
}))(FormControl);
