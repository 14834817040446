import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Calendar from '../../components/calendar';
import { CustomDropdown, CustomInput2 } from '../../components/Inputs';
import {
  DropdownIcon2,
  SaveIcon,
  MinusSquareIcon,
  PlusSquareIcon
} from '../../components/Icons';
import localizedStrings from '../../utils/localizedStrings';
import {
  getAllCountries,
  getAllCities
} from '../../redux/actions/countryActions';
import Questions from '../../components/Questions';
import RichText from '../../components/RichText';
import Confirmation from './modals/confirmation';
import { Title } from '../../components/Title';
import { createAd } from '../../redux/actions/jobsActions';
import { toast } from 'react-toastify';
import { getAllActiveCompanies } from '../../redux/actions/companiesAction';

const AddAd = ({ countries, getCountries, cities, getCities, addings, processCreateAd, loadAllActiveCompanies, activeCompanies }) => {
  let history = useHistory();

  const [ad, setAd] = useState({
    title: '',
    short_desc: '',
    country_id: '',
    city_id: '',
    job_id: '',
    type_id: '',
    work_time_id: '',
    description: '',
    start_date: '',
    end_date: '',
    questions: ['', ''],
    user_id: -1
  });

  const setRichText = (description) => setAd({ ...ad, description })

  useEffect(() => {
    console.log(ad.description);
  }, [ad.description])

  const [countryID, setCountryID] = useState(1);
  const [warning, setWarning] = useState(false);
  const [showQuestionsModal, toggleQuestionsModal] = useState(false);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    getCountries();
  }, [getCountries]);

  useEffect(() => {
    loadAllActiveCompanies();
    console.log(activeCompanies);
  }, []);

  useEffect(() => {
    if (activeCompanies) {
      setCompanies(activeCompanies.map(x => ({ id: x.id, name: x.full_name })))
    }
  }, [activeCompanies])

  useEffect(() => {
    getCities({ id: countryID });
  }, [getCities, countryID]);

  const handleInput = (e, name) => {
    let value = e.target.value;
    console.log(value, name);
    if (name === 'country_id') {
      setCountryID(value);
      setAd({
        ...ad,
        city_id: ''
      });
    }
    setAd({
      ...ad,
      [name]: value
    });
  };

  const closeModal = questions => {
    toggleQuestionsModal(false);
    if (questions && questions.length > 0) {
      let temp = [...ad.questions, ...questions];
      if (ad.questions.length < 2)
        temp = temp.filter(q => q !== null || undefined || '');

      setAd({ ...ad, questions: temp });
    }
  };

  const closeModal2 = (prop, answer) => {
    setWarning(false);

    if (answer) history.goBack();
  };

  const handleDescription = value => {
    setAd({ ...ad, description: value });
  };

  const setDate = (start, end) => {
    let s = `${start.getFullYear()}-${start.getMonth() + 1}-${start.getDate()}`;
    let e = `${end.getFullYear()}-${end.getMonth() + 1}-${end.getDate()}`;

    setAd({ ...ad, start_date: s || '', end_date: e || '' });
  };

  const deleteQuestion = (e, i) => {
    let temp = ad.questions;
    delete temp[i];
    temp = temp.filter(q => q !== null || undefined);
    setAd({ ...ad, questions: temp });
  };

  const addQuestion = e => {
    let temp = ad.questions;
    if (temp.length < 10) temp.push('');
    setAd({ ...ad, questions: temp });
  };

  const handleInputQuestions = (e, name, index) => {
    let temp = ad.questions;
    temp[index] = e.target.value;
    setAd({ ...ad, questions: temp });
  };

  const saveAd = () => {
    console.log(ad);
  };

  const publishAd = async () => {
    try {
      await processCreateAd(ad);
      toast.success('Oglas je uspešno kreiran! Bićete uskoro preusmereni...');
      setTimeout(() => { history.push('/active-ads') }, 3000)
    } catch (e) {
      toast.error('Došlo je do greške!');
    }
  };

  return (
    <main id="mainContent" className="xl-resize">
      <Title title="Dodajte oglas" />
      <div id="close-area"></div>
      <header>
        <div className="title-box">
          <h1>{localizedStrings.add_ad.title}</h1>
          <p>{localizedStrings.add_ad.description}</p>
        </div>
      </header>

      <div className="add-ad-container">
        <h2 className="title">{localizedStrings.add_ad.add_new_ad}</h2>
        <div className="add-ad-wrapper add-border-bottom">
          <div className="flex-wrap left-side">
            <div className="box box-100">
              <CustomInput2
                label={localizedStrings.add_ad.ad_title}
                placeholder="Upišite ovdje"
                name="title"
                onChange={handleInput}
                value={ad.title}
                className="input-class"
                req={true}
              />
            </div>
            <div className="box box-100">
              <p>
                <strong>{localizedStrings.add_ad.ad_company}</strong>{' '}
                <span>*</span>
              </p>
              <div className="select-wrapper">
                <CustomDropdown
                  className="select"
                  name="user_id"
                  value={ad.user_id}
                  children={companies}
                  onChange={handleInput}
                />
                <DropdownIcon2 className="select-arrow" />
              </div>
            </div>
            <div className="box box-100">
              <CustomInput2
                label="Kratak opis"
                placeholder="Upišite ovdje"
                name="short_desc"
                onChange={handleInput}
                value={ad.short_desc}
                className="input-class"
                max="100"
              />
              <div className={`number-of-characters ${ad.short_desc.length > 150 ? 'number-of-characters-error' : ''}`}>{ad.short_desc.length}/150</div>
            </div>

            <div className="box box-50">
              <p>
                <strong>{localizedStrings.add_ad.country}</strong>{' '}
                <span>*</span>
              </p>
              <div className="select-wrapper">
                <CustomDropdown
                  className="select"
                  name="country_id"
                  value={ad.country_id}
                  children={countries}
                  onChange={handleInput}
                />
                <DropdownIcon2 className="select-arrow" />
              </div>
            </div>

            <div className="box box-50">
              <p>
                <strong>{localizedStrings.add_ad.cities}</strong> <span>*</span>
              </p>
              <div className="select-wrapper">
                <CustomDropdown
                  className="select"
                  name="city_id"
                  value={ad.city_id}
                  children={cities}
                  onChange={handleInput}
                />
                <DropdownIcon2 className="select-arrow" />
              </div>
            </div>

            <div className="box box-50">
              <p>
                <strong>{localizedStrings.add_ad.category}</strong>{' '}
                <span>*</span>
              </p>
              <div className="select-wrapper">
                <CustomDropdown
                  className="select"
                  name="job_id"
                  children={addings.type_of_works}
                  onChange={handleInput}
                  value={ad.job_id}
                />
                <DropdownIcon2 className="select-arrow" />
              </div>
            </div>

            <div className="box box-50">
              <p>
                <strong>{localizedStrings.add_ad.type}</strong> <span>*</span>
              </p>
              <div className="select-wrapper">
                <CustomDropdown
                  className="select"
                  name="type_id"
                  value={ad.type_id}
                  children={addings.jobTypes}
                  onChange={handleInput}
                />
                <DropdownIcon2 className="select-arrow" />
              </div>
            </div>

            <div className="box box-50">
              <p>
                <strong>{localizedStrings.add_ad.working_hours}</strong>{' '}
                <span>*</span>
              </p>
              <div className="select-wrapper">
                <select
                  className="select"
                  name="work_time_id"
                  value={ad.work_time_id}
                  onChange={e => handleInput(e, 'work_time_id')}
                >
                  <option hidden>Odaberite</option>
                  <option value="1">Puno radno vrijeme</option>
                  <option value="2">Part time vrijeme</option>
                  <option value="3">Remote</option>
                </select>
                <DropdownIcon2 className="select-arrow" />
              </div>
            </div>
          </div>
          <div className="flex-wrap right-side">
            <div className="box box-100">
              <p>
                <strong>{localizedStrings.add_ad.job_desc}</strong>
              </p>

              <div className="jodit-editor-cv">
                <RichText value={ad.description} setValue={v => setRichText(v)} />
              </div>
            </div>
          </div>

          <div className="add-ad-wrapper">
            <Calendar setDate={setDate} />

            <div className="flex-column questions-side box-70">
              <div className="ad-wrapper-header">
                <h4>{localizedStrings.add_ad.ask_questions}</h4>
                {/* <button
                  className="all-btns blue-btn"
                  onClick={() => toggleQuestionsModal(prevState => !prevState)}
                >
                  {localizedStrings.add_ad.pick_predefined}
                </button> */}
              </div>
              {ad.questions.map((q, i) => {
                return (
                  <div className="box">
                    <p>
                      <strong>{i + 1}. Pitanje za kandidata</strong>
                    </p>
                    <div className="box-100 flex-wrap question-wrap">
                      <CustomInput2
                        placeholder={
                          localizedStrings.add_ad.question_placeholder
                        }
                        name="questions"
                        onChange={handleInputQuestions}
                        value={ad.questions[i]}
                        index={i}
                        className=" input-class-2 w-full-btn-2"
                      />
                      <button className="add-remove-btn">
                        <SaveIcon />
                      </button>
                      {i > 0 ? (
                        <button
                          className="add-remove-btn"
                          onClick={e => deleteQuestion(e, i)}
                        >
                          <MinusSquareIcon />
                        </button>
                      ) : (
                        <button
                          className="add-remove-btn"
                          onClick={e => addQuestion(e)}
                        >
                          <PlusSquareIcon />
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
              <div className="questions-side-legend">
                <div className="legend-item">
                  <PlusSquareIcon />
                  <span>Dodajte novo pitanje</span>
                </div>
                <div className="legend-item">
                  <MinusSquareIcon />
                  <span>Uklonite pitanje</span>
                </div>
                <div className="legend-item">
                  <SaveIcon />
                  <span>Sačuvajte pitanje kao šablon</span>
                </div>
              </div>
            </div>

            <div className="flex-wrap btns-container">
              {/* <button
                className="all-btns blue-btn upper"
                onClick={() => saveAd()}
              >
                {localizedStrings.add_ad.save_ad}
              </button> */}
              <button
                className="all-btns yellow-btn upper"
                onClick={() => publishAd()}
              >
                {localizedStrings.add_ad.publish_ad}
              </button>
            </div>
          </div>
          <button
            className="all-btns gray-btn upper ml-auto btn-position-right"
            onClick={() => setWarning(prevState => !prevState)}
          >
            {localizedStrings.add_ad.cancel}
          </button>
        </div>
      </div>

      {warning && (
        <Confirmation
          name="warning"
          modal={warning}
          closeModal={closeModal2}
          title="OTKAŽI"
          text="Da li ste sigurni da želite da otkažete izmjene?"
        />
      )}

      {showQuestionsModal && (
        <Questions
          showModalProps={showQuestionsModal}
          closeModal={closeModal}
        />
      )}
    </main>
  );
};

function mapStateToProps(state) {
  return {
    activeCompanies: state.activeCompanies ? state.activeCompanies.data : [],
    countries: state.countries,
    cities: state.cities,
    addings: state.addings
  };
}

const mapDispatchToProps = {
  getCountries: getAllCountries,
  getCities: getAllCities,
  processCreateAd: createAd,
  loadAllActiveCompanies: getAllActiveCompanies
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAd);