import * as types from '../actions/actionTypes';
import initialState from './initialState';
import localizedStrings from '../../utils/localizedStrings';

export default function userReducer(state = initialState.auth, action) {
  switch (action.type) {
    case types.LOGIN_USER_SUCCESS:
      return {
        successMessage: localizedStrings.login.successMessage,
        errorMessage: ''
      };
    case types.LOGIN_USER_FAILURE:
      return {
        errorMessage: localizedStrings.login.errorMessage,
        successMessage: ''
      };
    default:
      return state;
  }
}
