import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import configureStore from './redux/configureStore';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

//const store = configureStore();
import { store } from './redux/v2/store';
import AuthWrapper from './wrapper/auth';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';

const Theme = createMuiTheme();

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={Theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Provider store={store}>
          <AuthWrapper>
            <Router>
              <App />
            </Router>
          </AuthWrapper>
        </Provider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
