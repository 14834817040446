import axios from 'axios';
import qs from 'qs';

import { apiBaseUrl, token } from './config';

/*
export const getAdminCompaniesAd = data => {
  return axios({
    method: 'post',
    url: `${apiBaseUrl}/api/jobs/getAdminCompaniesAds`,
    headers: {
      Authorization: token
    },
    data
  }).then(res => res.data);
};*/

export const archiveAd = data => {
  return axios({
    method: 'post',
    url: `${apiBaseUrl}/api/jobs/archiveAds`,
    data
  }).then(res => res.data);
};

export const getAdminCompaniesAd = params => {
  return axios({
    method: 'get',
    url: `${apiBaseUrl}/api/v2/admin/ads`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    params
  }).then(res => res.data);
};

export const deleteSingleAd = id => {
  return axios({
    method: 'delete',
    url: `${apiBaseUrl}/api/v2/admin/ads/${id}`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Accept: 'application/json'
    }
  }).then(res => res.data);
};

export const deleteMultipleAds = ids => {
  return axios({
    method: 'delete',
    url: `${apiBaseUrl}/api/v2/admin/ads`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Accept: 'application/json'
    },
    data: qs.stringify(ids)
  }).then(res => res.data);
};

export const archieveSingleAd = id => {
  return axios({
    method: 'patch',
    url: `${apiBaseUrl}/api/v2/admin/ads/${id}/set-archived`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Accept: 'application/json'
    }
  }).then(res => res.data);
};

export const archieveMultipleAds = data => {
  return axios({
    method: 'patch',
    url: `${apiBaseUrl}/api/v2/admin/ads/set-archived`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Accept: 'application/json'
    },
    data: qs.stringify(data)
  }).then(res => res.data);
};

export const resetSingleAd = id => {
  return axios({
    method: 'patch',
    url: `${apiBaseUrl}/api/v2/admin/ads/${id}/reset`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Accept: 'application/json'
    }
  }).then(res => res.data);
};

export const resetMultipleAds = data => {
  return axios({
    method: 'patch',
    url: `${apiBaseUrl}/api/v2/admin/ads/reset`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Accept: 'application/json'
    },
    data: qs.stringify(data)
  }).then(res => res.data);
};

export const getSingleAd = data => {
  return axios({
    method: 'get',
    url: `${apiBaseUrl}/api/v2/admin/ads/${data.id}`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  }).then(res => res.data);
};


export const createAd = data => {
  return axios({
    method: 'post',
    url: `${apiBaseUrl}/api/v2/admin/ads`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    data: data
  }).then(res => res.data);
};
