import { MenuItem } from '@material-ui/core';
import React, { Fragment } from 'react';
import { IconButtonWrapper, BadgeWrapper, MenuWrapper} from './styles';

const DropDownItem = ({ icon: Icon, count = 15, items = [], emptyMessage  = "You don't have any items"}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClose = handleFunc => {
    if (typeof handleFunc === 'function') handleFunc();
    setAnchorEl(null);
  };
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <Fragment>
      <IconButtonWrapper
        className="mui-menu-item"
        id="notifications"
        onClick={handleClick}
      >
        <BadgeWrapper badgeContent={count}>
          <Icon size="large" />
        </BadgeWrapper>
      </IconButtonWrapper>
      <MenuWrapper
        elevation={4}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'notifications'
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        getContentAnchorEl={null}
        PaperProps={{
          sx: { borderRadius: 10 }
        }}
      >
        {items.map((x, y) => (
          <MenuItem key={y} onClick={() => handleClose(x)}>
            {x.content}
          </MenuItem>
        ))}
        {!items.length ? (
          <MenuItem disabled onClick={() => handleClose()}>
            {emptyMessage}
          </MenuItem>
        ) : (
          ''
        )}
      </MenuWrapper>
    </Fragment>
  );
};

export default DropDownItem;