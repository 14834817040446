import React, { useEffect, useState } from 'react';
import { CircleSvg } from '../components/Icons';
import localizedStrings from '../utils/localizedStrings';
import { getDateTime } from '../utils/commonFunctions';
import {
  getNotifications,
  viewNotification
} from '../redux/actions/userActions';
import { connect } from 'react-redux';
import Pagination from '../components/Pagination';

const Notifications = ({
  loadNotifications,
  viewSingleNotification,
  all_notifications
}) => {
  const [params, setParams] = useState({ offset: 0, limit: 5 });

  useEffect(() => {
    loadNotifications(params);
  }, [params, loadNotifications]);

  const goTo = (e, id) => {
    viewSingleNotification({ id: id });
  };

  const handlePageChange = index => {
    setParams(prevState => {
      return { ...prevState, offset: index * params.limit };
    });
  };

  return (
    <main id="mainContent" className="xl-resize">
      <div id="close-area"></div>
      <header>
        <div className="title-box">
          <h1>{localizedStrings.notifications.title}</h1>
          <p>{localizedStrings.notifications.description}</p>
        </div>
      </header>

      <div className="msg-container">
        {all_notifications.count > 0 ? (
          <>
            {all_notifications.notifications.map((e, index) => {
              return (
                <div key={index}>
                  <span>
                    <div className="msg-body">
                      <div className="msg-text-container">
                        {/*TODO: fix styling*/}
                        <div style={{ width: '10px' }}>
                          {e.seen !== 1 ? <CircleSvg fill="#F5712B" /> : null}
                        </div>
                        <div className="ml-1">
                          <p
                            className="msg-text"
                            onClick={event => goTo(event, e.id)}
                          >
                            {e.title ? e.title : ' / '}
                          </p>
                          <p>{e.text ? e.text : ' / '}</p>
                        </div>
                      </div>
                      <div>
                        {e.created_at ? getDateTime(e.created_at) : '  /'}
                      </div>
                    </div>
                  </span>
                </div>
              );
            })}
            <Pagination
              params={params}
              count={all_notifications.count}
              handlePageChange={handlePageChange}
            />{' '}
          </>
        ) : (
          <p>{localizedStrings.notifications.no_notifications}</p>
        )}
      </div>
    </main>
  );
};

function mapStateToProps(state) {
  return {
    all_notifications: state.notifications
  };
}

const mapDispatchToProps = {
  loadNotifications: getNotifications,
  viewSingleNotification: viewNotification
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
