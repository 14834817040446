// import React, { useEffect, useState } from 'react';
// import { Logo } from '../components/Icons';
// import { CustomInput } from '../components/Inputs';
// import { BlueBtn } from '../components/Button';
// import localizedStrings from '../utils/localizedStrings';
// import { connect } from 'react-redux';
// import { loginUser } from '../redux/actions/authActions';
// import { Title } from '../components/Title';

// const Login = ({ handleLogin, serverErrorMessage, serverSuccessMessage }) => {
//   const [state, setState] = useState({
//     email: '',
//     password: '',
//     errorMessage: '',
//     successMessage: ''
//   });

//   useEffect(() => {
//     setState(state => ({ ...state, errorMessage: serverErrorMessage }));
//   }, [serverErrorMessage]);

//   useEffect(() => {
//     setState(state => ({
//       ...state,
//       successMessage: serverSuccessMessage
//     }));
//   }, [serverSuccessMessage]);

//   const submit = () => {
//     const { email, password } = state;

//     if (email.length === 0) {
//       setState({ ...state, errorMessage: localizedStrings.login.enterEmail });
//       return;
//     }

//     if (password.length === 0) {
//       setState({
//         ...state,
//         errorMessage: localizedStrings.login.enterPassword
//       });
//       return;
//     }

//     handleLogin(email, password);
//   };

//   const handleInput = (e, name) => {
//     let value = e.target.value;
//     setState({ ...state, [name]: value });
//   };

//   return (
//     <main className="login-register">
//       <Title title="Prijava"/>
//       <div className="log-reg-container admin-log-container">
//         <div className="logo-box mt-3">
//           <Logo width="250" height="70" />
//         </div>

//         <form>
//           <CustomInput
//             label={localizedStrings.login.username}
//             onChange={handleInput}
//             name="email"
//             value={state.email}
//           />
//           <CustomInput
//             label={localizedStrings.login.password}
//             onChange={handleInput}
//             type="password"
//             name="password"
//             value={state.password}
//           />
//           <BlueBtn
//             name={localizedStrings.login.login}
//             onClick={() => submit()}
//             className="w-320px m-auto"
//           />
//           <a href="/" className="new-pass">
//             {localizedStrings.login.forgetPassword}
//           </a>
//           <span
//             className={
//               state.errorMessage !== ''
//                 ? 'error '
//                 : state.successMessage !== ''
//                 ? 'success '
//                 : ''
//             }
//           >
//             {state.errorMessage} {state.successMessage}
//           </span>
//         </form>
//       </div>
//     </main>
//   );
// };

// function mapStateToProps(state) {
//   return {
//     serverSuccessMessage: state.auth.successMessage || '',
//     serverErrorMessage: state.auth.errorMessage || ''
//   };
// }

// const mapDispatchToProps = {
//   handleLogin: loginUser
// };

// export default connect(mapStateToProps, mapDispatchToProps)(Login);

import React, { useEffect, useState } from 'react';
import { Logo } from '../components/Icons';
import { CustomInput } from '../components/Inputs';
import { BlueBtn } from '../components/Button';
import localizedStrings from '../utils/localizedStrings';
import { Title } from '../components/Title';
import { AdminAPI, AuthAPI } from '../api/v2';
import axios from 'axios';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { fetchLogin, fetchUser } from '../redux/v2/global/slice';
import { globalActions } from '../redux/v2/global';

const Login = () => {
  const [state, setState] = useState({
    email: '',
    password: ''
  });

  const [messageState, setMessageState] = useState({
    message: '',
    class: ''
  });

  const history = useHistory();
  const dispatch = useDispatch();

  const clearMessageState = () => setMessageState({ message: '', class: '' });

  const handleChange = (e, v) => {
    if (messageState.message) clearMessageState();
    setState({ ...state, [v]: e.target.value });
  };

  const handleSubmit = async () => {
    if (!state.email.trim().length) {
      return setMessageState({
        ...messageState,
        message: localizedStrings.login.enterEmail,
        class: 'error'
      });
    } else if (!state.password.trim().length) {
      return setMessageState({
        ...messageState,
        message: localizedStrings.login.enterPassword,
        class: 'error'
      });
    } else {
      try {
        const { token } = await AuthAPI.login(state);
        localStorage.setItem('Authorization', token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        const user = await AdminAPI.getInfo();
        dispatch(globalActions.setUser(user));
        history.push('/')
      } catch(e) {
        setMessageState({ ...messageState, message: 'Pogresna email adresa ili password!', class: 'error'})
      }
    }
  };

  return (
    <main className="login-register">
      <Title title="Prijava" />
      <div className="log-reg-container admin-log-container">
        <div className="logo-box mt-3">
          <Logo width="250" height="70" />
        </div>

        <form>
          <CustomInput
            label={localizedStrings.login.username}
            onChange={handleChange}
            name="email"
            value={state.email}
          />
          <CustomInput
            label={localizedStrings.login.password}
            onChange={handleChange}
            type="password"
            name="password"
            value={state.password}
          />
          <BlueBtn
            name={localizedStrings.login.login}
            onClick={handleSubmit}
            className="w-320px m-auto"
          />
          <a href="/" className="new-pass">
            {localizedStrings.login.forgetPassword}
          </a>
          <span
            className={messageState.class}
          >
            {messageState.message}
          </span>
        </form>
      </div>
    </main>
  );
};

export default Login;
