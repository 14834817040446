import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function singleUser(state = initialState.single_user, action) {
  switch (action.type) {
    case types.LOAD_SINGLE_USER_DATA_SUCCESS:
      return action.data;
    case types.LOAD_SINGLE_USER_DATA_FAILURE:
      return {};
    case types.UPDATE_PROFILE_IMAGE_SUCCESS:
      return { ...state, profile_image: action.data.profile_image };
    default:
      return state;
  }
}
