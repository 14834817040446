import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function archivedAdsReducer(
  state = initialState.archivedAds,
  action
) {
  switch (action.type) {
    case types.LOAD_ARCHIVED_ADS_SUCCESS:
      return action.data;
    case types.LOAD_ARCHIVED_ADS_FAILURE:
      return [];

    default:
      return state;
  }
}
