import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  DropdownIcon2,
  EyeIcon,
  FiltersIcon,
  FolderIcon,
  PlusIcon,
  SearchIcon,
  MegaphoneIcon
} from '../../components/Icons';
import localizedStrings from '../../utils/localizedStrings';
import { getAllActiveAds, getAllAds } from '../../redux/actions/jobsActions';
import { connect } from 'react-redux';
import { Spinner } from '../../components/Common';
import { apiBaseUrl, imagePlaceholder } from '../../api/config';

import Confirmation from './modals/confirmation';
import Filter from './modals/filter';
import { getDate } from '../../utils/commonFunctions';
import Pagination from '../../components/Pagination';
import { archieveMultipleAds, archieveSingleAd } from '../../api/jobs';

const CompanyAds = ({ loadAllAds, activeAds, count, match }) => {
  let history = useHistory();
  let params_id = match.params;

  const [params, setParams] = useState({
    offset: 0,
    limit: 5,
    user_id: params_id ? params_id.id : null
  });
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [userIds, setUserIds] = useState({});
  const [allItems, setAllItems] = useState(0);
  const [allSelected, setAllSelected] = useState(false);
  const [singleID, setSingleID] = useState();

  const [modals, setModals] = useState({
    confirmation_all: false,
    confirmation_one: false,
    filter: false
  });

  useEffect(() => {
    loadAllAds(params);
  }, [params, loadAllAds]);

  useEffect(() => {
    if (activeAds) {
      let temp = {};
      activeAds.forEach(ad => {
        temp[ad.id] = false;
      });
      setUserIds(temp);
    }
  }, [activeAds]);

  const showModal = (prop, id) => event => {
    setModals({ ...modals, [prop]: true });
    setSingleID(id);
  };

  const closeModal = (prop, answer, values) => {
    setModals({ ...modals, [prop]: false });

    if (answer && prop === 'filter') {
      if (values) {
        setParams({
          ...params,
          user_id: values.company ? values.company.id : '',
          type_of_work_id: values.type_of_work ? values.type_of_work.id : '',
          city_id: values.location ? values.location.id : '',
          is_active: values.status ? values.status : '',
          term: ''
        });
      }
    }

    if (
      (prop === 'confirmation_all' || prop === 'confirmation_one') &&
      answer
    ) {
      let temp = [];

      for (const property in userIds) {
        if (userIds[property]) temp.push(parseInt(property));
      }

      if (temp.length === 1) {
        archieveSingleAd(temp[0])
          .then(res => {
            console.log(res);
            setParams({ limit: 5, offset: 0 });
          })
          .catch(err => console.log(err));
      } else if (temp.length > 1) {
        const data = {
          ids: temp
        };
        console.log(data);

        archieveMultipleAds(data)
          .then(res => {
            console.log(res);
            setParams({ limit: 5, offset: 0 });
          })
          .catch(err => console.log(err));
      } else {
        archieveSingleAd(singleID)
          .then(res => {
            console.log(res);
            setParams({ limit: 5, offset: 0 });
          })
          .catch(err => console.log(err));
      }
    }
  };

  const goto = (event, ad, index) => {
    event.preventDefault();
    history.push(`/ads/${ad.id}`);
  };

  const searchAds = (e, name) => {
    let value = e.target.value;
    setParams({ ...params, offset: 0, term: value });
  };

  const handleOffsetChange = e => {
    setParams(prevState => {
      return { ...prevState, limit: parseInt(e.target.value) };
    });
  };

  const handlePageChange = index => {
    setParams(prevState => {
      return { ...prevState, offset: index * params.limit };
    });
  };

  const getSelectedIds = () => {
    let temp = [];
    Object.entries(userIds).forEach(item => {
      item[1] && temp.push(parseInt(item[0]));
    });
    return temp;
  };

  const toggleSelect = id => {
    setUserIds(prevState => {
      return { ...prevState, [id]: prevState[id] ? false : true };
    });

    if (!userIds[id]) setAllItems(allItems + 1);
    else setAllItems(allItems - 1);
  };

  const toggleAll = () => {
    const newValue = allSelected ? false : true;
    setAllSelected(newValue);
    newValue ? setAllItems(activeAds.length) : setAllItems(0);
    activeAds.forEach(ad => {
      setUserIds(prevState => {
        return { ...prevState, [ad.id]: newValue };
      });
    });
  };

  if (activeAds) {
    if (activeAds.length > 0) {
      return (
        <main id="mainContent" className="xl-resize">
          <header>
            <div className="title-box">
              <h1>{localizedStrings.ads.title}</h1>
              <p>{localizedStrings.ads.description}</p>
            </div>

            <div className="btn-wrapper">
              <Link to="/add-ad">
                <button className="all-btns blue-btn upper btn-order-sm-last">
                  <PlusIcon className="hide-on-mobile-sm" />
                  {localizedStrings.ads.add_ad}
                </button>
              </Link>
              <button
                className="all-btns gray-btn upper btn-order-sm-last"
                onClick={allItems > 0 && showModal('confirmation_all')}
              >
                <FolderIcon className="hide-on-mobile-sm" />
                {localizedStrings.ads.archive} ({allItems})
              </button>
            </div>

            <div className="search-box-wrapper">
              <div className="wrapper w-select-10 justify-between">
                <div className="select-wrapper select-row-wrapper">
                  <select
                    className="select select-row-num"
                    name="num"
                    id=""
                    value={params.limit}
                    onChange={handleOffsetChange}
                  >
                    <option value="5" defaultValue>
                      Prikaži 5
                    </option>
                    <option value="10">Prikaži 10</option>
                    <option value="25">Prikaži 25</option>
                    <option value="50">Prikaži 50</option>
                  </select>
                  <DropdownIcon2 className="select-arrow" />
                </div>
              </div>

              <div className="wrapper max-width-search-input">
                <div className="search-wrapper">
                  <input
                    type="text"
                    placeholder="Pretraga"
                    className="input-class"
                    onChange={searchAds}
                  />
                  <button className="search">
                    <SearchIcon />
                  </button>
                </div>
                <button className="settings-btn" onClick={showModal('filter')}>
                  <FiltersIcon />
                </button>
              </div>
            </div>
          </header>

          <table className="concourse-table">
            <thead>
              <tr className="table-header">
                <td className="column xs-column df-cc">
                  <button
                    className={`empty-circle ${allSelected === true ? 'fill' : ' '}`}
                    onClick={() => toggleAll()}
                  ></button>
                </td>
                <td className="column s-column">
                  <p>Id</p>
                </td>
                <td className="column xxl-column">
                  <p>Naslov oglasa</p>
                </td>
                <td className="column l-column-17">
                  <p>Tip posla</p>
                </td>
                <td className="column m-column">
                  <p>Lokacija</p>
                </td>
                <td className="column m-column">
                  <p>Ističe</p>
                </td>
                <td className="column l-column-25 p-0">
                  <div className="sub-ceil">
                    <p>Status</p>
                  </div>
                  <div className="sub-ceil">
                    <p>Pogledaj</p>
                  </div>
                  <div className="sub-ceil">
                    <p>Arhiviraj</p>
                  </div>
                </td>
              </tr>
            </thead>

            <tbody>
              {activeAds.map((ad, index) => {
                return (
                  <tr
                    id="row1"
                    className={`table-row favourite-copmany  text-wrap ${userIds[ad.id] ? ' omiljeni-oglas ' : ' '
                      }`}
                    key={index}
                  >
                    <td className="ceil xs-column">
                      <button
                        className="btn-circle gray-circle"
                        onClick={() => toggleSelect(ad.id)}
                      ></button>
                    </td>
                    <td className="ceil s-column">
                      <p>{ad.id ? ad.id : ' - '}</p>
                    </td>
                    <td className="ceil ceil-with-logo xxl-column">
                      <div className="logo-holder">
                        <img
                          src={
                            ad.creator && ad.creator.profile_image
                              ? ad.creator.profile_image
                              : imagePlaceholder
                          }
                          alt="profile"
                        />
                      </div>
                      <div className="ceil-text-wrap">
                        <p>
                          <strong>{ad.title ? ad.title : ' - '}</strong>
                        </p>
                        <p>{ad.creator ? ad.creator.full_name : ' - '}</p>
                      </div>
                    </td>
                    <td className="ceil l-column-17">
                      <p>{ad.type_of_work ? ad.type_of_work.name : ' - '}</p>
                    </td>
                    <td className="ceil m-column">
                      <p>{ad.city ? ad.city.name : ' - '}</p>
                    </td>
                    <td className="ceil m-column">
                      <p>{ad.created_at ? getDate(ad.created_at) : '-'}</p>
                    </td>
                    <td className="ceil l-column-25 last-ceil">
                      <div className="sub-ceil">
                        <p className="text-center">
                          {ad.is_active === 1 ? 'Aktuelno' : 'Isteklo'}
                        </p>
                      </div>
                      <div className="sub-ceil">
                        <button className="see" onClick={e => goto(e, ad)}>
                          <EyeIcon />
                        </button>
                      </div>
                      <div className="sub-ceil">
                        <button
                          className="edit"
                          onClick={showModal('confirmation_one')}
                        >
                          <FolderIcon />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          <table className="concourse-table-lg company-table-lg">
            <tbody>
              {activeAds.length > 0 ? (
                activeAds.map((ad, index) => {
                  return (
                    <tr id="row1-lg" key={index}>
                      <td className="row-title company-title concourse-title">
                        <button className="btn-circle gray-circle"></button>
                        <Link
                          to={`/ads/${ad.id}`}
                          className="name one-line-text"
                        >
                          <strong>{ad.title ? ad.title : ' - '}</strong>
                        </Link>
                      </td>
                      <td className="row-body">
                        <p>
                          <strong>Tip posla:</strong>{' '}
                          {ad.type_of_work ? ad.type_of_work.name : ' - '}
                        </p>
                        <p>
                          <strong>Lokacija:</strong>
                          {ad.city ? ad.city.name : ' - '}
                        </p>
                        <p>
                          <strong>Status:</strong>{' '}
                          {ad.is_active === 1 ? 'Aktuelno' : 'Isteklo'}
                        </p>
                        <p>
                          <strong>Broj prijava:</strong>{' '}
                          {ad.number_of_applications
                            ? ad.number_of_applications
                            : 0}
                        </p>
                        <div className="buttons-box">
                          <input
                            type="checkbox"
                            name=""
                            id=""
                            className="settings-toggle"
                          />

                          <button
                            className="edit"
                            onClick={showModal('confirmation_one', ad.id)}
                          >
                            <FolderIcon />
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td>
                    <p>Trenutno nema oglasa</p>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <Pagination
            params={params}
            count={count}
            handlePageChange={handlePageChange}
          />
          <Confirmation
            name="confirmation_all"
            modal={modals.confirmation_all}
            closeModal={closeModal}
            title="ARHIVIRANJE"
            text="Da li ste sigurni da želite da arhivirate izabrane stavke?"
          />

          <Confirmation
            name="confirmation_one"
            modal={modals.confirmation_one}
            closeModal={closeModal}
            title="ARHIVIRANJE"
            text="Da li ste sigurni da želite da arhivirate izabranu stavku?"
          />

          <Filter modal={modals.filter} closeModal={closeModal} />
        </main>
      );
    } else {
      return (
        <main id="mainContent" className="xl-resize">
          <header>
            <div className="title-box">
              <h1>{localizedStrings.ads.title}</h1>
              <p>{localizedStrings.ads.description}</p>
            </div>

            <div className="btn-wrapper">
              <Link to="/add-ad">
                <button className="all-btns blue-btn upper btn-order-sm-last">
                  <PlusIcon className="hide-on-mobile-sm" />
                  {localizedStrings.ads.add_ad}
                </button>
              </Link>
              <button
                className="all-btns gray-btn upper btn-order-sm-last"
                onClick={showModal('confirmation_all')}
              >
                <FolderIcon className="hide-on-mobile-sm" />
                {localizedStrings.ads.archive}
              </button>
            </div>

            <div className="search-box-wrapper">
              <div className="wrapper max-width-50 justify-between">
                <div className="select-wrapper select-row-wrapper">
                  <select
                    className="select select-row-num"
                    name="num"
                    id=""
                    value={params.limit}
                    onChange={handleOffsetChange}
                  >
                    <option value="5" defaultValue>
                      Prikaži 5
                    </option>
                    <option value="10">Prikaži 10</option>
                    <option value="25">Prikaži 25</option>
                    <option value="50">Prikaži 50</option>
                  </select>
                  <DropdownIcon2 className="select-arrow" />
                </div>
              </div>

              <div className="wrapper max-width-50">
                <div className="search-wrapper">
                  <input
                    type="text"
                    placeholder="Pretraga"
                    className="input-class"
                    onChange={searchAds}
                  />
                  <button className="search">
                    <SearchIcon />
                  </button>
                </div>
                <button className="settings-btn" onClick={showModal('filter')}>
                  <FiltersIcon />
                </button>
              </div>
            </div>
          </header>
          <div className="empty-table">
            <MegaphoneIcon height="100px" fill="gray" />
            <p>Trenutno nema oglasa.</p>
          </div>
        </main>
      );
    }
  } else {
    return (
      <main id="mainContent">
        <div className="spinner-container" style={{ marginTop: '40%' }}>
          <Spinner />
        </div>
      </main>
    );
  }
};

function mapStateToProps(state) {
  return {
    activeAds: state.activeAds ? state.activeAds.data : [],
    count: state.activeAds
      ? state.activeAds.meta
        ? state.activeAds.meta.total
        : 0
      : 0
  };
}

const mapDispatchToProps = {
  loadAllAds: getAllActiveAds
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyAds);
