import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function conversationReducer(
  state = initialState.conversation,
  action
) {
  switch (action.type) {
    case types.LOAD_CONVERSATION_SUCCESS:
      return action.data;
    case types.LOAD_CONVERSATION_FAILURE:
      return {};
    case types.SEND_SINGLE_MESSAGE_SUCCESS:
      return {
        ...state,
        messages: [action.data.message].concat(state.messages)
      };
    default:
      return state;
  }
}
