import LocalizedStrings from 'react-localization';

const localizedStrings = new LocalizedStrings({
  crnogorski: {
    dashboard: {
      title: 'Dashboard',
      welcome: 'Dobrodošao',
      new_company: 'NOVE KOMPANIJE',
      no_new_companies: 'Trenutno nema novih kompanija.',
      date_created: 'Kreirano',
      sum_users: 'Ukupan broj korisnika',
      sum_companies: 'Ukupan broj kompanija',
      sum_employees: 'Ukupan broj kandidata',
      sum_ads: 'Ukupan broj oglasa',
      show_all: 'POGLEDAJTE SVE',
      show_list: 'POGLEDAJTE LISTU',
      stats_title: 'STATISTIKA OGLASA KOMPANIJA',
      top_companies_title: 'TOP KOMPANIJE',
      top_companies_desc: 'Kompanije sa najvećim brojem oglasa',
      top_companies_th_company: 'NAZIV KOMPANIJE',
      top_companies_th_area: 'DJELATNOST',
      top_companies_th_country: 'DRŽAVA',
      top_companies_th_city: 'GRAD',
      top_companies_th_application: 'PRIJAVA',
      no_companies: 'Trenutno nema kompanija',
      look: 'Pogledaj'
    },
    login: {
      forgetPassword: 'Zaboravili ste lozinku? Zatražite novu!',
      login: 'PRIJAVITE SE',
      password: 'Lozinka',
      username: 'Ime ili email',
      enterPassword: 'Unesite šifru.',
      enterEmail: 'Unesite email.',
      errorMessage:
        'Korisničko ime ili lozinka nijesu tačni. Molimo unesite ispravne podatke.',
      successMessage: 'Uspješno ste ulogovani.'
    },
    notFound: {
      page_not_found: 'Stranica nije pronađena.',
      try_again_later: 'Pokušajte kasnije.',
      go_back: 'Vratite se nazad'
    },
    navigation: {
      dashboard: 'Dashboard',
      users: 'Korisnici',
      active_users: 'Svi korisnici',
      archived_users: 'Arhivirani korisnici',
      companies: 'Kompanije',
      active_companies: 'Sve kompanije',
      archived_companies: 'Arhivirane kompanije',
      ads: 'Oglasi',
      create_ad: 'Dodaj oglas',
      active_ads: 'Aktivni oglasi',
      archived_ads: 'Arhivirani oglasi',
      candidates: 'Kandidati',
      active_candidates: 'Svi kandidati',
      archived_candidates: 'Arhivirani kandidati',
      settings: 'Podešavanja',
      profile: 'Profil',
    },
    header: {
      notifications: 'Obavještenja',
      no_notifications: 'Nema trenutno notifikacija',
      show_all: 'Pogledaj sve',
      messages: 'Poruke',
      no_messages: 'Trenutno nema poruka'
    },
    messagesPopUp: {
      title: 'ODGOVORITE NA PORUKU',
      receiver: 'Poruka za',
      subject: 'Naslov poruke',
      body: 'Tekst poruke',
      placeholder: 'Upišite',
      send: 'POŠALJITE PORUKU',
      message: 'Poruka',
      answer: 'Odgovorite na poruku'
    },
    notifications: {
      title: 'Obavještenja',
      description: 'Sva obavještenja profila',
      no_notifications: 'Trenutno nema notifikacija'
    },
    messages: {
      title: 'Poruke',
      description: 'Sve poruke kandidata',
      header: 'Sve poruke',
      placeholder: 'Pronađi oglas...',
      no_messages: 'Trenutno nemaš poruka'
    },
    ads: {
      title: 'Oglasi kompanija',
      description: 'Upravljanje oglasima kompanija',
      add_ad: 'Dodajte Oglas',
      archive: 'Arhiviraj'
    },
    edit_ad: {
      title: 'Izmeni oglas',
      description: 'Sve informacije o oglasima kompanije',
      edit_ad: "Izmenite oglas"
    },
    archived_ad: {
      title: 'Arhivirani oglasi kompanija',
      description: 'Upravljanje arhiviranim oglasima kompanija',
    },
    add_ad: {
      title: 'Oglasi',
      description: 'Sve informacije o oglasima kompanije',
      add_new_ad: 'Dodajte novi oglas',
      ad_title: 'Naslov oglasa',
      ad_company: 'Naziv kompanije',
      label: 'Upišite ovdje',
      country: 'Država u kojoj zapošljavate',
      cities: 'Grad u kojoj zapošljavate',
      category: 'Kategorija posla',
      type: 'Tip posla',
      job_desc: 'Opis posla',
      save_ad: 'Sačuvaj oglas',
      publish_ad: 'Postavi oglas',
      cancel: 'Otkaži',
      ask_questions: 'Postavite pitanja kandidatima',
      pick_predefined: 'Izaberite gotova pitanja',
      first_question: '1. Pitanje za kandidata',
      second_question: '2. Pitanje za kandidata',
      question_placeholder: 'Tekst pitanja?',
      working_hours: 'Radno vrijeme'
    },
    question_modal: {
      header: 'Izaberite gotova pitanja',
      desc: 'Generička pitanja za kandidate',
      confirm: 'Potvrdite pitanja'
    },
    calendar: { choose_date: 'Odaberite period trajanja' },
    candidates_edit: {
      title: 'Kandidati',
      desc: 'Upravljanje kandidatima',
      deactivate_btn: 'Deaktiviraj profil',
      cancel: 'Otkažite',
      save: 'Sačuvajte',
      contact_info: 'Kontakt informacije',
      my_cv: 'MOJ CV',
      name: 'Ime',
      surname: 'Prezime',
      placeholder: 'Upišite ovdje',
      gender: 'Pol',
      birthday: 'Datum rođenja',
      education_level: 'Nivo obrazovanja',
      email: 'Kontakt email',
      phone: 'Kontakt telefon',
      country: 'Država',
      address: 'Ulica i broj prebivališta',
      city: 'Grad',
      zip_code: 'Poštanski broj'
    },
    group_message: {
      header: 'GRUPNA PORUKA',
      header_single: 'PORUKA',
      desc: 'Pošaljite poruku svim selektovanim kandidatima',
      desc_single: 'Pošaljite poruku kandidatu',
      confirm: 'Pošaljite',
      placeholder_title: 'Naslov poruke',
      placeholder_body: 'Tekst poruke'
    }
  }
});

localizedStrings.setLanguage(
  process.env.REACT_APP_DEFAULT_LANGUAGE || 'crnogorski'
);

export default localizedStrings;
