import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function activeCompaniesReducer(
  state = initialState.activeCompanies,
  action
) {
  switch (action.type) {
    case types.LOAD_ACTIVE_COMPANIES_SUCCESS:
      return action.data;
    case types.LOAD_ACTIVE_COMPANIES_FAILURE:
      return [];
    case types.ARCHIVE_MULTIPLE_USERS_SUCCESS:
      return {
        ...state,
        count: state.count - action.response.ids.length,
        users: state.users.filter(user => {
          return action.response.ids.indexOf(user.id) === -1;
        })
      };
    default:
      return state;
  }
}
