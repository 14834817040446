import { combineReducers } from 'redux';
import user from './userReducer';
import auth from './authReducer';
import notifications from './notificationsReducer';
import singleMessage from './singleMessageReducer';
import dashboard from './dashboardReducer';
import messages from './messagesReducer';
import conversation from './conversationReducer';
import ads from './adsReducer';
import singleUser from './singleUserReducer';
import allUsers from './allUsersReducer';
import countries from './countriesReducer';
import cities from './citiesReducer';
import addings from './addingsReducer';
import activeCandidates from './activeCandidatesReducer';
import archivedCandidates from './archivedCandidatesReducer';
import singleAd from './singleAdReducer';
import searchCities from './searchCitiesReducer';
import { deactivateUser } from '../actions/userActions';
import searchCompanies from './searchedCompaniesReducer';
import activeUsersReducer from './activeUsersReducer';
import archivedUsersReducer from './archivedUsersReducer';
import activeCompaniesReducer from './activeCompaniesReducer';
import archivedCompaniesReducer from './archivedCompaniesReducer';
import activeAdsReducer from './activeAds';
import archivedAdsReducer from './archivedAds';

const rootReducer = combineReducers({
  user: user,
  auth: auth,
  notifications: notifications,
  singleMessage: singleMessage,
  dashboard: dashboard,
  messages: messages,
  conversation: conversation,
  ads: ads,
  singleUser: singleUser,
  allUsers: allUsers,
  countries: countries,
  cities: cities,
  addings: addings,
  activeCandidates: activeCandidates,
  archivedCandidates: archivedCandidates,
  activeUsers: activeUsersReducer,
  archivedUsers: archivedUsersReducer,
  activeCompanies: activeCompaniesReducer,
  archivedCompanies: archivedCompaniesReducer,
  singleAd: singleAd,
  search_cities: searchCities,
  deacttivate_user: deactivateUser,
  search_companies: searchCompanies,
  activeAds: activeAdsReducer,
  archivedAds: archivedAdsReducer
});

export default rootReducer;
