import React, { useState, useRef } from 'react';
import { apiBaseUrl, videoPlaceholder } from '../../../api/config';
import { XIcon, PlayIcon } from '../../../components/Icons';

const ShowVideo = props => {
  const [play_video, setPlay_video] = useState(false);
  const video_container = useRef(null);

  const closeModal = prop => event => {
    props.closeModal(prop);
    video_container.current.pause();
    setPlay_video(false);
  };

  const pauseVideo = e => {
    if (e.target.paused) {
      e.target.play();
      setPlay_video(true);
    } else {
      e.target.pause();
      setPlay_video(false);
    }
  };

  const playVideo = e => {
    video_container.current.play();
    setPlay_video(true);
  };

  return (
    <div
      className="modal-bg"
      style={{ display: props.modal ? 'block' : 'none' }}
    >
      <div className="modal l-modal">
        <div className="modal-header">
          <h5>VIDEO PREZENTACIJA</h5>
          <button className="close-btn" onClick={closeModal('video')}>
            <XIcon />
          </button>
        </div>
        <div className="modal-body">
          <div className="rec-box">
            {props.video !== '' ? (
              <video
                className="rec-box"
                loop
                preload="auto"
                width="400"
                style={{
                  filter: !play_video ? 'brightness(0.5)' : 'brightness(1)'
                }}
                ref={video_container}
                onClick={pauseVideo}
                src={props.video}
              ></video>
            ) : (
              <img
                src={videoPlaceholder}
                className="rec-box"
                alt="video placeholder"
              />
            )}

            {!play_video && props.video !== '' ? (
              <button className="play-video" onClick={playVideo}>
                <PlayIcon />
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowVideo;
