import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function searchCities(
  state = initialState.search_cities,
  action
) {
  switch (action.type) {
    case types.LOAD_SEARCH_CITIES_SUCCESS:
      return action.data;
    case types.LOAD_SEARCH_CITIES_FAILURE:
      return [];
    default:
      return state;
  }
}
