import { Edit, Replay } from '@material-ui/icons';
import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { CompanyAPI } from '../../api/v2';
import Button from '../../components/button/index';
import InputCard from '../../components/inputcard';
import { Title } from '../../components/Title';
import localizedStrings from '../../utils/localizedStrings';

const PreviewCompany = () => {
  const [company, setCompany] = useState(null);

  const { id } = useParams();

  const [loaded, setLoaded] = useState(false);

  const history = useHistory();

  const goTo = x => {
    history.push(x);
  };

  const getCompany = async () => {
    const res = await CompanyAPI.companyInfo(id);
    setCompany(res);
    setLoaded(true);
  };

  useEffect(() => {
    getCompany();
  }, []);

  return (
    <Fragment>
      <main id="mainContent" className="xl-resize">
        <Title title="Pregled kompanije" />
        <header>
          <div className="title-box">
            <h1>Pregled kompanije</h1>
            <p>Informacije o kompaniji</p>
          </div>
        </header>

        <div className="add-ad-container">
          <h2 className="title">Pregled kompanije</h2>
          {loaded ? (
            <Fragment>
              <div className="candidate-main">
                <div className="company-background-holder">
                  <div className="company-background">
                    <img
                      src={company.background_image}
                      alt={company.full_name}
                    />
                  </div>
                  <div className="candidate-profile-row">
                    <div className="candidate-image-holder">
                      <img
                        src={
                          company.profile_image ||
                          '/images/mockup/profile-img-placeholder.png'
                        }
                        alt={company.full_name}
                      />
                    </div>
                  </div>
                </div>
                <div className="candidate-row">
                  <div className="add-ad-half">
                    <div className="add-ad-full-info">
                      <h1 className="force-space-b">{company.full_name}</h1>
                      <div className="add-ad-row-info">
                        <span>Delatnost kompanije</span>
                        {company.company_activity || '/'}
                      </div>
                      <div className="add-ad-row-info">
                        <span>Opis kompanije</span>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: company.company_description
                          }}
                        ></div>
                      </div>
                      <div className="add-ad-row-info">
                        <span>Video</span>
                        <div className="candidate-video">
                          {company.company_video ? (
                            <video src={company.company_video} controls></video>
                          ) : (
                            <img src="/images/mockup/video-placeholder.png" />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="add-ad-half">
                    {company.company_users?.map((x, y) => (
                      <InputCard
                        key={y}
                        className="force-space-b"
                        label={`${y + 1}# Kontakt osoba`}
                      >
                        <div className="add-ad-row-info">
                          <span>Kontakt osoba</span>
                          {x.contact_person || '/'}
                        </div>
                        <div className="add-ad-row-info">
                          <span>Pozicija kontakt osobe</span>
                          {x.contact_person_position || '/'}
                        </div>
                        <div className="add-ad-row-info">
                          <span>Kontakt telefon</span>
                          {x.contact_phone || '/'}
                        </div>
                        <div className="add-ad-row-info">
                          <span>Kontakt email</span>
                          {x.contact_mail || '/'}
                        </div>
                      </InputCard>
                    ))}
                    <div className="add-ad-row-info">
                      <span>Web adresa</span>
                      {company.website ? (
                        <a className="company-link" href={company.website}>
                          {company.website}
                        </a>
                      ) : (
                        '/'
                      )}
                    </div>
                    <div className="add-ad-row-info">
                      <span>Facebook profil</span>
                      {company.facebook ? (
                        <a className="company-link" href={company.facebook}>
                          {company.facebook}
                        </a>
                      ) : (
                        '/'
                      )}
                    </div>
                    <div className="add-ad-row-info">
                      <span>Instagram profil</span>
                      {company.instagram ? (
                        <a className="company-link" href={company.instagram}>
                          {company.instagram}
                        </a>
                      ) : (
                        '/'
                      )}
                    </div>
                    <div className="add-ad-row-info">
                      <span>Država</span> {company.country?.name || '/'}
                    </div>
                    <div className="add-ad-row-info">
                      <span>Grad</span> {company.city?.name || '/'}
                    </div>
                    <div className="add-ad-row-info">
                      <span>Adresa kompanije</span> {company.address || '/'}
                    </div>
                    <div className="add-ad-row-info">
                      <span>PIB</span> {company.pib || '/'}
                    </div>
                    <div className="add-ad-row-info">
                      <span>PDV</span> {company.pdv || '/'}
                    </div>
                  </div>
                </div>
              </div>
              <div className="add-ad-actions">
                <Button
                  className="button-inline"
                  onClick={() => goTo(`/company/${id}/edit`)}
                >
                  <Edit className="hide-on-mobile-sm" />
                  Izmeni kompaniju
                </Button>
                <Button
                  grayscale
                  className="button-inline"
                  onClick={() => goTo('/active-companies')}
                >
                  <Replay className="hide-on-mobile-sm" />
                  Nazad
                </Button>
              </div>
            </Fragment>
          ) : (
            <div className="edit-ad-loading">
              <h1>Kompanija se učitava</h1>
              <p>Molimo sačekajte</p>
            </div>
          )}
        </div>
      </main>
    </Fragment>
  );
};

export default PreviewCompany;
