import React, { useCallback, useEffect, useState } from 'react';
import { XIcon } from './Icons';
import localizedStrings from '../utils/localizedStrings';
import { sendMessage } from '../redux/actions/userActions';
import { connect } from 'react-redux';

function MessagePopUp({
  showModal,
  showModalProps,
  singleMessage,
  sendSingleMessage
}) {
  const [send, setSend] = useState(false);
  const [message, setMessage] = useState({});
  const [new_message, setNewMessage] = useState({ title: '', text: '' });

  useEffect(() => {
    setMessage(singleMessage);
  }, [singleMessage]);

  const closeModal = () => {
    showModal();
    setMessage('');
    setSend(false);
    setNewMessage({ title: '', text: '' });
  };

  const openAnswer = () => {
    setSend(true);
  };

  const handleChange = useCallback(
    ({ target }) => {
      setNewMessage({
        ...new_message,
        [target.name]: target.value
      });
    },
    [new_message]
  );

  const sendMessage = () => {
    sendSingleMessage({ ...new_message, sender_id: message.owner_id });
    closeModal();
  };

  return (
    <>
      {send && (
        <div
          className="modal-bg"
          style={{ display: showModalProps ? 'block' : 'none' }}
        >
          <div className="modal">
            <div className="modal-header">
              <h5>{localizedStrings.messagesPopUp.title}</h5>
              <button className="close-btn" onClick={closeModal}>
                <XIcon />
              </button>
            </div>
            <div className="modal-body">
              <form className="msg-body-wrapper">
                <p>
                  <strong>{localizedStrings.messagesPopUp.receiver}:</strong>{' '}
                  {message.full_name}
                </p>
                <div className="box box-100">
                  <p>{localizedStrings.messagesPopUp.subject}</p>
                  <input
                    type="text"
                    name="title"
                    value={new_message.subject}
                    placeholder={localizedStrings.messagesPopUp.placeholder}
                    className="input-class"
                    onChange={handleChange}
                  />
                </div>
                <div className="box box-100">
                  <p>{localizedStrings.messagesPopUp.body}</p>
                  <textarea
                    name="text"
                    value={new_message.body}
                    placeholder={localizedStrings.messagesPopUp.placeholder}
                    onChange={handleChange}
                  ></textarea>
                </div>
              </form>
              <button
                className="all-btns blue-btn upper msg-btn-pos"
                onClick={sendMessage}
              >
                {localizedStrings.messagesPopUp.send}
              </button>
            </div>
          </div>
        </div>
      )}

      {!send && (
        <div
          id="msg-1"
          className="modal-bg"
          style={{
            display: showModalProps && message !== '' ? 'block' : 'none'
          }}
        >
          <div className="modal">
            <div className="modal-header">
              <h5>{localizedStrings.messagesPopUp.message}</h5>
              <button className="close-btn" onClick={closeModal}>
                <XIcon />
              </button>
            </div>
            <div className="modal-body">
              <div className="msg-body-wrapper">
                <p>
                  {message && message.full_name ? message.full_name : ' - '}
                </p>
                <h5>{message && message.title ? message.title : ' - '}</h5>
                <p>{message && message.text ? message.text : ' - '} </p>
              </div>
              <button
                className="all-btns blue-btn upper msg-btn-pos"
                onClick={openAnswer}
              >
                {localizedStrings.messagesPopUp.answer}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function mapStateToProps(state) {
  return {};
}

const mapDispatchToProps = {
  sendSingleMessage: sendMessage
};

export default connect(mapStateToProps, mapDispatchToProps)(MessagePopUp);
