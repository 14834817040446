import React, { Fragment, useEffect, useState } from 'react';

const Months = ({ data = [] }) => {

  const maximum = Math.max(...data.map(x => x.data));

  return (
    <Fragment>
      {data.map(x => (
        <div className="yearly-stats-box" key={x.key}>
          <div className="month-cont">
            <div className="yearly-stats-box-inner">
              <p className="bold">{x.data}</p>
            </div>
            <div className="yearly-stats-box-inner">
              <div
                className={
                  x.data === maximum && maximum !== 0
                    ? 'data-fill-month-yl'
                    : 'data-fill-month'
                }
                style={{ height: x.size }}
              ></div>
              <p>{x.name}</p>
            </div>
          </div>
        </div>
      ))}
    </Fragment>
  );
};

export default Months;
