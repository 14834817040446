import { Switch, withStyles } from "@material-ui/core";

export const SwitchWrapper = withStyles(() => ({
  root: {
    '& .MuiSwitch-track': {
      background: 'linear-gradient(to top, #2f2f2f, #7f7f7f)'
    },
    '& .Mui-checked + .MuiSwitch-track': {
      background: 'linear-gradient(to top, #04274f, #2897fb)',
      opacity: 0.8
    },
    '& .Mui-checked .MuiSwitch-thumb': {
      background: 'linear-gradient(to top, #04274f, #2897fb)'
    },
    '& .Mui-checked .MuiTouchRipple-root': {
      color: '#2897fb'
    }
  }
}))(Switch);
