import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "60px",
    height: "60px",
    marginTop: "-5px",
    marginRight: "20px",
    "& > *": {
      margin: theme.spacing(1),
      width: "75px",
      height: "50px",
    },
  },
  spinner: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
}));

export const ImageAvatar = ({ alt, img }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Avatar
        alt={alt || "user image"}
        src={img || "/images/mockup/profile-img-placeholder.png"}
      />
    </div>
  );
};

export const Spinner = () => {
  const classes = useStyles();

  return (
    // <div className={classes.spinner}>
    //   <CircularProgress size="100px" />
    // </div>
    <div className={classes.spinner}>
      <div className="spinner">
        <div className="rect1"></div>
        <div className="rect2"></div>
        <div className="rect3"></div>
        <div className="rect4"></div>
        <div className="rect5"></div>
      </div>
    </div>
  );
};
