import * as types from './actionTypes';
import * as countryApi from '../../api/country';

export function loadAllCountriesRequest() {
  return { type: types.LOAD_ALL_COUNTRIES_REQUEST };
}

export function loadAllCountriesSuccess(data) {
  return { type: types.LOAD_ALL_COUNTRIES_SUCCESS, data };
}

export function loadAllCountriesFailure(error) {
  return { type: types.LOAD_ALL_COUNTRIES_FAILURE, error };
}

export function loadAllCitiesRequest() {
  return { type: types.LOAD_ALL_CITIES_REQUEST };
}

export function loadAllCitiesSuccess(data) {
  return { type: types.LOAD_ALL_CITIES_SUCCESS, data };
}

export function loadAllCitiesFailure(error) {
  return { type: types.LOAD_ALL_CITIES_FAILURE, error };
}

export function loadSearchCitiesRequest() {
  return { type: types.LOAD_SEARCH_CITIES_REQUEST };
}

export function loadSearchCitiesSuccess(data) {
  return { type: types.LOAD_SEARCH_CITIES_SUCCESS, data };
}

export function loadSearchCitiesFailure(error) {
  return { type: types.LOAD_SEARCH_CITIES_FAILURE, error };
}

export function getAllCountries() {
  return function (dispatch) {
    dispatch(loadAllCountriesRequest());
    return countryApi
      .getCountries()
      .then(res => {
        if (res.success) {
          dispatch(loadAllCountriesSuccess(res.countries));
        }
      })
      .catch(error => {
        dispatch(loadAllCountriesFailure(error));
      });
  };
}

export function getAllCities(data) {
  return function (dispatch) {
    dispatch(loadAllCitiesRequest());
    return countryApi
      .getCountryCity(data)
      .then(res => {
        if (res.success) {
          dispatch(loadAllCitiesSuccess(res.cities));
        }
      })
      .catch(error => {
        dispatch(loadAllCitiesFailure(error));
      });
  };
}

export function getSearchCities(data) {
  return function (dispatch) {
    dispatch(loadSearchCitiesRequest());
    return countryApi
      .getSearchCities(data)
      .then(res => {
        dispatch(loadSearchCitiesSuccess(res.data));
      })
      .catch(error => {
        dispatch(loadSearchCitiesFailure(error));
      });
  };
}
