import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function activeAdsReducer(
  state = initialState.activeAds,
  action
) {
  switch (action.type) {
    case types.LOAD_ACTIVE_ADS_SUCCESS:
      return action.data;
    case types.LOAD_ACTIVE_ADS_FAILURE:
      return [];

    default:
      return state;
  }
}
