import { IconButton } from '@material-ui/core';
import {
  Close,
  Delete,
  Publish,
  SentimentVeryDissatisfied
} from '@material-ui/icons';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { AdminAPI, CompanyAPI, GlobalAPI } from '../../api/v2';
import Button from '../../components/button/index';
import DatePicker from '../../components/datepicker/index';
import Input from '../../components/input';
import InputCard from '../../components/inputcard';
import RichText from '../../components/RichText';
import Select from '../../components/select';
import { Title } from '../../components/Title';
import localizedStrings from '../../utils/localizedStrings';
import Modal from '../../components/modal/index';
import { toast } from 'react-toastify';

const CreateAd = () => {
  const [values, setValues] = useState({
    title: '',
    user_id: '',
    short_description: '',
    country_id: '',
    city_id: '',
    type_of_work_id: '',
    job_type_id: '',
    work_time_id: '',
    education_level_id: '',
    description: '',
    start_date: null,
    end_date: null,
    ads_questions: []
  });

  const { common } = useSelector(state => state.global);

  const [companies, setCompanies] = useState([]);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [jobCategories, setJobCategories] = useState([]);
  const [jobTypes, setJobTypes] = useState([]);
  const [workTimes, setWorkTimes] = useState([]);
  const [educationLevels, setEducationLevels] = useState([]);;

  const [cancelModal, setCancelModal] = useState(false);
  const onCancelModalClose = () => setCancelModal(false);

  const [submitModal, setSubmitModal] = useState(false);
  const onSubmitModalClose = () => setSubmitModal(false);

  const history = useHistory();

  const goTo = x => {
    history.push(x);
  };

  const onCancel = () => {
    history.push('/active-ads');
  };

  const getCountries = async () => {
    const res = await GlobalAPI.countries();
    const formattedCountries = res.map(x => ({ value: x.id, label: x.name }));
    setCountries(formattedCountries);
  };

  const getCities = async country_id => {
    if (country_id) {
      setValues({ ...values, country_id });
      const res = await GlobalAPI.cities(country_id);
      const formattedCities = res.map(x => ({ value: x.id, label: x.name }));
      setCities(formattedCities);
    } else {
      setCities([]);
      setValues({ ...values, country_id: '', city_id: '' });
    }
  };

  const formatFromGlobal = () => {
    const formattedJobCategories = common.type_of_works.map(x => ({
      value: x.id,
      label: x.name
    }));
    setJobCategories(formattedJobCategories);

    const formattedJobTypes = common.job_types.map(x => ({
      value: x.id,
      label: x.name
    }));
    setJobTypes(formattedJobTypes);

    const formattedWorkTimes = common.work_times.map(x => ({
      value: x.id,
      label: x.name
    }));
    setWorkTimes(formattedWorkTimes);

    const formattedEducationLevels = common.education_levels.map(x => ({
      value: x.id,
      label: x.name
    }));
    setEducationLevels(formattedEducationLevels);
  };

  const getCompanies = async () => {
    const res = await CompanyAPI.companies();
    const formattedCompanies = res.map(x => ({
      value: x.id,
      label: x.full_name
    }));
    setCompanies(formattedCompanies);
  };

  useEffect(() => {
    getCountries();
    getCompanies();
    formatFromGlobal();
  }, []);

  const addQuestion = () => {
    setValues({ ...values, ads_questions: [...values.ads_questions, ''] });
  };

  const deleteQuestion = id => {
    setValues({
      ...values,
      ads_questions: values.ads_questions.filter((x, y) => y !== id)
    });
  };

  const onSubmit = async () => {
    console.log(values);
    if (
      [
        values.title,
        values.user_id,
        values.country_id,
        values.city_id,
        values.type_of_work_id,
        //values.job_type_id,
        values.work_time_id,
        values.education_level_id,
        values.start_date,
        values.end_date,
      ].filter(x => ['', null].includes(x)).length
    ) {
      return toast.warn('Popunite sva obavezna polja')
    }

    const { start_date, end_date, user_id, ads_questions, ...other } = values;
    var newValues = {
      ...other,
      start_date: start_date.format(),
      end_date: end_date.format()
    };

    if (ads_questions.length) {
      newValues.ads_questions = JSON.stringify(ads_questions);
    }
    try {
      await AdminAPI.createAd(newValues, user_id);
      toast.success('Oglas je uspešno postavljen');
    } catch (e) {
      toast.error('Došlo je do greške');
    }
  };

  return (
    <Fragment>
      <main id="mainContent" className="xl-resize">
        <Title title="Aktivni korisnici" />
        <header>
          <div className="title-box">
            <h1>Dodajte oglas</h1>
            <p>Kreirajte novi oglas</p>
          </div>
        </header>
        <div className="add-ad-container">
          <h2 className="title">{localizedStrings.add_ad.add_new_ad}</h2>
          <div className="add-ad-main">
            <div className="add-ad-half">
              <Input
                className="force-space-b"
                label="Naslov oglasa"
                required
                value={values.title}
                onChange={title => setValues({ ...values, title })}
              />
              <Select
                className="force-space-b"
                label="Naziv kompanije"
                required
                options={companies}
                value={values.user_id}
                onChange={user_id => setValues({ ...values, user_id })}
              />
              <Input
                style={{ marginBottom: 10 }}
                label="Kratak opis"
                value={values.short_description}
                inputProps={{ maxLength: 150 }}
                helperText={`Dozvoljeno karaktera : ${values.short_description.length}/150`}
                onChange={short_description =>
                  setValues({ ...values, short_description })
                }
              />
              <Select
                className="force-space-b"
                label="Država u kojoj zapošljavate"
                required
                options={countries}
                value={values.country_id}
                onChange={getCities}
              />
              <Select
                className="force-space-b"
                label="Grad u kojoj zapošljavate"
                required
                options={cities}
                value={values.city_id}
                onChange={city_id => setValues({ ...values, city_id })}
              />
              <Select
                className="force-space-b"
                label="Kategorija posla"
                required
                options={jobCategories}
                value={values.type_of_work_id}
                onChange={type_of_work_id => setValues({ ...values, type_of_work_id })}
              />
              <Select
                className="force-space-b"
                label="Tip posla"
                required
                options={jobTypes}
                value={values.job_type_id}
                onChange={job_type_id => setValues({ ...values, job_type_id })}
              />
              <Select
                className="force-space-b"
                label="Stepen stručne spreme"
                required
                options={educationLevels}
                value={values.education_level_id}
                onChange={education_level_id => setValues({ ...values, education_level_id })}
              />
              <Select
                className="force-space-b"
                label="Radno vreme"
                required
                options={workTimes}
                value={values.work_time_id}
                onChange={work_time_id =>
                  setValues({ ...values, work_time_id })
                }
              />
              {/* <DatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              label="Date picker inline"
              value={values.start_date}
              onChange={start_date => setValues({...values, start_date})}
            /> */}
              <DatePicker
                required
                className="force-space-b"
                label="Početak trajanja oglasa"
                value={values.start_date}
                onChange={start_date => setValues({ ...values, start_date })}
              />
              <DatePicker
                required
                minDate={values.start_date}
                label="Kraj trajanja oglasa"
                value={values.end_date}
                onChange={end_date => setValues({ ...values, end_date })}
              />
            </div>
            <div className="add-ad-half">
              <InputCard label="Opis oglasa" className="force-space-b">
                <RichText
                  value={values.description}
                  setValue={description =>
                    setValues({ ...values, description })
                  }
                />
              </InputCard>
              <div className="add-ad-questions-main">
                <h4>Postavite pitanja kandidatima</h4>
                <div className="add-ad-questions">
                  {values.ads_questions.map((x, y) => (
                    <div key={y} className="add-ad-question-single">
                      <Input
                        className="force-space-b"
                        label={`${y + 1}. Pitanje za kandidata`}
                        required
                        value={values.ads_questions[y]}
                        onChange={v =>
                          setValues({
                            ...values,
                            ads_questions: values.ads_questions.map((a, b) =>
                              b === y ? v : a
                            )
                          })
                        }
                        InputProps={{
                          endAdornment: (
                            <IconButton onClick={() => deleteQuestion(y)}>
                              <Delete />
                            </IconButton>
                          )
                        }}
                      />
                    </div>
                  ))}
                  {!values.ads_questions.length ? (
                    <div className="add-ads-no-questions">
                      <h1>Nema pitanja</h1>
                      <p>Dodajte pitanja klikom na dugme.</p>
                    </div>
                  ) : (
                    ''
                  )}
                  <Button onClick={addQuestion}>
                    {values.ads_questions.length
                      ? 'Dodaj novo pitanje'
                      : 'Dodaj pitanja'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="add-ad-actions">
            <Button
              className="button-inline"
              onClick={() => setSubmitModal(true)}
            >
              <Publish className="hide-on-mobile-sm" />
              Postavi oglas
            </Button>
            <Button
              grayscale
              className="button-inline"
              onClick={() => setCancelModal(true)}
            >
              <Close className="hide-on-mobile-sm" />
              Otkaži
            </Button>
          </div>
        </div>
      </main>
      <Modal
        title="Otkaži"
        open={cancelModal}
        onClose={onCancelModalClose}
        size="medium"
      >
        <CancelModal onCancel={onCancel} onClose={onCancelModalClose} />
      </Modal>
      <Modal
        title="Postavi oglas"
        open={submitModal}
        onClose={onSubmitModalClose}
        size="medium"
      >
        <SubmitModal onSubmit={onSubmit} onClose={onSubmitModalClose} />
      </Modal>
    </Fragment>
  );
};

const CancelModal = ({ onClose, onCancel }) => {
  const processCancel = () => {
    onClose();
    onCancel();
  };

  return (
    <Fragment>
      <p className="modal-center-text">
        Da li ste sigurni da želite da otkažete radnju?
      </p>
      <div className="d-flex justify-between">
        <Button className="force-space-r" onClick={processCancel}>
          Da
        </Button>
        <Button grayscale onClick={onClose}>
          Ne
        </Button>
      </div>
    </Fragment>
  );
};

const SubmitModal = ({ onClose, onSubmit }) => {
  const processSubmit = async () => {
    await onSubmit();
    onClose();
  };

  return (
    <Fragment>
      <p className="modal-center-text">
        Da li ste sigurni da ste završili sa izmenama i da želite da postavite
        oglas?
      </p>
      <div className="d-flex justify-between">
        <Button className="force-space-r" onClick={processSubmit}>
          Da
        </Button>
        <Button grayscale onClick={onClose}>
          Ne
        </Button>
      </div>
    </Fragment>
  );
};

export default CreateAd;
