import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function userReducer(state = initialState.user, action) {
  switch (action.type) {
    case types.LOAD_USER_SUCCESS:
      return action.user;
    case types.LOAD_USER_FAILURE:
      return {};
    case types.VIEW_SINGLE_MESSAGE_SUCCESS:
      return {
        ...state,
        messages: state.messages.map(message => {
          return message.id === action.id ? { ...message, seen: 1 } : message;
        })
      };
    case types.VIEW_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: state.notifications.map(notification => {
          return notification.id === action.id
            ? { ...notification, seen: 1 }
            : notification;
        })
      };
    case types.UPDATE_USER_PROFILE_IMAGE_SUCCESS:
      return { ...state, profile_image: action.data.profile_image };
    case types.UPDATE_USER_DATA_SUCCESS:
      return action.data;
    default:
      return state;
  }
}
