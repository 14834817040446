import React from 'react';

export const Logo = ({ className, width, height, onClick }) => (
  <svg
    viewBox="0 0 234 58"
    width={width}
    height={height}
    className={className}
    onClick={onClick}
  >
    <path
      d="M21.97 26.44C20.72 25.73 19.68 26.33 19.68 27.81V38.69C19.68 40.17 20.72 40.77 21.97 40.06L31.49 34.59C32.75 33.88 32.75 32.67 31.49 31.91L21.97 26.44Z"
      fill="url(#paint0_linear)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M90.01 45.15C89.8 44.93 89.69 44.6 89.69 44.27V11.4C89.69 11.02 89.8 10.74 90.01 10.47C90.23 10.25 90.51 10.14 90.89 10.14H104.45C108.5 10.14 111.73 11.12 114.03 13.04C116.32 14.95 117.53 17.74 117.53 21.41C117.53 25.07 116.38 27.81 114.03 29.67C111.73 31.53 108.5 32.46 104.45 32.46H97.02V44.27C97.02 44.65 96.91 44.93 96.69 45.15C96.47 45.36 96.14 45.47 95.76 45.47H90.89C90.56 45.47 90.29 45.36 90.01 45.15ZM108.67 25.4C109.71 24.47 110.2 23.1 110.2 21.35C110.2 19.6 109.71 18.24 108.72 17.25C107.74 16.27 106.21 15.77 104.18 15.77H96.91V26.77H104.18C106.15 26.77 107.63 26.33 108.67 25.4Z"
      fill="#353535"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M122.12 45.15C121.9 44.93 121.79 44.6 121.79 44.27V11.4C121.79 11.02 121.9 10.74 122.12 10.47C122.34 10.25 122.61 10.14 123 10.14H136.07C140.23 10.14 143.45 11.12 145.8 13.04C148.16 14.95 149.31 17.69 149.31 21.24C149.31 23.7 148.7 25.78 147.55 27.42C146.35 29.06 144.71 30.32 142.58 31.03L149.91 43.94C150.02 44.16 150.07 44.33 150.07 44.49C150.07 44.82 149.96 45.04 149.74 45.26C149.52 45.47 149.31 45.58 149.03 45.58H144.38C143.84 45.58 143.4 45.47 143.12 45.2C142.8 44.93 142.58 44.6 142.3 44.22L135.8 32.13H128.85V44.27C128.85 44.6 128.74 44.87 128.52 45.15C128.3 45.36 127.97 45.47 127.59 45.47H122.94C122.67 45.47 122.34 45.36 122.12 45.15ZM135.9 26.38C137.93 26.38 139.46 25.95 140.44 25.07C141.43 24.2 141.98 22.88 141.98 21.13C141.98 19.44 141.48 18.07 140.44 17.14C139.46 16.21 137.93 15.77 135.9 15.77H128.9V26.38H135.9Z"
      fill="#353535"
    />
    <path
      d="M156.14 45.15C155.92 44.93 155.81 44.6 155.81 44.27V11.34C155.81 10.96 155.92 10.69 156.14 10.47C156.36 10.25 156.69 10.14 157.02 10.14H161.89C162.27 10.14 162.54 10.25 162.76 10.47C162.98 10.69 163.09 10.96 163.09 11.34V44.27C163.09 44.6 162.98 44.87 162.76 45.15C162.54 45.36 162.27 45.47 161.89 45.47H157.02C156.69 45.47 156.42 45.36 156.14 45.15Z"
      fill="#353535"
    />
    <path
      d="M172.55 42.41C169.98 40.06 168.67 36.67 168.5 32.29C168.45 31.36 168.45 29.89 168.45 27.86C168.45 25.78 168.45 24.25 168.5 23.32C168.67 19 170.03 15.66 172.61 13.26C175.18 10.85 178.73 9.65 183.38 9.65C186.39 9.65 189.01 10.14 191.2 11.18C193.44 12.22 195.14 13.59 196.4 15.39C197.6 17.2 198.26 19.16 198.37 21.41V21.52C198.37 21.79 198.26 22.01 198.04 22.17C197.82 22.34 197.6 22.45 197.33 22.45H192.41C192.02 22.45 191.69 22.34 191.48 22.17C191.26 22.01 191.09 21.68 190.98 21.19C190.49 19.11 189.62 17.63 188.36 16.76C187.1 15.88 185.46 15.45 183.38 15.45C178.57 15.45 176.05 18.13 175.83 23.54C175.78 24.47 175.78 25.84 175.78 27.75C175.78 29.61 175.78 31.09 175.83 32.07C176.05 37.49 178.57 40.17 183.38 40.17C185.4 40.17 187.05 39.73 188.3 38.86C189.56 37.98 190.44 36.5 190.93 34.43C191.04 33.93 191.26 33.6 191.42 33.44C191.64 33.28 191.91 33.17 192.35 33.17H197.27C197.6 33.17 197.82 33.28 198.04 33.5C198.26 33.71 198.37 33.93 198.31 34.26C198.26 36.5 197.6 38.47 196.34 40.28C195.14 42.08 193.39 43.45 191.15 44.49C188.91 45.53 186.33 46.02 183.33 46.02C178.68 45.97 175.12 44.82 172.55 42.41ZM180.7 7.46C180.43 7.35 180.15 7.13 179.88 6.8L174.9 1.72C174.58 1.44 174.47 1.17 174.47 0.840003C174.47 0.400003 174.74 0.190002 175.23 0.190002H177.26C177.86 0.190002 178.4 0.300003 178.84 0.510003L183.16 3.19L187.48 0.510003C187.92 0.300003 188.47 0.190002 189.07 0.190002H191.09C191.59 0.190002 191.8 0.400003 191.8 0.840003C191.8 1.17 191.64 1.44 191.37 1.72L186.44 6.8C186.12 7.13 185.84 7.35 185.57 7.46C185.35 7.57 185.02 7.62 184.69 7.62H181.69C181.25 7.57 180.92 7.51 180.7 7.46Z"
      fill="#353535"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M199.74 45.15C199.52 44.93 199.41 44.71 199.41 44.38L199.52 43.94L211.66 11.34C211.99 10.52 212.59 10.09 213.46 10.09H219.04C219.92 10.09 220.52 10.52 220.85 11.34L232.99 43.94L233.1 44.38C233.1 44.71 232.99 44.93 232.77 45.15C232.55 45.36 232.33 45.47 232.06 45.47H227.58C226.86 45.47 226.37 45.15 226.1 44.49L223.8 38.36H208.87L206.57 44.49C206.3 45.15 205.81 45.47 205.1 45.47H200.61C200.17 45.47 199.9 45.36 199.74 45.15ZM210.51 32.57H221.94L216.25 17.03L210.51 32.57Z"
      fill="#353535"
    />
    <path
      d="M91.98 56.91C91.93 56.85 91.93 56.8 91.93 56.74V50.4H89.91C89.85 50.4 89.8 50.4 89.74 50.34C89.69 50.29 89.69 50.23 89.69 50.18V50.07C89.69 50.01 89.69 49.96 89.74 49.9C89.8 49.85 89.85 49.85 89.91 49.85H94.61C94.66 49.85 94.72 49.85 94.77 49.9C94.83 49.96 94.83 50.01 94.83 50.07V50.23C94.83 50.29 94.83 50.34 94.77 50.4C94.72 50.45 94.66 50.45 94.61 50.45H92.59V56.8C92.59 56.85 92.59 56.91 92.53 56.96C92.48 57.01 92.42 57.01 92.37 57.01H92.2C92.09 56.96 92.04 56.96 91.98 56.91Z"
      fill="#353535"
    />
    <path
      d="M98.88 56.74L96.52 50.12V50.01C96.52 49.96 96.52 49.9 96.58 49.85C96.63 49.79 96.69 49.79 96.74 49.79H96.96C97.02 49.79 97.07 49.79 97.13 49.85C97.18 49.9 97.18 49.9 97.18 49.96L99.42 56.19L101.67 49.96C101.67 49.9 101.72 49.9 101.72 49.85C101.77 49.79 101.83 49.79 101.88 49.79H102.1C102.16 49.79 102.21 49.79 102.27 49.85C102.32 49.9 102.32 49.96 102.32 50.01V50.12L99.97 56.74C99.91 56.91 99.81 56.96 99.64 56.96H99.37C99.09 56.96 98.98 56.91 98.88 56.74Z"
      fill="#353535"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M104.18 54.28C104.18 54.06 104.18 53.79 104.18 53.4C104.18 53.02 104.18 52.75 104.18 52.53C104.18 51.6 104.45 50.89 104.95 50.4C105.44 49.9 106.1 49.69 106.92 49.69C107.74 49.69 108.39 49.9 108.88 50.4C109.38 50.89 109.6 51.6 109.65 52.53C109.65 52.91 109.65 53.24 109.65 53.4C109.65 53.57 109.65 53.84 109.65 54.28C109.6 56.14 108.72 57.12 106.97 57.12C105.11 57.07 104.24 56.14 104.18 54.28ZM108.34 55.92C108.72 55.54 108.88 54.99 108.94 54.23C108.94 53.84 108.94 53.57 108.94 53.4C108.94 53.3 108.94 53.02 108.94 52.58C108.94 51.82 108.72 51.27 108.34 50.89C107.96 50.51 107.46 50.34 106.86 50.34C106.26 50.34 105.77 50.51 105.38 50.89C105 51.27 104.84 51.82 104.78 52.58C104.78 52.8 104.78 53.08 104.78 53.4C104.78 53.73 104.78 54.01 104.78 54.23C104.78 54.99 105 55.54 105.38 55.92C105.77 56.3 106.26 56.47 106.86 56.47C107.46 56.47 107.96 56.3 108.34 55.92Z"
      fill="#353535"
    />
    <path
      d="M112.44 56.52C112 56.19 111.73 55.65 111.73 54.94C111.73 54.88 111.73 54.83 111.78 54.77C111.84 54.72 111.89 54.72 111.95 54.72H112.17C112.22 54.72 112.28 54.72 112.33 54.77C112.39 54.83 112.39 54.88 112.39 54.94C112.44 55.43 112.6 55.81 112.93 56.03C113.26 56.25 113.7 56.41 114.19 56.41C114.79 56.41 115.23 56.19 115.56 55.81C115.89 55.43 116.05 54.94 116.05 54.28V50.34H112.28C112.22 50.34 112.17 50.34 112.11 50.29C112.06 50.23 112.06 50.18 112.06 50.12V50.07C112.06 50.01 112.06 49.96 112.11 49.9C112.17 49.85 112.22 49.85 112.28 49.85H116.43C116.49 49.85 116.54 49.85 116.6 49.9C116.65 49.96 116.65 50.01 116.65 50.07V54.39C116.65 55.21 116.43 55.87 116 56.36C115.56 56.85 114.96 57.12 114.19 57.12C113.48 57.07 112.93 56.91 112.44 56.52Z"
      fill="#353535"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M118.79 56.91C118.73 56.85 118.73 56.8 118.73 56.74V56.63L121.3 50.01C121.36 49.9 121.46 49.79 121.57 49.79H121.9C122.07 49.79 122.12 49.85 122.18 50.01L124.86 56.63V56.74C124.86 56.8 124.86 56.85 124.8 56.91C124.75 56.96 124.69 56.96 124.64 56.96H124.42C124.36 56.96 124.31 56.96 124.25 56.91C124.2 56.85 124.2 56.85 124.2 56.8L123.54 55.16H119.93L119.28 56.8C119.28 56.85 119.22 56.85 119.22 56.91C119.17 56.96 119.11 56.96 119.06 56.96H118.84C118.89 56.96 118.84 56.96 118.79 56.91ZM120.26 54.55H123.38L121.79 50.51L120.26 54.55Z"
      fill="#353535"
    />
    <path
      d="M132.02 56.74L129.67 50.12V50.01C129.67 49.96 129.67 49.9 129.72 49.85C129.78 49.79 129.83 49.79 129.89 49.79H130.11C130.16 49.79 130.22 49.79 130.27 49.85C130.33 49.9 130.33 49.9 130.33 49.96L132.57 56.19L134.81 49.96C134.81 49.9 134.87 49.9 134.87 49.85C134.92 49.79 134.97 49.79 135.03 49.79H135.25C135.3 49.79 135.36 49.79 135.41 49.85C135.47 49.9 135.47 49.96 135.47 50.01V50.12L133.12 56.74C133.06 56.91 132.95 56.96 132.79 56.96H132.51C132.19 56.96 132.08 56.91 132.02 56.74Z"
      fill="#353535"
    />
    <path
      d="M137.82 56.91C137.76 56.85 137.76 56.8 137.76 56.74V50.07C137.76 50.01 137.76 49.96 137.82 49.9C137.87 49.85 137.93 49.85 137.98 49.85H138.2C138.26 49.85 138.31 49.85 138.37 49.9C138.42 49.96 138.42 50.01 138.42 50.07V56.8C138.42 56.85 138.42 56.91 138.37 56.96C138.31 57.01 138.26 57.01 138.2 57.01H137.98C137.93 56.96 137.87 56.96 137.82 56.91Z"
      fill="#353535"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M141.43 56.91C141.37 56.85 141.37 56.8 141.37 56.74V50.07C141.37 50.01 141.37 49.96 141.43 49.9C141.48 49.85 141.54 49.85 141.59 49.85H143.78C144.49 49.85 145.04 49.96 145.48 50.18C145.91 50.4 146.19 50.67 146.35 51.11C146.52 51.49 146.63 52.04 146.63 52.64C146.63 52.86 146.63 53.13 146.63 53.46C146.63 53.79 146.63 54.06 146.63 54.28C146.63 54.94 146.52 55.48 146.35 55.87C146.19 56.25 145.91 56.58 145.48 56.74C145.04 56.91 144.55 57.01 143.84 57.01H141.59C141.48 56.96 141.43 56.96 141.43 56.91ZM143.78 56.36C144.33 56.36 144.77 56.3 145.09 56.14C145.42 55.98 145.64 55.76 145.75 55.43C145.91 55.1 145.97 54.66 145.97 54.12C145.97 53.9 145.97 53.68 145.97 53.35C145.97 53.08 145.97 52.8 145.97 52.58C145.97 51.82 145.75 51.27 145.42 50.89C145.09 50.56 144.49 50.34 143.73 50.34H142.03V56.25H143.78V56.36Z"
      fill="#353535"
    />
    <path
      d="M149.36 56.91C149.31 56.85 149.31 56.8 149.31 56.74V50.07C149.31 50.01 149.31 49.96 149.36 49.9C149.41 49.85 149.47 49.85 149.52 49.85H153.52C153.57 49.85 153.63 49.85 153.68 49.9C153.74 49.96 153.74 50.01 153.74 50.07V50.23C153.74 50.29 153.74 50.34 153.68 50.4C153.63 50.45 153.57 50.45 153.52 50.45H149.96V53.08H153.3C153.35 53.08 153.41 53.08 153.46 53.13C153.52 53.19 153.52 53.24 153.52 53.3V53.46C153.52 53.51 153.52 53.57 153.46 53.62C153.41 53.68 153.35 53.68 153.3 53.68H149.96V56.41H153.63C153.68 56.41 153.74 56.41 153.79 56.47C153.85 56.52 153.85 56.58 153.85 56.63V56.8C153.85 56.85 153.85 56.91 153.79 56.96C153.74 57.01 153.68 57.01 153.63 57.01H149.58C149.47 56.96 149.41 56.96 149.36 56.91Z"
      fill="#353535"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M156.03 54.28C156.03 54.06 156.03 53.79 156.03 53.4C156.03 53.02 156.03 52.75 156.03 52.53C156.03 51.6 156.31 50.89 156.8 50.4C157.29 49.9 157.95 49.69 158.77 49.69C159.59 49.69 160.24 49.9 160.74 50.4C161.23 50.89 161.45 51.6 161.5 52.53C161.5 52.91 161.5 53.24 161.5 53.4C161.5 53.57 161.5 53.84 161.5 54.28C161.45 56.14 160.57 57.12 158.82 57.12C157.07 57.12 156.09 56.14 156.03 54.28ZM160.19 55.92C160.57 55.54 160.74 54.99 160.79 54.23C160.79 53.84 160.79 53.57 160.79 53.4C160.79 53.3 160.79 53.02 160.79 52.58C160.74 51.82 160.57 51.27 160.19 50.89C159.81 50.51 159.31 50.34 158.71 50.34C158.11 50.34 157.62 50.51 157.24 50.89C156.85 51.27 156.69 51.82 156.63 52.58C156.63 52.8 156.63 53.08 156.63 53.4C156.63 53.73 156.63 54.01 156.63 54.23C156.69 54.99 156.85 55.54 157.24 55.92C157.62 56.3 158.11 56.47 158.71 56.47C159.31 56.47 159.81 56.3 160.19 55.92Z"
      fill="#353535"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M167.79 56.91C167.74 56.85 167.74 56.8 167.74 56.74V50.07C167.74 50.01 167.74 49.96 167.79 49.9C167.85 49.85 167.9 49.85 167.96 49.85H170.64C171.29 49.85 171.84 50.01 172.17 50.4C172.5 50.72 172.72 51.16 172.72 51.71C172.72 52.09 172.61 52.42 172.44 52.69C172.28 52.97 172.06 53.13 171.79 53.24C172.06 53.4 172.33 53.62 172.55 53.95C172.77 54.28 172.88 54.61 172.88 54.99C172.88 55.32 172.77 55.7 172.61 55.98C172.44 56.3 172.17 56.52 171.89 56.74C171.57 56.91 171.18 57.01 170.75 57.01H168.01C167.9 56.96 167.85 56.96 167.79 56.91ZM170.53 52.91C171.02 52.91 171.4 52.8 171.68 52.58C171.95 52.37 172.06 52.04 172.06 51.65C172.06 51.27 171.95 50.94 171.68 50.72C171.4 50.51 171.02 50.4 170.53 50.4H168.39V52.91H170.53ZM170.64 56.36C171.13 56.36 171.51 56.25 171.79 55.98C172.06 55.7 172.22 55.37 172.22 54.94C172.22 54.5 172.06 54.17 171.79 53.9C171.51 53.62 171.13 53.51 170.64 53.51H168.39V56.36H170.64Z"
      fill="#353535"
    />
    <path
      d="M175.51 56.91C175.45 56.85 175.45 56.8 175.45 56.74V50.07C175.45 50.01 175.45 49.96 175.51 49.9C175.56 49.85 175.61 49.85 175.67 49.85H175.89C175.94 49.85 176 49.85 176.05 49.9C176.11 49.96 176.11 50.01 176.11 50.07V56.8C176.11 56.85 176.11 56.91 176.05 56.96C176 57.01 175.94 57.01 175.89 57.01H175.67C175.61 56.96 175.56 56.96 175.51 56.91Z"
      fill="#353535"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M178.79 54.28C178.79 54.06 178.79 53.79 178.79 53.4C178.79 53.02 178.79 52.75 178.79 52.53C178.79 51.6 179.06 50.89 179.55 50.4C180.04 49.9 180.7 49.69 181.52 49.69C182.34 49.69 183 49.9 183.49 50.4C183.98 50.89 184.2 51.6 184.26 52.53C184.26 52.91 184.26 53.24 184.26 53.4C184.26 53.57 184.26 53.84 184.26 54.28C184.2 56.14 183.33 57.12 181.58 57.12C179.72 57.07 178.79 56.14 178.79 54.28ZM182.94 55.92C183.33 55.54 183.49 54.99 183.55 54.23C183.55 53.84 183.55 53.57 183.55 53.4C183.55 53.3 183.55 53.02 183.55 52.58C183.49 51.82 183.33 51.27 182.94 50.89C182.56 50.51 182.07 50.34 181.47 50.34C180.86 50.34 180.37 50.51 179.99 50.89C179.61 51.27 179.44 51.82 179.39 52.58C179.39 52.8 179.39 53.08 179.39 53.4C179.39 53.73 179.39 54.01 179.39 54.23C179.44 54.99 179.61 55.54 179.99 55.92C180.37 56.3 180.86 56.47 181.47 56.47C182.07 56.47 182.56 56.3 182.94 55.92Z"
      fill="#353535"
    />
    <path
      d="M187.32 56.36C186.83 55.87 186.61 55.21 186.55 54.39C186.55 54.17 186.55 53.84 186.55 53.4C186.55 52.97 186.55 52.64 186.55 52.42C186.55 51.6 186.83 50.94 187.32 50.45C187.81 49.96 188.47 49.74 189.29 49.74C189.89 49.74 190.38 49.85 190.76 50.07C191.15 50.29 191.48 50.56 191.64 50.83C191.86 51.11 191.91 51.38 191.97 51.6C191.97 51.65 191.97 51.71 191.91 51.71C191.86 51.71 191.8 51.76 191.75 51.76H191.53C191.48 51.76 191.42 51.76 191.42 51.71C191.37 51.71 191.37 51.65 191.37 51.55C191.26 51.16 191.04 50.89 190.71 50.62C190.38 50.4 189.94 50.23 189.4 50.23C188.74 50.23 188.25 50.4 187.92 50.78C187.54 51.11 187.37 51.65 187.32 52.42C187.32 52.64 187.32 52.91 187.32 53.35C187.32 53.79 187.32 54.06 187.32 54.28C187.37 54.99 187.54 55.54 187.92 55.92C188.3 56.25 188.8 56.47 189.4 56.47C190.05 56.47 190.55 56.3 190.93 55.92C191.31 55.54 191.53 55.05 191.53 54.33V53.79H189.73C189.67 53.79 189.62 53.79 189.56 53.73C189.51 53.68 189.51 53.62 189.51 53.57V53.4C189.51 53.35 189.51 53.3 189.56 53.24C189.62 53.19 189.67 53.19 189.73 53.19H191.91C191.97 53.19 192.02 53.19 192.08 53.24C192.13 53.3 192.13 53.35 192.13 53.4V54.28C192.13 54.83 192.02 55.32 191.8 55.7C191.59 56.14 191.26 56.47 190.87 56.69C190.44 56.91 189.94 57.01 189.4 57.01C188.41 57.07 187.76 56.85 187.32 56.36Z"
      fill="#353535"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M194.7 56.91C194.65 56.85 194.65 56.8 194.65 56.74V50.07C194.65 50.01 194.65 49.96 194.7 49.9C194.76 49.85 194.81 49.85 194.87 49.85H197.27C197.99 49.85 198.59 50.01 199.02 50.4C199.46 50.72 199.63 51.27 199.63 51.93C199.63 52.48 199.52 52.91 199.24 53.24C198.97 53.57 198.59 53.79 198.09 53.9L199.74 56.69C199.74 56.74 199.79 56.74 199.79 56.8C199.79 56.85 199.79 56.91 199.74 56.96C199.68 57.01 199.63 57.01 199.57 57.01H199.46C199.35 57.01 199.3 57.01 199.24 56.96C199.19 56.91 199.13 56.85 199.08 56.74L197.44 54.01H195.3V56.8C195.3 56.85 195.3 56.91 195.25 56.96C195.2 57.01 195.14 57.01 195.09 57.01H194.87C194.81 56.96 194.76 56.96 194.7 56.91ZM197.22 53.35C197.82 53.35 198.26 53.24 198.53 52.97C198.81 52.75 198.97 52.37 198.97 51.87C198.97 51.38 198.81 51.05 198.53 50.78C198.26 50.56 197.82 50.4 197.22 50.4H195.3V53.3H197.22V53.35Z"
      fill="#353535"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M201.54 56.91C201.49 56.85 201.49 56.8 201.49 56.74V56.63L204.06 50.01C204.11 49.9 204.22 49.79 204.33 49.79H204.66C204.82 49.79 204.88 49.85 204.93 50.01L207.5 56.63V56.74C207.5 56.8 207.5 56.85 207.45 56.91C207.39 56.96 207.34 56.96 207.28 56.96H207.06C207.01 56.96 206.95 56.96 206.9 56.91C206.85 56.85 206.85 56.85 206.85 56.8L206.19 55.16H202.58L201.92 56.8C201.92 56.85 201.87 56.85 201.87 56.91C201.81 56.96 201.76 56.96 201.7 56.96H201.49C201.65 56.96 201.59 56.96 201.54 56.91ZM203.02 54.55H206.13L204.55 50.51L203.02 54.55Z"
      fill="#353535"
    />
    <path
      d="M209.96 56.91C209.91 56.85 209.91 56.8 209.91 56.74V50.07C209.91 50.01 209.91 49.96 209.96 49.9C210.02 49.85 210.07 49.85 210.13 49.85H214.07C214.12 49.85 214.17 49.85 214.23 49.9C214.28 49.96 214.28 50.01 214.28 50.07V50.23C214.28 50.29 214.28 50.34 214.23 50.4C214.17 50.45 214.12 50.45 214.07 50.45H210.57V53.19H213.9C213.96 53.19 214.01 53.19 214.07 53.24C214.12 53.3 214.12 53.35 214.12 53.4V53.57C214.12 53.62 214.12 53.68 214.07 53.73C214.01 53.79 213.96 53.79 213.9 53.79H210.57V56.74C210.57 56.8 210.57 56.85 210.51 56.91C210.46 56.96 210.4 56.96 210.35 56.96H210.18C210.02 56.96 209.96 56.96 209.96 56.91Z"
      fill="#353535"
    />
    <path
      d="M216.8 56.91C216.75 56.85 216.75 56.8 216.75 56.74V50.07C216.75 50.01 216.75 49.96 216.8 49.9C216.85 49.85 216.91 49.85 216.96 49.85H217.18C217.24 49.85 217.29 49.85 217.35 49.9C217.4 49.96 217.4 50.01 217.4 50.07V56.8C217.4 56.85 217.4 56.91 217.35 56.96C217.29 57.01 217.24 57.01 217.18 57.01H216.96C216.91 56.96 216.85 56.96 216.8 56.91Z"
      fill="#353535"
    />
    <path
      d="M220.57 56.52C220.14 56.19 219.86 55.65 219.86 54.94C219.86 54.88 219.86 54.83 219.92 54.77C219.97 54.72 220.03 54.72 220.08 54.72H220.3C220.36 54.72 220.41 54.72 220.46 54.77C220.52 54.83 220.52 54.88 220.52 54.94C220.57 55.43 220.74 55.81 221.07 56.03C221.39 56.25 221.83 56.41 222.32 56.41C222.93 56.41 223.36 56.19 223.69 55.81C224.02 55.43 224.18 54.94 224.18 54.28V50.34H220.41C220.36 50.34 220.3 50.34 220.25 50.29C220.19 50.23 220.19 50.18 220.19 50.12V50.07C220.19 50.01 220.19 49.96 220.25 49.9C220.3 49.85 220.36 49.85 220.41 49.85H224.57C224.62 49.85 224.68 49.85 224.73 49.9C224.79 49.96 224.79 50.01 224.79 50.07V54.39C224.79 55.21 224.57 55.87 224.13 56.36C223.69 56.85 223.09 57.12 222.32 57.12C221.61 57.07 221.01 56.91 220.57 56.52Z"
      fill="#353535"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M226.86 56.91C226.81 56.85 226.81 56.8 226.81 56.74V56.63L229.38 50.01C229.43 49.9 229.54 49.79 229.65 49.79H229.98C230.15 49.79 230.2 49.85 230.26 50.01L232.83 56.63V56.74C232.83 56.8 232.83 56.85 232.77 56.91C232.72 56.96 232.66 56.96 232.61 56.96H232.39C232.33 56.96 232.28 56.96 232.22 56.91C232.17 56.85 232.17 56.85 232.17 56.8L231.51 55.16H227.9L227.25 56.8C227.25 56.85 227.19 56.85 227.19 56.91C227.14 56.96 227.08 56.96 227.03 56.96H226.81C226.97 56.96 226.92 56.96 226.86 56.91ZM228.34 54.55H231.46L229.87 50.51L228.34 54.55Z"
      fill="#353535"
    />
    <path
      d="M42.21 41.04L46.64 51.65C45.22 48.26 43.41 43.83 42.21 41.04Z"
      fill="black"
    />
    <path
      d="M42.92 10.3C43.2 9.76001 42.65 9.16001 42.05 9.32001L34.61 12.11C34.5 12.16 34.33 12.16 34.23 12.11C33.57 11.78 32.91 11.51 32.26 11.29C32.26 11.29 32.2 11.29 32.2 11.23C31.05 10.8 29.85 10.52 28.65 10.3C27.66 10.14 26.68 10.03 25.64 9.92001C24.98 9.87001 24.33 9.87001 23.67 9.87001C19.46 9.81001 15.46 11.4 13.88 12.11C10.65 13.64 7.81001 15.83 5.56001 18.62C2.83001 22.01 1.19001 25.84 0.640009 30.05C0.310009 32.35 0.370008 34.7 0.750008 37C1.02001 38.8 1.52001 40.5 2.23001 42.14C3.65001 45.53 5.73001 48.48 8.57001 50.83C10.65 52.58 12.95 54.01 15.46 54.94C15.46 54.94 15.46 54.94 15.52 54.94C16.78 55.48 17.71 55.7 18.25 55.81C20.11 56.36 25.75 57.56 32.26 54.83C32.42 54.77 32.58 54.66 32.8 54.61C33.19 54.44 33.57 54.23 34.01 54.06C34.23 53.95 34.44 53.95 34.66 54.01L42.1 56.8C42.7 56.96 43.25 56.36 42.98 55.81L36.25 42.41L35.98 41.81C35.7 41.26 35.05 41.15 34.55 41.54C33.79 42.08 30.83 44.05 26.95 45.15C26.08 45.36 25.26 45.58 24.38 45.64C22.57 45.8 20.82 45.42 19.18 44.71C17.16 43.89 15.41 42.63 14.1 40.82C12.07 38.2 11.2 35.25 11.53 31.96C11.53 31.91 11.53 31.8 11.53 31.74C11.58 31.2 11.69 30.71 11.8 30.27C11.85 29.99 11.96 29.78 12.02 29.5C12.07 29.39 12.07 29.34 12.13 29.23C12.24 28.9 12.35 28.63 12.46 28.3C14.32 24.36 17.38 21.95 21.59 21.19C25.2 20.53 28.43 21.52 31.33 23.76C32.58 24.74 33.62 25.95 34.5 27.1C34.77 27.48 35.32 27.42 35.54 26.99L42.92 10.3Z"
      fill="url(#paint1_linear)"
    />
    <path
      d="M79.08 9.37H68.68C68.3 9.37 67.97 9.58999 67.81 9.91999C66.5 12.77 57.14 35.63 54.95 40.72C54.79 41.1 54.24 41.1 54.08 40.72C52.66 37.65 44.89 21.9 44.4 21.08C44.18 20.75 43.63 20.75 43.47 21.13L38.22 32.67C38.05 33 38.05 33.39 38.22 33.77C39.75 37.05 48.28 55.81 48.5 56.36C48.66 56.74 48.99 56.96 49.38 56.96H59.28C59.66 56.96 59.99 56.74 60.15 56.41C60.75 55.05 79.9 10.74 79.9 10.74C80.28 10.09 79.79 9.37 79.08 9.37Z"
      fill="url(#paint2_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="26.04"
        y1="26.141"
        x2="26.04"
        y2="40.34"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F6D55C" />
        <stop offset="1" stopColor="#ED553B" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="0.441008"
        y1="33.157"
        x2="78.749"
        y2="33.157"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.056" stopColor="#03254C" />
        <stop offset="0.203" stopColor="#114D8A" />
        <stop offset="0.413" stopColor="#2383DE" />
        <stop offset="0.508" stopColor="#2A99FF" />
        <stop offset="0.562" stopColor="#278FF0" />
        <stop offset="0.666" stopColor="#1E75C8" />
        <stop offset="0.807" stopColor="#104C88" />
        <stop offset="0.927" stopColor="#03254C" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="-0.595004"
        y1="33.181"
        x2="80.066"
        y2="33.181"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.056" stopColor="#03254C" />
        <stop offset="0.203" stopColor="#114D8A" />
        <stop offset="0.413" stopColor="#2383DE" />
        <stop offset="0.508" stopColor="#2A99FF" />
        <stop offset="0.562" stopColor="#278FF0" />
        <stop offset="0.666" stopColor="#1E75C8" />
        <stop offset="0.807" stopColor="#104C88" />
        <stop offset="0.927" stopColor="#03254C" />
      </linearGradient>
    </defs>
  </svg>
);

export const Gradient = () => (
  <svg className="svg-defs">
    <defs>
      <linearGradient id="grayGradient" gradientTransform="rotate(90)">
        <stop offset="0%" stopColor="#6b6b6b" />
        <stop offset="100%" stopColor="#353535" />
      </linearGradient>
      <linearGradient id="blueGradient" gradientTransform="rotate(90)">
        <stop offset="0%" stopColor="#2897fb" />
        <stop offset="100%" stopColor="#04274f" />
      </linearGradient>
      <linearGradient id="redGradient" gradientTransform="rotate(90)">
        <stop offset="0%" stopColor="#fd9292" />
        <stop offset="100%" stopColor="#c52424" />
      </linearGradient>
      <linearGradient id="yellowGradient" gradientTransform="rotate(90)">
        <stop offset="0%" stopColor="#ffa837" />
        <stop offset="100%" stopColor="#ee5124" />
      </linearGradient>
    </defs>
  </svg>
);

export const Masks = () => {
  <svg className="svg-defs">
    <defs>
      <mask id="favouriteMask">
        <path
          d="M20.35 2.23001C17.94 0.170006 14.35 0.540006 12.14 2.82001L11.27 3.72001L10.4 2.82001C8.19 0.540006 4.6 0.170006 2.19 2.23001C-0.580003 4.59001 -0.720003 8.82001 1.75 11.38L10.27 20.17C10.82 20.74 11.71 20.74 12.26 20.17L20.78 11.38C23.26 8.82001 23.11 4.59001 20.35 2.23001Z"
          fill="white"
        />
      </mask>
      <mask id="sendMask">
        <path
          d="M19.5247 0.391078L0.512982 12.4041C-0.230516 12.8686 -0.133903 14 0.605394 14.3403L4.96557 16.3455L16.7481 4.97183C16.975 4.75107 17.2942 5.09141 17.101 5.34436L7.22127 18.5301V22.1451C7.22127 23.2029 8.38902 23.6214 8.9645 22.8534L11.5688 19.381L16.6809 21.7266C17.2606 21.9979 17.9285 21.5978 18.0335 20.9079L20.9865 1.50407C21.1251 0.598041 20.2346 -0.0550405 19.5247 0.391078Z"
          fill="white"
        />
      </mask>
    </defs>
  </svg>;
};

export const BellIcon = ({ className, id, width, height, onClick }) => (
  <svg
    viewBox="0 0 20 23"
    width={width}
    height={height}
    className={className}
    id={id}
    onClick={onClick}
  >
    <path
      fill="currentColor"
      d="M9.62 22.75C11.14 22.75 12.37 21.52 12.37 20H6.88C6.88 21.52 8.11 22.75 9.62 22.75ZM18.88 16.32C18.05 15.42 16.5 14.08 16.5 9.69C16.5 6.35 14.16 3.68 11 3.02V2.12C11 1.37 10.38 0.75 9.62 0.75C8.87 0.75 8.25 1.37 8.25 2.12V3.02C5.09 3.68 2.75 6.35 2.75 9.69C2.75 14.08 1.2 15.42 0.37 16.32C0.11 16.59 0 16.93 0 17.25C0 17.95 0.56 18.62 1.38 18.62H17.87C18.69 18.62 19.25 17.95 19.25 17.25C19.25 16.93 19.14 16.59 18.88 16.32Z"
    />
  </svg>
);

export const DashboardIcon = ({ className, id, width, height, onClick }) => (
  <svg
    viewBox="0 0 512 512"
    width={width}
    height={height}
    className={className}
    id={id}
    onClick={onClick}
  >
    <path
      fill="currentColor"
      d="M149.333 216v80c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24v-80c0-13.255 10.745-24 24-24h101.333c13.255 0 24 10.745 24 24zM0 376v80c0 13.255 10.745 24 24 24h101.333c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H24c-13.255 0-24 10.745-24 24zM125.333 32H24C10.745 32 0 42.745 0 56v80c0 13.255 10.745 24 24 24h101.333c13.255 0 24-10.745 24-24V56c0-13.255-10.745-24-24-24zm80 448H488c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H205.333c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24zm-24-424v80c0 13.255 10.745 24 24 24H488c13.255 0 24-10.745 24-24V56c0-13.255-10.745-24-24-24H205.333c-13.255 0-24 10.745-24 24zm24 264H488c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H205.333c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24z"
    ></path>
  </svg>
);

export const ProfileIcon = ({ className, id, width, height, onClick }) => (
  <svg
    viewBox="0 0 49 56"
    width={width}
    height={height}
    className={className}
    id={id}
    onClick={onClick}
  >
    <path
      d="M24.06 28.37C31.66 28.37 37.81 22.22 37.81 14.62C37.81 7.02999 31.66 0.869995 24.06 0.869995C16.47 0.869995 10.31 7.02999 10.31 14.62C10.31 22.22 16.47 28.37 24.06 28.37ZM34.35 31.88L29.22 52.44L25.78 37.83L29.22 31.81H18.91L22.34 37.83L18.91 52.44L13.77 31.88C6.11 32.24 0 38.5 0 46.25V50.72C0 53.56 2.31 55.87 5.16 55.87H42.97C45.81 55.87 48.12 53.56 48.12 50.72V46.25C48.12 38.5 42.01 32.24 34.35 31.88Z"
      fill="currentColor"
    />
  </svg>
);

export const MegaphoneIcon = ({ className, id, width, height, onClick }) => (
  <svg
    viewBox="0 0 57 51"
    width={width}
    height={height}
    className={className}
    id={id}
    onClick={onClick}
  >
    <path
      d="M53.12 29.07V44C53.12 44.9 52.43 47.12 50 47.12C49.39 47.12 48.52 46.82 48.05 46.44L39.74 39.8C35.57 36.46 30.34 34.62 25 34.62H22.26C21.98 35.62 21.83 36.66 21.83 37.75C21.83 40.59 22.78 43.21 24.38 45.31C25.92 47.34 24.39 50.25 21.85 50.25H14.6C13.44 50.25 12.33 49.63 11.82 48.58C10.23 45.3 9.33 41.63 9.33 37.75C9.33 36.69 9.4 35.65 9.54 34.62H6.25C2.8 34.62 0 31.83 0 28.37V19C0 15.55 2.8 12.75 6.25 12.75H25C30.34 12.75 35.57 10.91 39.74 7.58L48.05 0.94C48.61 0.48 49.3 0.25 50 0.25C52.44 0.25 53.12 2.52 53.12 3.38V18.3C54.99 19.39 56.25 21.38 56.25 23.69C56.25 25.99 54.99 27.99 53.12 29.07ZM46.87 9.88L43.65 12.46C38.37 16.68 31.75 19 25 19V28.37C31.75 28.37 38.37 30.7 43.65 34.92L46.87 37.5V9.88Z"
      fill="currentColor"
    />
  </svg>
);

export const PaperPlaneIcon = ({
  className,
  id,
  width,
  height,
  onClick,
  fill
}) => (
  <svg
    viewBox="0 0 50 51"
    width={width}
    height={height}
    className={className}
    id={id}
    onClick={onClick}
  >
    <path
      d="M46.48 0.560008L1.22 26.68C-0.55 27.69 -0.32 30.15 1.44 30.89L11.82 35.25L39.87 10.52C40.41 10.04 41.17 10.78 40.71 11.33L17.19 40V47.86C17.19 50.16 19.97 51.07 21.34 49.4L27.54 41.85L39.71 46.95C41.09 47.54 42.68 46.67 42.93 45.17L49.96 2.98001C50.29 1.01001 48.17 -0.409992 46.48 0.560008Z"
      fill={fill || 'currentColor'}
    />
  </svg>
);

export const SettingsIcon = ({ className, id, width, height, onClick }) => (
  <svg
    viewBox="0 0 512 512"
    width={width}
    height={height}
    className={className}
    id={id}
    onClick={onClick}
  >
    <path
      fill="currentColor"
      d="M487.4 315.7l-42.6-24.6c4.3-23.2 4.3-47 0-70.2l42.6-24.6c4.9-2.8 7.1-8.6 5.5-14-11.1-35.6-30-67.8-54.7-94.6-3.8-4.1-10-5.1-14.8-2.3L380.8 110c-17.9-15.4-38.5-27.3-60.8-35.1V25.8c0-5.6-3.9-10.5-9.4-11.7-36.7-8.2-74.3-7.8-109.2 0-5.5 1.2-9.4 6.1-9.4 11.7V75c-22.2 7.9-42.8 19.8-60.8 35.1L88.7 85.5c-4.9-2.8-11-1.9-14.8 2.3-24.7 26.7-43.6 58.9-54.7 94.6-1.7 5.4.6 11.2 5.5 14L67.3 221c-4.3 23.2-4.3 47 0 70.2l-42.6 24.6c-4.9 2.8-7.1 8.6-5.5 14 11.1 35.6 30 67.8 54.7 94.6 3.8 4.1 10 5.1 14.8 2.3l42.6-24.6c17.9 15.4 38.5 27.3 60.8 35.1v49.2c0 5.6 3.9 10.5 9.4 11.7 36.7 8.2 74.3 7.8 109.2 0 5.5-1.2 9.4-6.1 9.4-11.7v-49.2c22.2-7.9 42.8-19.8 60.8-35.1l42.6 24.6c4.9 2.8 11 1.9 14.8-2.3 24.7-26.7 43.6-58.9 54.7-94.6 1.5-5.5-.7-11.3-5.6-14.1zM256 336c-44.1 0-80-35.9-80-80s35.9-80 80-80 80 35.9 80 80-35.9 80-80 80z"
    ></path>
  </svg>
);

export const LogoutIcon = ({ className, id, width, height, onClick }) => (
  <svg
    viewBox="0 0 22 17"
    width={width}
    height={height}
    className={className}
    id={id}
    onClick={onClick}
  >
    <path
      d="M21.36 9.48C21.75 9.08 21.75 8.42 21.36 8.02L14.14 0.8C13.49 0.16 12.37 0.61 12.37 1.53V5.66H6.53C5.96 5.66 5.5 6.12 5.5 6.69V10.81C5.5 11.38 5.96 11.84 6.53 11.84H12.37V15.97C12.37 16.89 13.49 17.34 14.14 16.7L21.36 9.48ZM8.25 16.48V14.77C8.25 14.48 8.02 14.25 7.73 14.25H4.12C3.36 14.25 2.75 13.64 2.75 12.87V4.62C2.75 3.86 3.36 3.25 4.12 3.25H7.73C8.02 3.25 8.25 3.02 8.25 2.73V1.02C8.25 0.73 8.02 0.5 7.73 0.5H4.12C1.85 0.5 0 2.35 0 4.62V12.87C0 15.15 1.85 17 4.12 17H7.73C8.02 17 8.25 16.77 8.25 16.48Z"
      fill="currentColor"
    />
  </svg>
);

export const MessageIcon = ({ className, id, width, height, onClick }) => (
  <svg
    viewBox="0 0 22 17"
    width={width}
    height={height}
    className={className}
    id={id}
    onClick={onClick}
  >
    <path
      d="M21.58 5.95C20.62 6.7 19.35 7.65 14.97 10.83C14.09 11.47 12.53 12.89 11 12.88C9.47 12.89 7.94 11.5 7.04 10.83C2.66 7.65 1.38 6.7 0.42 5.95C0.24 5.82 0 5.94 0 6.15V14.94C0 16.08 0.92 17 2.06 17H19.94C21.08 17 22 16.08 22 14.94V6.15C22 5.94 21.75 5.82 21.58 5.95ZM11 11.5C12 11.52 13.43 10.25 14.15 9.72C19.86 5.58 20.29 5.22 21.6 4.19C21.85 4 22 3.7 22 3.38V2.56C22 1.42 21.08 0.5 19.94 0.5H2.06C0.92 0.5 0 1.42 0 2.56V3.38C0 3.7 0.15 3.99 0.39 4.19C1.71 5.22 2.14 5.58 7.85 9.72C8.57 10.25 10 11.52 11 11.5Z"
      fill="currentColor"
    />
  </svg>
);

export const PlusIcon = ({ className, id, width, height, onClick }) => (
  <svg
    viewBox="0 0 512 512"
    width={width}
    height={height}
    className={className}
    id={id}
    onClick={onClick}
  >
    <path
      fill="currentColor"
      d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm144 276c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92h-92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z"
    ></path>
  </svg>
);

export const FiltersIcon = ({ className, id, width, height, onClick }) => (
  <svg
    viewBox="0 0 24 22"
    width={width}
    height={height}
    className={className}
    id={id}
    onClick={onClick}
  >
    <path
      d="M23.25 17H7.5V16.25C7.5 15.84 7.16 15.5 6.75 15.5H5.25C4.84 15.5 4.5 15.84 4.5 16.25V17H0.75C0.34 17 0 17.34 0 17.75V19.25C0 19.66 0.34 20 0.75 20H4.5V20.75C4.5 21.16 4.84 21.5 5.25 21.5H6.75C7.16 21.5 7.5 21.16 7.5 20.75V20H23.25C23.66 20 24 19.66 24 19.25V17.75C24 17.34 23.66 17 23.25 17ZM23.25 9.5H19.5V8.75C19.5 8.34 19.16 8 18.75 8H17.25C16.84 8 16.5 8.34 16.5 8.75V9.5H0.75C0.34 9.5 0 9.84 0 10.25V11.75C0 12.16 0.34 12.5 0.75 12.5H16.5V13.25C16.5 13.66 16.84 14 17.25 14H18.75C19.16 14 19.5 13.66 19.5 13.25V12.5H23.25C23.66 12.5 24 12.16 24 11.75V10.25C24 9.84 23.66 9.5 23.25 9.5ZM23.25 2H13.5V1.25C13.5 0.84 13.16 0.5 12.75 0.5H11.25C10.84 0.5 10.5 0.84 10.5 1.25V2H0.75C0.34 2 0 2.34 0 2.75V4.25C0 4.66 0.34 5 0.75 5H10.5V5.75C10.5 6.16 10.84 6.5 11.25 6.5H12.75C13.16 6.5 13.5 6.16 13.5 5.75V5H23.25C23.66 5 24 4.66 24 4.25V2.75C24 2.34 23.66 2 23.25 2Z"
      fill="currentColor"
    />
  </svg>
);

export const PlayIcon = ({ className, id, width, height, onClick, fill }) => (
  <svg
    viewBox="0 0 60 69"
    width={width}
    height={height}
    className={className}
    id={id}
    onClick={onClick}
  >
    <path
      d="M56.84 28.82L9.7 0.949986C5.87 -1.31001 0 0.879986 0 6.47999V62.21C0 67.23 5.45 70.26 9.7 67.74L56.84 39.88C61.05 37.41 61.06 31.3 56.84 28.82Z"
      fill={fill || 'url(#yellowGradient)'}
    ></path>
  </svg>
);

export const DropdownIcon = ({ className, id, width, height, onClick }) => (
  <svg
    viewBox="0 0 288 168"
    width={width}
    height={height}
    className={className}
    id={id}
    onClick={onClick}
  >
    <path
      d="M25 0H263C284.4 0 295.1 25.9 280 41L161 160C151.6 169.4 136.4 169.4 127.1 160L8.00001 41C-7.09999 25.9 3.60001 0 25 0Z"
      fill="currentColor"
    />
  </svg>
);

export const LeftIcon = ({ className, id, width, height, onClick }) => (
  <svg
    viewBox="0 0 448 512"
    width={width}
    height={height}
    className={className}
    id={id}
    onClick={onClick}
  >
    <path
      fill="currentColor"
      d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"
    />
  </svg>
);

export const RightIcon = ({ className, id, width, height, onClick }) => (
  <svg
    viewBox="0 0 448 512"
    width={width}
    height={height}
    className={className}
    id={id}
    onClick={onClick}
  >
    <path
      fill="currentColor"
      d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"
    />
  </svg>
);

export const CircleIcon = ({ className, id, width, height, onClick }) => (
  <svg
    viewBox="0 0 49 56"
    width={width}
    height={height}
    className={className}
    id={id}
    onClick={onClick}
  >
    <path
      d="M24.06 28.37C31.66 28.37 37.81 22.22 37.81 14.62C37.81 7.02999 31.66 0.869995 24.06 0.869995C16.47 0.869995 10.31 7.02999 10.31 14.62C10.31 22.22 16.47 28.37 24.06 28.37ZM34.35 31.88L29.22 52.44L25.78 37.83L29.22 31.81H18.91L22.34 37.83L18.91 52.44L13.77 31.88C6.11 32.24 0 38.5 0 46.25V50.72C0 53.56 2.31 55.87 5.16 55.87H42.97C45.81 55.87 48.12 53.56 48.12 50.72V46.25C48.12 38.5 42.01 32.24 34.35 31.88Z"
      fill="url('#blueGradient')"
    />
  </svg>
);

export const EyeIcon = ({ className, id, width, height, onClick, color }) => (
  <svg
    viewBox="0 0 32 22"
    width={width}
    height={height}
    className={className}
    id={id}
    onClick={onClick}
  >
    <path
      d="M30.96 10.45C31.21 10.94 31.21 11.52 30.96 12.01C28.06 17.67 22.32 21.5 15.74 21.5C9.16 21.5 3.42 17.67 0.52 12.01C0.27 11.52 0.27 10.94 0.52 10.45C3.42 4.79 9.16 0.949997 15.74 0.949997C22.32 0.949997 28.06 4.79 30.96 10.45ZM23.45 11.23C23.45 6.97 20 3.52 15.74 3.52C11.49 3.52 8.04 6.97 8.04 11.23C8.04 15.48 11.49 18.93 15.74 18.93C20 18.93 23.45 15.48 23.45 11.23ZM15.74 6.09C17.94 6.09 19.9 7.48 20.6 9.56C21.31 11.64 20.61 13.95 18.87 15.28C17.13 16.62 14.72 16.7 12.9 15.47C11.07 14.25 10.23 11.99 10.81 9.87C11.83 10.62 13.24 10.52 14.13 9.62C15.03 8.73 15.14 7.31 14.39 6.29C14.83 6.17 15.28 6.1 15.74 6.09Z"
      fill={color || 'currentColor'}
    />
  </svg>
);

export const PaperIcon = ({ className, id, width, height, onClick }) => (
  <svg
    viewBox="0 0 16 20"
    width={width}
    height={height}
    className={className}
    id={id}
    onClick={onClick}
  >
    <path d="M10.19 6.25H15.5V19.06C15.5 19.58 15.08 20 14.56 20H1.44C0.92 20 0.5 19.58 0.5 19.06V0.94C0.5 0.42 0.92 0 1.44 0H9.25V5.31C9.25 5.83 9.67 6.25 10.19 6.25ZM11.75 14.22C11.75 13.96 11.54 13.75 11.28 13.75H4.72C4.46 13.75 4.25 13.96 4.25 14.22V14.53C4.25 14.79 4.46 15 4.72 15H11.28C11.54 15 11.75 14.79 11.75 14.53V14.22ZM11.75 11.72C11.75 11.46 11.54 11.25 11.28 11.25H4.72C4.46 11.25 4.25 11.46 4.25 11.72V12.03C4.25 12.29 4.46 12.5 4.72 12.5H11.28C11.54 12.5 11.75 12.29 11.75 12.03V11.72ZM11.28 8.75H4.72C4.46 8.75 4.25 8.96 4.25 9.22V9.53C4.25 9.79 4.46 10 4.72 10H11.28C11.54 10 11.75 9.79 11.75 9.53V9.22C11.75 8.96 11.54 8.75 11.28 8.75ZM15.5 4.76V5H10.5V0H10.74C10.99 0 11.23 0.1 11.4 0.27L15.23 4.1C15.4 4.28 15.5 4.52 15.5 4.76Z" />
  </svg>
);

export const XIcon = ({ className, id, width, height, onClick }) => (
  <svg
    viewBox="0 0 512 512"
    width={width}
    height={height}
    className={className}
    id={id}
    onClick={onClick}
  >
    <path
      fill="currentColor"
      d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
    ></path>
  </svg>
);

export const PenIcon = ({ className, id, width, height, onClick }) => (
  <svg
    viewBox="0 0 512 512"
    width={width}
    height={height}
    className={className}
    id={id}
    onClick={onClick}
  >
    <path
      fill="currentColor"
      d="M497.9 142.1l-46.1 46.1c-4.7 4.7-12.3 4.7-17 0l-111-111c-4.7-4.7-4.7-12.3 0-17l46.1-46.1c18.7-18.7 49.1-18.7 67.9 0l60.1 60.1c18.8 18.7 18.8 49.1 0 67.9zM284.2 99.8L21.6 362.4.4 483.9c-2.9 16.4 11.4 30.6 27.8 27.8l121.5-21.3 262.6-262.6c4.7-4.7 4.7-12.3 0-17l-111-111c-4.8-4.7-12.4-4.7-17.1 0zM124.1 339.9c-5.5-5.5-5.5-14.3 0-19.8l154-154c5.5-5.5 14.3-5.5 19.8 0s5.5 14.3 0 19.8l-154 154c-5.5 5.5-14.3 5.5-19.8 0zM88 424h48v36.3l-64.5 11.3-31.1-31.1L51.7 376H88v48z"
    ></path>
  </svg>
);

export const TrashIcon = ({ className, id, width, height, onClick }) => (
  <svg
    viewBox="0 0 21 24"
    width={width}
    height={height}
    className={className}
    id={id}
    onClick={onClick}
  >
    <path
      d="M1.5 6H19.5V21.75C19.5 22.99 18.49 24 17.25 24H3.75C2.51 24 1.5 22.99 1.5 21.75V6ZM14.25 20.25C14.25 20.66 14.59 21 15 21C15.41 21 15.75 20.66 15.75 20.25V9.75C15.75 9.34 15.41 9 15 9C14.59 9 14.25 9.34 14.25 9.75V20.25ZM9.75 20.25C9.75 20.66 10.09 21 10.5 21C10.91 21 11.25 20.66 11.25 20.25V9.75C11.25 9.34 10.91 9 10.5 9C10.09 9 9.75 9.34 9.75 9.75V20.25ZM5.25 20.25C5.25 20.66 5.59 21 6 21C6.41 21 6.75 20.66 6.75 20.25V9.75C6.75 9.34 6.41 9 6 9C5.59 9 5.25 9.34 5.25 9.75V20.25ZM21 2.25V3.75C21 4.16 20.66 4.5 20.25 4.5H0.75C0.34 4.5 0 4.16 0 3.75V2.25C0 1.84 0.34 1.5 0.75 1.5H6.37L6.82 0.62C6.98 0.28 7.43 0 7.81 0H13.18C13.56 0 14.01 0.28 14.18 0.62L14.62 1.5H20.25C20.66 1.5 21 1.84 21 2.25Z"
      fill="currentColor"
    />
  </svg>
);

export const EditIcon = ({ className, id, width, height, onClick }) => (
  <svg
    viewBox="0 0 576 512"
    width={width}
    height={height}
    className={className}
    id={id}
    onClick={onClick}
  >
    <path
      fill="currentColor"
      d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z"
    ></path>
  </svg>
);

export const X2Icon = ({ className, id, width, height, onClick }) => (
  <svg
    viewBox="0 0 352 512"
    width={width}
    height={height}
    className={className}
    id={id}
    onClick={onClick}
  >
    <path
      fill="currentColor"
      d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
    />
  </svg>
);

export const SaveIcon = ({ className, id, width, height, onClick }) => (
  <svg
    viewBox="0 0 448 512"
    width={width}
    height={height}
    className={className}
    id={id}
    onClick={onClick}
  >
    <path
      fill="currentColor"
      d="M433.941 129.941l-83.882-83.882A48 48 0 0 0 316.118 32H48C21.49 32 0 53.49 0 80v352c0 26.51 21.49 48 48 48h352c26.51 0 48-21.49 48-48V163.882a48 48 0 0 0-14.059-33.941zM224 416c-35.346 0-64-28.654-64-64 0-35.346 28.654-64 64-64s64 28.654 64 64c0 35.346-28.654 64-64 64zm96-304.52V212c0 6.627-5.373 12-12 12H76c-6.627 0-12-5.373-12-12V108c0-6.627 5.373-12 12-12h228.52c3.183 0 6.235 1.264 8.485 3.515l3.48 3.48A11.996 11.996 0 0 1 320 111.48z"
    ></path>
  </svg>
);

export const RecordIcon = ({ className, id, width, height, onClick, fill }) => (
  <svg
    viewBox="0 0 512 512"
    width={width}
    height={height}
    className={className}
    id={id}
    onClick={onClick}
  >
    <path
      fill={fill || 'url(#yellowGradient)'}
      d="M256 56c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m0-48C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 168c-44.183 0-80 35.817-80 80s35.817 80 80 80 80-35.817 80-80-35.817-80-80-80z"
    ></path>
  </svg>
);

export const DropdownIcon2 = ({ className, id, width, height, onClick }) => (
  <svg
    viewBox="0 0 448 512"
    width={width}
    height={height}
    className={className}
    id={id}
    onClick={onClick}
  >
    <path
      fill="currentColor"
      d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"
    />
  </svg>
);

export const RemoveIcon = ({ className, id, width, height, onClick }) => (
  <svg
    viewBox="0 0 512 512"
    width={width}
    height={height}
    className={className}
    id={id}
    onClick={onClick}
  >
    <path
      fill="currentColor"
      d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"
    ></path>
  </svg>
);

export const CloudIcon = ({ className, id, width, height, onClick }) => (
  <svg
    viewBox="0 0 17 12"
    width={width}
    height={height}
    className={className}
    id={id}
    onClick={onClick}
  >
    <path
      d="M16.81 8.4C16.81 10.26 15.3 11.77 13.45 11.77H3.78C1.69 11.77 0 10.07 0 7.98C0 6.33 1.06 4.93 2.53 4.41C2.52 4.34 2.52 4.27 2.52 4.2C2.52 1.88 4.4 0 6.72 0C8.28 0 9.64 0.85 10.37 2.11C10.77 1.84 11.25 1.68 11.77 1.68C13.16 1.68 14.29 2.81 14.29 4.2C14.29 4.52 14.23 4.83 14.12 5.11C15.65 5.42 16.81 6.78 16.81 8.4ZM10.33 6.72H8.61V3.78C8.61 3.55 8.43 3.36 8.19 3.36H6.93C6.7 3.36 6.51 3.55 6.51 3.78V6.72H4.8C4.42 6.72 4.23 7.18 4.5 7.44L7.27 10.21C7.43 10.37 7.7 10.37 7.86 10.21L10.63 7.44C10.89 7.18 10.7 6.72 10.33 6.72Z"
      fill="currentColor"
    />
  </svg>
);

export const WhitePlayIcon = ({ className, id, width, height, onClick }) => (
  <svg
    viewBox="0 0 60 69"
    width={width}
    height={height}
    className={className}
    id={id}
    onClick={onClick}
  >
    <path
      d="M56.84 28.82L9.7 0.949986C5.87 -1.31001 0 0.879986 0 6.47999V62.21C0 67.23 5.45 70.26 9.7 67.74L56.84 39.88C61.05 37.41 61.06 31.3 56.84 28.82Z"
      fill="currentColor"
    />
  </svg>
);

export const CheckIcon = ({ className, id, width, height, onClick }) => (
  <svg
    viewBox="0 0 512 512"
    width={width}
    height={height}
    className={className}
    id={id}
    onClick={onClick}
  >
    <path
      fill="white"
      className="tr"
      d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
    ></path>
  </svg>
);

export const SearchIcon = ({ className, id, width, height, onClick }) => (
  <svg
    viewBox="0 0 512 512"
    width={width}
    height={height}
    className={className}
    id={id}
    onClick={onClick}
  >
    <path
      fill="currentColor"
      d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
    ></path>
  </svg>
);

export const RecordPaperIcon = ({ className, id, width, height, onClick }) => (
  <svg
    viewBox="0 0 25 33"
    width={width}
    height={height}
    className={className}
    id={id}
    onClick={onClick}
  >
    <path
      d="M16.17 10.22H24.67V30.72C24.67 31.55 24 32.22 23.17 32.22H2.17C1.34 32.22 0.669998 31.55 0.669998 30.72V1.72C0.669998 0.890001 1.34 0.220001 2.17 0.220001H14.67V8.72C14.67 9.55 15.34 10.22 16.17 10.22ZM24.67 7.84V8.22H16.67V0.220001H17.05C17.44 0.220001 17.82 0.380001 18.11 0.660001L24.23 6.78C24.51 7.06 24.67 7.44 24.67 7.84ZM18.11 16.66L14.67 20.1V17.72C14.67 16.89 13.99 16.22 13.17 16.22H6.17C5.34 16.22 4.67 16.89 4.67 17.72V24.72C4.67 25.55 5.34 26.22 6.17 26.22H13.17C13.99 26.22 14.67 25.55 14.67 24.72V22.34L18.11 25.78C19.05 26.72 20.67 26.06 20.67 24.72V17.72C20.67 16.38 19.04 15.72 18.11 16.66Z"
      fill="currentColor"
    />
  </svg>
);

export const HeartIcon = ({ className, id, width, height, onClick, fill }) => (
  <svg
    viewBox="0 0 23 21"
    width={width}
    height={height}
    className={className}
    id={id}
    onClick={onClick}
  >
    <path
      d="M20.35 2.23001C17.94 0.170006 14.35 0.540006 12.14 2.82001L11.27 3.72001L10.4 2.82001C8.19 0.540006 4.6 0.170006 2.19 2.23001C-0.580003 4.59001 -0.720003 8.82001 1.75 11.38L10.27 20.17C10.82 20.74 11.71 20.74 12.26 20.17L20.78 11.38C23.26 8.82001 23.11 4.59001 20.35 2.23001Z"
      fill={fill || 'currentColor'}
    />
  </svg>
);

export const RightArrowIcon = ({ className, id, width, height, onClick }) => (
  <svg
    viewBox="0 0 320 512"
    width={width}
    height={height}
    className={className}
    id={id}
    onClick={onClick}
  >
    <path
      fill="currentColor"
      d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
    ></path>
  </svg>
);

export const HomeIcon = ({ className, id, width, height, onClick }) => (
  <svg
    viewBox="0 0 576 512"
    width={width}
    height={height}
    className={className}
    id={id}
    onClick={onClick}
  >
    <path
      fill="currentColor"
      d="M280.37 148.26L96 300.11V464a16 16 0 0 0 16 16l112.06-.29a16 16 0 0 0 15.92-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.64a16 16 0 0 0 16 16.05L464 480a16 16 0 0 0 16-16V300L295.67 148.26a12.19 12.19 0 0 0-15.3 0zM571.6 251.47L488 182.56V44.05a12 12 0 0 0-12-12h-56a12 12 0 0 0-12 12v72.61L318.47 43a48 48 0 0 0-61 0L4.34 251.47a12 12 0 0 0-1.6 16.9l25.5 31A12 12 0 0 0 45.15 301l235.22-193.74a12.19 12.19 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0-1.7-16.93z"
    ></path>
  </svg>
);

export const CarouselRightIcon = ({
  className,
  id,
  width,
  height,
  onClick
}) => (
  <svg
    viewBox="0 0 448 512"
    width={width}
    height={height}
    className={className}
    id={id}
    onClick={onClick}
  >
    <path
      fill="currentColor"
      d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"
    />
  </svg>
);

export const CarouselLeftIcon = ({ className, id, width, height, onClick }) => (
  <svg
    viewBox="0 0 448 512"
    width={width}
    height={height}
    className={className}
    id={id}
    onClick={onClick}
  >
    <path
      fill="currentColor"
      d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z"
    />
  </svg>
);

export const AvatarIconSvg = ({ className, id, width, height, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 52 52"
    width={width || 52}
    height={height || 52}
    className={className}
    id={id}
    onClick={onClick}
  >
    <g id="Group_49" data-name="Group 49" transform="translate(-1328 -84)">
      <circle
        id="portrait-white-man-isolated_53876-40306"
        cx="26"
        cy="26"
        r="26"
        transform="translate(1328 84)"
        fill="#d9dde1"
      />
      <g id="account_circle_black_24dp" transform="translate(1332.5 88.5)">
        <path
          id="Path_78"
          data-name="Path 78"
          d="M0,0H43.5V43.5H0Z"
          fill="none"
        />
        <path
          id="Path_79"
          data-name="Path 79"
          d="M20.125,2A18.125,18.125,0,1,0,38.25,20.125,18.132,18.132,0,0,0,20.125,2ZM11.189,31.508c.779-1.631,5.528-3.226,8.936-3.226s8.174,1.595,8.936,3.226a14.365,14.365,0,0,1-17.871,0Zm20.463-2.628c-2.592-3.154-8.881-4.223-11.528-4.223S11.189,25.726,8.6,28.879a14.5,14.5,0,1,1,23.055,0ZM20.125,9.25a6.344,6.344,0,1,0,6.344,6.344A6.328,6.328,0,0,0,20.125,9.25Zm0,9.063a2.719,2.719,0,1,1,2.719-2.719A2.715,2.715,0,0,1,20.125,18.313Z"
          transform="translate(1.625 1.625)"
          fill="#eff3f6"
        />
      </g>
    </g>
  </svg>
);

export const CircleSvg = ({ className, id, width, height, onClick, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={height}
    viewBox="0 0 24 24"
    width={width}
    fill={fill}
    className={className}
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2z" />
  </svg>
);

export const PackageIcon = ({
  className,
  id,
  width,
  height,
  onClick,
  fill
}) => (
  <svg
    viewBox="0 0 512 512"
    height={height}
    width={width}
    fill={fill}
    className={className}
  >
    <path
      fill="currentColor"
      d="M32 448c0 17.7 14.3 32 32 32h384c17.7 0 32-14.3 32-32V160H32v288zm160-212c0-6.6 5.4-12 12-12h104c6.6 0 12 5.4 12 12v8c0 6.6-5.4 12-12 12H204c-6.6 0-12-5.4-12-12v-8zM480 32H32C14.3 32 0 46.3 0 64v48c0 8.8 7.2 16 16 16h480c8.8 0 16-7.2 16-16V64c0-17.7-14.3-32-32-32z"
    ></path>
  </svg>
);

export const InfoIcon = ({ className, id, width, height, onClick, fill }) => (
  <svg
    viewBox="0 0 512 512"
    height={height}
    width={width}
    fill={fill}
    className={className}
  >
    <path
      fill="currentColor"
      d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"
    ></path>
  </svg>
);

export const CreditCardIcon = ({
  className,
  id,
  width,
  height,
  onClick,
  fill
}) => (
  <svg
    viewBox="0 0 576 512"
    height={height}
    width={width}
    fill={fill}
    className={className}
  >
    <path
      fill="currentColor"
      d="M0 432c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V256H0v176zm192-68c0-6.6 5.4-12 12-12h136c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H204c-6.6 0-12-5.4-12-12v-40zm-128 0c0-6.6 5.4-12 12-12h72c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM576 80v48H0V80c0-26.5 21.5-48 48-48h480c26.5 0 48 21.5 48 48z"
    ></path>
  </svg>
);

export const PayPalIcon = ({ className, id, width, height, onClick, fill }) => (
  <svg
    viewBox="0 0 384 512"
    height={height}
    width={width}
    fill={fill}
    className={className}
  >
    <path
      fill="currentColor"
      d="M111.4 295.9c-3.5 19.2-17.4 108.7-21.5 134-.3 1.8-1 2.5-3 2.5H12.3c-7.6 0-13.1-6.6-12.1-13.9L58.8 46.6c1.5-9.6 10.1-16.9 20-16.9 152.3 0 165.1-3.7 204 11.4 60.1 23.3 65.6 79.5 44 140.3-21.5 62.6-72.5 89.5-140.1 90.3-43.4.7-69.5-7-75.3 24.2zM357.1 152c-1.8-1.3-2.5-1.8-3 1.3-2 11.4-5.1 22.5-8.8 33.6-39.9 113.8-150.5 103.9-204.5 103.9-6.1 0-10.1 3.3-10.9 9.4-22.6 140.4-27.1 169.7-27.1 169.7-1 7.1 3.5 12.9 10.6 12.9h63.5c8.6 0 15.7-6.3 17.4-14.9.7-5.4-1.1 6.1 14.4-91.3 4.6-22 14.3-19.7 29.3-19.7 71 0 126.4-28.8 142.9-112.3 6.5-34.8 4.6-71.4-23.8-92.6z"
    ></path>
  </svg>
);

export const GroupIcon = ({ className, id, width, height, onClick, fill }) => (
  <svg
    viewBox="0 0 63 45"
    height={height}
    width={width}
    fill={fill}
    className={className}
  >
    <path
      d="M9.37 19.12C12.82 19.12 15.62 16.32 15.62 12.87C15.62 9.42999 12.82 6.62 9.37 6.62C5.93 6.62 3.12 9.42999 3.12 12.87C3.12 16.32 5.93 19.12 9.37 19.12ZM53.12 19.12C56.57 19.12 59.37 16.32 59.37 12.87C59.37 9.42999 56.57 6.62 53.12 6.62C49.68 6.62 46.87 9.42999 46.87 12.87C46.87 16.32 49.68 19.12 53.12 19.12ZM56.25 22.25H50C48.28 22.25 46.73 22.94 45.6 24.07C49.53 26.22 52.32 30.12 52.93 34.75H59.37C61.1 34.75 62.5 33.35 62.5 31.62V28.5C62.5 25.05 59.7 22.25 56.25 22.25ZM31.25 22.25C37.29 22.25 42.19 17.36 42.19 11.31C42.19 5.27 37.29 0.369995 31.25 0.369995C25.2 0.369995 20.31 5.27 20.31 11.31C20.31 17.36 25.2 22.25 31.25 22.25ZM38.75 25.37H37.94C35.91 26.35 33.65 26.94 31.25 26.94C28.85 26.94 26.6 26.35 24.56 25.37H23.75C17.54 25.37 12.5 30.41 12.5 36.62V39.44C12.5 42.02 14.6 44.12 17.19 44.12H45.31C47.9 44.12 50 42.02 50 39.44V36.62C50 30.41 44.96 25.37 38.75 25.37ZM16.9 24.07C15.77 22.94 14.22 22.25 12.5 22.25H6.25C2.8 22.25 0 25.05 0 28.5V31.62C0 33.35 1.4 34.75 3.12 34.75H9.56C10.18 30.12 12.97 26.22 16.9 24.07Z"
      fill="currentColor"
    />
  </svg>
);

export const PersonTagIcon = ({
  className,
  id,
  width,
  height,
  onClick,
  fill
}) => (
  <svg
    viewBox="0 0 28 22"
    height={height}
    width={width}
    fill={fill}
    className={className}
  >
    <path
      d="M27.35 15.78C27.88 16.3 27.88 17.16 27.35 17.69L23.43 21.6C22.91 22.13 22.05 22.13 21.52 21.6L17.7 17.79C17.19 17.28 16.9 16.59 16.9 15.87V12.52C16.9 11.77 17.51 11.17 18.26 11.17H21.61C22.33 11.17 23.02 11.45 23.53 11.96L27.35 15.78ZM20.63 13.87C20.63 13.31 20.18 12.86 19.62 12.86C19.05 12.86 18.6 13.31 18.6 13.87C18.6 14.43 19.05 14.89 19.62 14.89C20.18 14.89 20.63 14.43 20.63 13.87ZM10.15 11.16C7.15001 11.16 4.73001 8.74 4.73001 5.75C4.73001 2.76 7.15001 0.330002 10.15 0.330002C13.14 0.330002 15.56 2.76 15.56 5.75C15.56 8.74 13.14 11.16 10.15 11.16ZM15.55 15.87C15.55 16.96 15.98 17.98 16.74 18.74L19.2 21.2C18.83 21.68 18.25 22 17.59 22H2.70001C1.58001 22 0.670013 21.09 0.670013 19.97V18.2C0.670013 15.06 3.21001 12.52 6.35001 12.51H7.06001C8.00001 12.94 9.05001 13.19 10.15 13.19C11.25 13.19 12.29 12.94 13.23 12.51H13.94C14.5 12.51 15.04 12.62 15.55 12.78V15.87Z"
      fill="currentColor"
    />
  </svg>
);

export const RightBlueArrowIcon = ({
  className,
  id,
  width,
  height,
  onClick,
  fill
}) => (
  <svg
    viewBox="0 0 448 512"
    height={height}
    width={width}
    fill={fill}
    className={className}
  >
    <path
      fill="currentColor"
      d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"
    ></path>
  </svg>
);

export const UploadCloudIcon = ({
  className,
  id,
  width,
  height,
  onClick,
  fill
}) => (
  <svg
    viewBox="0 0 640 512"
    height={height}
    width={width}
    fill={fill}
    className={className}
  >
    <path
      fill="currentColor"
      d="M537.6 226.6c4.1-10.7 6.4-22.4 6.4-34.6 0-53-43-96-96-96-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32c-88.4 0-160 71.6-160 160 0 2.7.1 5.4.2 8.1C40.2 219.8 0 273.2 0 336c0 79.5 64.5 144 144 144h368c70.7 0 128-57.3 128-128 0-61.9-44-113.6-102.4-125.4zM393.4 288H328v112c0 8.8-7.2 16-16 16h-48c-8.8 0-16-7.2-16-16V288h-65.4c-14.3 0-21.4-17.2-11.3-27.3l105.4-105.4c6.2-6.2 16.4-6.2 22.6 0l105.4 105.4c10.1 10.1 2.9 27.3-11.3 27.3z"
    ></path>
  </svg>
);

export const ArrowDown = ({ className, id, width, height, onClick, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    height={height}
    width={width}
    fill={fill}
    className={className}
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M7 10l5 5 5-5z" />
  </svg>
);

export const FolderIcon = ({ className, id, width, height, onClick, fill }) => (
  <svg
    viewBox="0 0 30 20"
    height={height}
    width={width}
    fill={fill}
    className={className}
  >
    <path
      d="M29.08 11.58C29.56 10.77 28.97 9.75 28.03 9.75H7.72C6.69 9.75 5.43 10.47 4.91 11.36L1.23 17.66C0.76 18.47 1.35 19.5 2.29 19.5H22.6C23.63 19.5 24.88 18.77 25.4 17.88L29.08 11.58ZM7.72 8.12H24.37V5.68C24.37 4.34 23.28 3.25 21.94 3.25H13.81L10.56 0H2.44C1.09 0 0 1.09 0 2.43V16.55L3.51 10.54C4.38 9.05 5.99 8.12 7.72 8.12Z"
      fill="currentColor"
    />
  </svg>
);

export const PlusSquareIcon = ({
  className,
  id,
  width,
  height,
  onClick,
  fill
}) => (
  <svg
    viewBox="0 0 448 512"
    height={height}
    width={width}
    fill={fill}
    className={className}
  >
    <path
      fill="currentColor"
      d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-32 252c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92H92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z"
    ></path>
  </svg>
);

export const MinusSquareIcon = ({
  className,
  id,
  width,
  height,
  onClick,
  fill
}) => (
  <svg
    viewBox="0 0 448 512"
    height={height}
    width={width}
    fill={fill}
    className={className}
  >
    <path
      fill="currentColor"
      d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zM92 296c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h264c6.6 0 12 5.4 12 12v56c0 6.6-5.4 12-12 12H92z"
    ></path>
  </svg>
);

export const BriefcaseIcon = ({
  className,
  id,
  width,
  height,
  onClick,
  fill
}) => (
  <svg
    viewBox="0 0 23 23"
    height={height}
    width={width}
    fill={fill}
    className={className}
  >
    <path d="M13.44 10.94H21.5V16.98C21.5 18.06 20.56 19 19.48 19H2.02C0.94 19 0 18.06 0 16.98V10.94H8.06V12.95C8.06 13.32 8.36 13.62 8.73 13.62H12.77C13.14 13.62 13.44 13.32 13.44 12.95V10.94ZM21.5 6.23V9.59H0V6.23C0 5.16 0.94 4.22 2.02 4.22H5.37V2.2C5.37 1.13 6.32 0.190002 7.39 0.190002H14.11C15.18 0.190002 16.12 1.13 16.12 2.2V4.22H19.48C20.56 4.22 21.5 5.16 21.5 6.23ZM13.44 2.87H8.06V4.22H13.44V2.87Z" />
  </svg>
);

export const ResetIcon = ({ className, id, width, height, onClick, fill }) => (
  <svg
    viewBox="0 0 23 23"
    height={height}
    width={width}
    fill={fill}
    className={className}
  >
    <path
      d="M9.47 10.52H0.53C0.24 10.52 0 10.28 0 9.98999V1.04999C0 0.749994 0.24 0.509995 0.53 0.509995H2.68C2.97 0.509995 3.21 0.749994 3.21 1.04999V4.52999C5.25 2.26999 8.22 0.849995 11.52 0.869995C17.63 0.919995 22.51 5.84999 22.5 11.96C22.49 18.06 17.54 23 11.44 23C8.58 23 5.97 21.91 4.01 20.13C3.78 19.93 3.77 19.57 3.99 19.36L5.5 17.84C5.7 17.64 6.02 17.63 6.23 17.82C7.62 19.04 9.44 19.79 11.44 19.79C15.77 19.79 19.29 16.28 19.29 11.94C19.29 7.59999 15.78 4.07999 11.44 4.07999C8.83 4.07999 6.52 5.34999 5.09 7.30999H9.47C9.77 7.30999 10.01 7.54999 10.01 7.84999V9.98999C10.01 10.28 9.77 10.52 9.47 10.52Z"
      fill="currentColor"
    />
  </svg>
);

export const NoteIcon = ({ className, id, width, height, onClick, fill }) => (
  <svg
    viewBox="0 0 17 20"
    height={height}
    width={width}
    fill={fill}
    className={className}
  >
    <path
      d="M4.86 16.36H0.91C0.41 16.36 0 15.95 0 15.45V2.70001C0 2.19001 0.41 1.79001 0.91 1.79001H3.97C4.39 1.06001 5.17 0.570007 6.07 0.570007C6.97 0.570007 7.75 1.06001 8.17 1.79001H11.23C11.73 1.79001 12.14 2.19001 12.14 2.70001V5.43001H6.98C5.81 5.43001 4.86 6.38001 4.86 7.55001V16.36ZM5.16 3.00001C5.16 3.50001 5.57 3.91001 6.07 3.91001C6.57 3.91001 6.98 3.50001 6.98 3.00001C6.98 2.50001 6.57 2.09001 6.07 2.09001C5.57 2.09001 5.16 2.50001 5.16 3.00001ZM13.05 11.5H17V19.09C17 19.59 16.59 20 16.09 20H6.98C6.48 20 6.07 19.59 6.07 19.09V7.55001C6.07 7.05001 6.48 6.64001 6.98 6.64001H12.14V10.59C12.14 11.09 12.55 11.5 13.05 11.5ZM17 10.06V10.29H13.36V6.64001H13.59C13.83 6.64001 14.06 6.74001 14.23 6.91001L16.73 9.41001C16.9 9.58001 17 9.81001 17 10.06Z"
      fill={fill || 'currentColor'}
    />
  </svg>
);

export const CirclePlayIcon = ({
  className,
  id,
  width,
  height,
  onClick,
  fill
}) => (
  <svg
    viewBox="0 0 512 512"
    height={height}
    width={width}
    fill={fill}
    className={className}
  >
    <path
      fill={fill || 'currentColor}'}
      d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm115.7 272l-176 101c-15.8 8.8-35.7-2.5-35.7-21V152c0-18.4 19.8-29.8 35.7-21l176 107c16.4 9.2 16.4 32.9 0 42z"
    ></path>
  </svg>
);
