import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Navigation from '../../components/Navigation';

const ProtectedRoute = ({authenticated, redirectTo, ...props}) => {

    const { user } = useSelector(state => state.global);

    const [propMenu, setPropMenu] = useState(false);

    const togglePropMenu = () => setPropMenu(!propMenu);

    return authenticated ? (
      user?.role === 'admin' ? (
        <main
          className={` container flex-wrap main-js ${
            propMenu ? ' main-fixed  ' : ' '
          }`}
        >
          <Header
            user={user}
            propMenu={propMenu}
            togglePropMenu={togglePropMenu}
          />
          <Navigation />
          <Route {...props} />
          <Footer />
        </main>
      ) : (
        <Redirect to={redirectTo} />
      )
    ) : user?.role !== 'admin' ? (
      <Route {...props} />
    ) : (
      <Redirect to={redirectTo} />
    );
}

export default ProtectedRoute;