import React, { useState } from 'react';
import { RightArrowIcon } from './Icons';

const Pagination = ({ params, count, handlePageChange }) => {
  const totalPages = Math.ceil(count / params.limit);
  let page = parseInt(params.offset / params.limit) + 1;
  const [shownPages, setShownPages] = useState([1, 2]);

  const handlePageChangeFunc = i => {
    if (0) {
      setShownPages([1, 2]);
    } else if (i === totalPages - 2) {
      setShownPages([totalPages - 2, totalPages - 1]);
    } else if (i === totalPages - 1) {
      setShownPages([totalPages - 2, totalPages - 1]);
    } else {
      setShownPages([i + 1, i + 2]);
    }
    handlePageChange(i);
  };

  const newPage = index => {
    handlePageChangeFunc(index);
    handlePageChange(index);
  };

  return (
    <div className="pagination-box">
      {totalPages > 3 ? (
        <>
          {page > 1 && (
            <button
              onClick={() => newPage(page - 2)}
              style={{
                display: page === 0 ? 'none' : 'block',
                transform: 'rotate(180deg)'
              }}
            >
              <RightArrowIcon />
            </button>
          )}

          {shownPages.map((e, i) => {
            return (
              <button
                key={e}
                className={page === e ? 'active' : ''}
                onClick={() => handlePageChangeFunc(e - 1)}
              >
                {e}
              </button>
            );
          })}

          <button
            className={page === totalPages ? 'active' : ''}
            onClick={() => handlePageChangeFunc(totalPages - 1)}
          >
            {totalPages}
          </button>

          {page !== totalPages && (
            <button
              onClick={() => newPage(page)}
              style={{ display: page === totalPages ? 'none' : 'block' }}
            >
              <RightArrowIcon />
            </button>
          )}
        </>
      ) : (
        <>
          <button
            className={page === 1 ? 'active' : ''}
            onClick={() => handlePageChangeFunc(0)}
          >
            01
          </button>
          {totalPages > 1 && (
            <button
              className={page === 2 ? 'active' : ''}
              onClick={() => handlePageChangeFunc(1)}
            >
              02
            </button>
          )}
          {totalPages > 2 && (
            <button
              className={page === 3 ? 'active' : ''}
              onClick={() => handlePageChangeFunc(2)}
            >
              03
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default Pagination;
