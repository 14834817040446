import React from 'react';

const Confirmation = props => {
  const closeModal = (prop, answer) => event => {
    props.closeModal(prop, answer);
  };

  return (
    <div
      id="confirmArchive"
      className="modal-bg"
      style={{ display: props.modal ? 'block' : 'none' }}
    >
      <div className="modal alert-modal">
        <div className="modal-header">
          <h5>{props.title}</h5>
        </div>

        <div className="modal-body justify-between">
          <h5>{props.text}</h5>
          <button
            className="all-btns blue-btn w-50 upper"
            onClick={closeModal(props.name, true)}
          >
            Da
          </button>
          <button
            className="all-btns gray-btn w-50 upper"
            onClick={closeModal(props.name, false)}
          >
            Ne
          </button>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
