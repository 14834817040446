import React, { useState } from 'react';
import { DropdownIcon2, XIcon } from '../../../components/Icons';
import { ComboBox } from '../../../components/Inputs';
import { getSearchCities } from '../../../redux/actions/countryActions';
import { connect } from 'react-redux';

const roles = [
  { id: 'admin', name: "Admin" },
  { id: 'company', name: "Kompanija" },
  { id: 'employee', name: "Kandidat" }
]

const Filter = props => {
  const [values, setValues] = useState({
    role: ''
  });

  const handleAutoCompleteSelect = (e, value, name) => {
    console.log(e, value, name);
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = (e, prop) => {
    e.preventDefault();
    props.closeModal(prop, { role: values.role.id });
  };

  const handleReset = () => {
    setValues({
      role: ''
    });

    props.closeModal('filter', null);
  };

  return (
    <div
      id="filters"
      className="modal-bg"
      style={{ display: props.modal ? 'block' : 'none' }}
    >
      <div className="modal s-modal">
        <div className="modal-header">
          <h5>Filteri</h5>
          <button
            className="close-btn"
            onClick={() => props.closeModal('filter')}
          >
            <XIcon />
          </button>
        </div>
        <div className="modal-body justify-between">
          <div className="box box-100">
            <div className="select-wrapper">
              <ComboBox
                className="select"
                options={roles}
                name="role"
                label="name"
                value={values.role}
                onChangeAutocomplete={handleAutoCompleteSelect}
                comboLabel="Uloga korisnika"
              />
              <DropdownIcon2 className="select-arrow " />
            </div>
          </div>
          <button
            className="all-btns blue-btn w-full mb-20 upper"
            onClick={e => handleSubmit(e, 'filter')}
          >
            Filtriranje
          </button>
          <button className="all-btns upper no-bg w-full" onClick={handleReset}>
            Resetujte filtere
          </button>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
  };
}

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
