import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function singleMessageReducer(
  state = initialState.single_message,
  action
) {
  switch (action.type) {
    case types.LOAD_SINGLE_MESSAGE_SUCCESS:
      return action.message;
    case types.LOAD_SINGLE_MESSAGE_FAILURE:
      return {};
    default:
      return state;
  }
}
