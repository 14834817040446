import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { Switch } from 'react-router';
import './scss/main.scss';
import { loadUser, getAllAddings } from './redux/actions/userActions';
import LayoutRoute from './components/LayoutRoute';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';

import NotFound from './pages/NotFound';
import Messages from './pages/Messages';
import Notifications from './pages/Notifications';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import ActiveUsers from './pages/users/active';
import ArchivedUsers from './pages/users/archived';

import ActiveCompanies from './pages/companies/active';
import ArchivedCompanies from './pages/companies/archived';

import ActiveAds from './pages/ads/active';
import ArchivedAds from './pages/ads/archived';
import PreviewAd from './pages/ads/preview';

import ActiveCandidates from './pages/candidates/active';
import ArchivedCandidates from './pages/candidates/archived';
import Settings from './pages/settings';
import PreviewCandidate from './pages/candidates/preview';
import PreviewCompany from './pages/companies/preview';
import EditCompany from './pages/companies/edit';
import AddAd from './pages/ads/add-ad';
import EditAd from './pages/ads/edit';
import EditCandidate from './pages/candidates/edit';
import CompanyAds from './pages/companies/ads';
import UserProfile from './pages/profile/profile';
import EditUserProfile from './pages/profile/profile/edit';
import ProtectedRoute from './routing/protected-route';
import CreateAd from './pages/ads/create';

const App = () => {

  return (
    <div className="App">
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
      <Switch>
        <ProtectedRoute
          exact
          path="/"
          authenticated={true}
          redirectTo="/login"
          component={Dashboard}
        />
        <ProtectedRoute
          exact
          path="/active-users"
          authenticated={true}
          redirectTo="/login"
          component={ActiveUsers}
        />
        <ProtectedRoute
          exact
          path="/archived-users"
          authenticated={true}
          redirectTo="/login"
          component={ArchivedUsers}
        />
        <ProtectedRoute
          exact
          path="/settings"
          authenticated={true}
          redirectTo="/login"
          component={Settings}
        />
        <ProtectedRoute
          exact
          path="/active-companies"
          authenticated={true}
          redirectTo="/login"
          component={ActiveCompanies}
        />
        <ProtectedRoute
          exact
          path="/archived-companies"
          authenticated={true}
          redirectTo="/login"
          component={ArchivedCompanies}
        />
        <ProtectedRoute
          exact
          path="/active-candidates"
          authenticated={true}
          redirectTo="/login"
          component={ActiveCandidates}
        />
        <ProtectedRoute
          exact
          path="/archived-candidates"
          authenticated={true}
          redirectTo="/login"
          component={ArchivedCandidates}
        />
        <ProtectedRoute
          exact
          path="/active-ads"
          authenticated={true}
          redirectTo="/login"
          component={ActiveAds}
        />
        <ProtectedRoute
          exact
          path="/archived-ads"
          authenticated={true}
          redirectTo="/login"
          component={ArchivedAds}
        />
        <ProtectedRoute
          exact
          path="/create-ad"
          authenticated={true}
          redirectTo="/login"
          component={CreateAd}
        />
        <ProtectedRoute
          exact
          path="/ad/:id/edit"
          authenticated={true}
          redirectTo="/login"
          component={EditAd}
        />
        <ProtectedRoute
          exact
          path="/ad/:id/preview"
          authenticated={true}
          redirectTo="/login"
          component={PreviewAd}
        />
        <ProtectedRoute
          exact
          path="/candidate/:id/preview"
          authenticated={true}
          redirectTo="/login"
          component={PreviewCandidate}
        />
        <ProtectedRoute
          exact
          path="/candidate/:id/edit"
          authenticated={true}
          redirectTo="/login"
          component={EditCandidate}
        />
        <ProtectedRoute
          exact
          path="/company/:id/preview"
          authenticated={true}
          redirectTo="/login"
          component={PreviewCompany}
        />
        <ProtectedRoute
          exact
          path="/company/:id/edit"
          authenticated={true}
          redirectTo="/login"
          component={EditCompany}
        />
        <ProtectedRoute
          exact
          path="/login"
          authenticated={false}
          redirectTo="/"
          component={Login}
        />
      </Switch>

      {/* <Switch>
        <Route exact key="LoginPage" path="/login" component={Login} />
        <Route exact key="NotFound" path="/not-found" component={NotFound} />
        <LayoutRoute
          user={user}
          exact
          key="Dashboard"
          path="/"
          component={Dashboard}
        />
        <LayoutRoute
          user={user}
          exact
          key="ActiveUsers"
          path="/active-users"
          component={ActiveUsers}
        />
        <LayoutRoute
          user={user}
          exact
          key="ArchivedUsers"
          path="/archived-users"
          component={ArchivedUsers}
        />
        <LayoutRoute
          user={user}
          exact
          key="ActiveCompanies"
          path="/active-companies"
          component={ActiveCompanies}
        />
        <LayoutRoute
          user={user}
          exact
          key="ArchivedCompanies"
          path="/archived-companies"
          component={ArchivedCompanies}
        />
        <LayoutRoute
          user={user}
          exact
          key="SingleCompany"
          path="/company/:id"
          component={Profile}
        />
        <LayoutRoute
          user={user}
          exact
          key="SingleCompany"
          path="/company/edit/:id"
          component={EditProfile}
        />
        <LayoutRoute
          user={user}
          exact
          key="CompanyAds"
          path="/company-ads/:id"
          component={CompanyAds}
        />
        <LayoutRoute
          user={user}
          exact
          key="AdsPage"
          path="/active-ads"
          component={ActiveAds}
        />
        <LayoutRoute
          user={user}
          exact
          key="AdsPage"
          path="/archived-ads"
          component={ArchivedAds}
        />
        <LayoutRoute
          user={user}
          exact
          key="AdsPage"
          path="/ads/:id"
          component={PreviewAd}
        />

        <LayoutRoute
          user={user}
          exact
          key="EditAd"
          path="/ads/:id/edit"
          component={EditAd}
        />
        <LayoutRoute
          user={user}
          exact
          key="ActiveCandidates"
          path="/active-candidates"
          component={ActiveCandidates}
        />
        <LayoutRoute
          user={user}
          exact
          key="ArchivedCandidates"
          path="/archived-candidates"
          component={ArchivedCandidates}
        />
        <LayoutRoute
          user={user}
          exact
          key="SettingsPage"
          path="/settings"
          component={Settings}
        />
        <LayoutRoute
          user={user}
          exact
          key="CandidatePage"
          path="/candidate/:id"
          component={CandidatePreview}
        />
        <LayoutRoute
          user={user}
          exact
          key="CandidateEditPage"
          path="/candidate/edit/:id"
          component={CandidateEdit}
        />
        <LayoutRoute
          user={user}
          exact
          key="Messages"
          path="/messages"
          component={Messages}
        />
        <LayoutRoute
          user={user}
          exact
          key="Notifications"
          path="/notifications"
          component={Notifications}
        />
        <LayoutRoute
          user={user}
          exact
          key="AddAd"
          path="/add-ad"
          component={AddAd}
        />
        <LayoutRoute
          user={user}
          exact
          key="UserProfile"
          path="/profile"
          component={UserProfile}
        />
        <LayoutRoute
          user={user}
          exact
          key="ProfileEdit"
          path="/profile/edit"
          component={EditUserProfile}
        />
        <Route key="Home" path="*">
          <Redirect to="/not-found" />
        </Route>
      </Switch>
     */}
    </div>
  );
}

export default App;

// function App({ loadUserData, logoutUser, user, loadAllAddings }) {
//   useEffect(() => {
//     loadUserData();
//   }, [loadUserData]);

//   useEffect(() => {
//     loadAllAddings();
//   }, [loadAllAddings]);

//   return (
    
//   );
// }

// function mapStateToProps(state) {
//   return {
//     user: state.user
//   };
// }

// const mapDispatchToProps = {
//   loadUserData: loadUser,
//   loadAllAddings: getAllAddings
// };

// export default connect(mapStateToProps, mapDispatchToProps)(App);
