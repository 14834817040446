import axios from 'axios';
import qs from 'qs';

import { apiBaseUrl, token } from './config';

export const getUserData = () => {
  return axios({
    method: 'post',
    url: `${apiBaseUrl}/api/user/getinfo`,
    headers: {
      Authorization: token
    }
  }).then(res => res.data);
};

export const viewNotification = data => {
  return axios({
    method: 'post',
    url: `${apiBaseUrl}/api/user/viewNotification`,
    headers: {
      Authorization: token
    },
    data
  }).then(res => res.data);
};

export const getNotifications = data => {
  return axios({
    method: 'post',
    url: `${apiBaseUrl}/api/user/getNotifications`,
    headers: {
      Authorization: token
    },
    data
  }).then(res => res.data);
};

export const getSingleMessage = data => {
  return axios({
    method: 'post',
    url: `${apiBaseUrl}/api/user/getSingleMessage`,
    headers: {
      Authorization: token
    },
    data
  }).then(res => res.data);
};

export const viewMessage = data => {
  return axios({
    method: 'post',
    url: `${apiBaseUrl}/api/user/viewMessage`,
    headers: {
      Authorization: token
    },
    data
  }).then(res => res.data);
};

export const sendMessage = data => {
  return axios({
    method: 'post',
    url: `${apiBaseUrl}/api/user/createMessage`,
    headers: {
      Authorization: token
    },
    data
  }).then(res => res.data);
};

export const sendGroupMessage = data => {
  return axios({
    method: 'post',
    url: `${apiBaseUrl}/api/user/sendMessages`,
    headers: {
      Authorization: token
    },
    data
  }).then(res => res.data);
};

export const getAdminDashboard = params => {
  return axios({
    method: 'get',
    url: `${apiBaseUrl}/api/v2/admin/dashboard`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    params
  }).then(res => res.data);
};

export const getSingleUser = data => {
  return axios({
    method: 'post',
    url: `${apiBaseUrl}/api/user/getUser`,
    headers: {
      Authorization: token
    },
    data
  }).then(res => res.data);
};

export const getMessages = data => {
  return axios({
    method: 'post',
    url: `${apiBaseUrl}/api/user/getMessages`,
    headers: {
      Authorization: token
    },
    data
  }).then(res => res.data);
};

export const getConversation = data => {
  return axios({
    method: 'post',
    url: `${apiBaseUrl}/api/user/getConversation`,
    headers: {
      Authorization: token
    },
    data
  }).then(res => res.data);
};

export const getAllAddings = () => {
  return axios({
    method: 'get',
    url: `${apiBaseUrl}/api/user/getAllAddings`
  }).then(res => res.data);
};

export const deactivateUser = data => {
  return axios({
    method: 'post',
    url: `${apiBaseUrl}/api/user/deactivate`,
    headers: {
      Authorization: token
    },
    data
  }).then(res => res.data);
};

export const getAllUsers = params => {
  return axios({
    method: 'get',
    url: `${apiBaseUrl}/api/v2/admin/users`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    params
  }).then(res => res.data);
};

export const archiveMultipleUsers = data => {
  return axios({
    method: 'patch',
    url: `${apiBaseUrl}/api/v2/admin/users/set-archived`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Accept: 'application/json'
    },
    data: qs.stringify(data)
  }).then(res => console.log(res));
};

export const archiveSingleUsers = id => {
  return axios({
    method: 'patch',
    url: `${apiBaseUrl}/api/v2/admin/users/${id}/set-archived`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Accept: 'application/json'
    },
    body: qs.stringify({
      is_archived: 1
    })
  }).then(res => res.data);
};

export const resetSingleUser = id => {
  return axios({
    method: 'patch',
    url: `${apiBaseUrl}/api/v2/admin/users/${id}/reset`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Accept: 'application/json'
    }
  }).then(res => res.data);
};

export const resetMultipleUsers = data => {
  return axios({
    method: 'patch',
    url: `${apiBaseUrl}/api/v2/admin/users/reset`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Accept: 'application/json'
    },
    data: qs.stringify(data)
  }).then(res => res.data);
};

export const deleteSingleUser = id => {
  return axios({
    method: 'delete',
    url: `${apiBaseUrl}/api/v2/admin/users/${id}`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Accept: 'application/json'
    }
  }).then(res => res.data);
};

export const deleteMultipleUsers = ids => {
  return axios({
    method: 'delete',
    url: `${apiBaseUrl}/api/v2/admin/users`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Accept: 'application/json'
    },
    data: qs.stringify(ids)
  }).then(res => res.data);
};

export const toggleUser = (id, data) => {
  return axios({
    method: 'patch',
    url: `${apiBaseUrl}/api/v2/admin/users/${id}/set-hidden`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Accept: 'application/json'
    },
    data: qs.stringify(data)
  }).then(res => res.data);
};

export const createUser = data => {
  return axios({
    method: 'post',
    url: `${apiBaseUrl}/api/user/createUser`,
    headers: {
      Authorization: token
    },
    data
  }).then(res => res.data);
};

export const getCompanies = data => {
  return axios({
    method: 'post',
    url: `${apiBaseUrl}/api/user/getCompanies`,
    data
  }).then(res => res.data);
};

export const updateUserVideo = data => {
  return axios({
    method: 'post',
    url: `${apiBaseUrl}/api/user/updateAdminVideo`,
    headers: {
      Authorization: token
    },
    data
  }).then(res => res.data);
};

export const updateUser = data => {
  return axios({
    method: 'post',
    url: `${apiBaseUrl}/api/user/updateAdminUser`,
    headers: {
      Authorization: token
    },
    data
  }).then(res => res.data);
};

export const updateProfileImageAdmin = data => {
  return axios({
    method: 'post',
    url: `${apiBaseUrl}/api/user/profileAdminImage`,
    headers: {
      Authorization: token
    },
    data
  }).then(res => res.data);
};

export const updateProfileImage = data => {
  return axios({
    method: 'post',
    url: `${apiBaseUrl}/api/user/profileImage`,
    headers: {
      Authorization: token
    },
    data
  }).then(res => res.data);
};

export const updateUserData = data => {
  return axios({
    method: 'patch',
    url: `${apiBaseUrl}/api/v2/admin/update`,
    headers: {
      Authorization: token
    },
    data
  }).then(res => res.data);
};

//Update candidate's info
export const updateCandidateData = body => {
  return axios({
    method: 'patch',
    url: `${apiBaseUrl}/api/v2/candidate/update`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Accept: 'application/json'
    },
    data: qs.stringify(body)
  }).then(res => res.data);
};

//Create education
export const createEducation = body => {
  return axios({
    method: 'post',
    url: `${apiBaseUrl}/api/v2/candidate/educations`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    data: body
  }).then(res => res.data);
};

//Update education
export const updateEducation = (id, body) => {
  return axios({
    method: 'patch',
    url: `${apiBaseUrl}/api/v2/candidate/educations/${id}`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Accept: 'application/json'
    },
    data: qs.stringify(body)
  }).then(res => res.data);
};

//Delete Education
export const deleteEducation = id => {
  return axios({
    method: 'delete',
    url: `${apiBaseUrl}/api/v2/candidate/educations/${id}`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Accept: 'application/json'
    }
  }).then(res => res.data);
};

//Create language
export const createLanguage = body => {
  return axios({
    method: 'post',
    url: `${apiBaseUrl}/api/v2/candidate/languages`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    data: body
  }).then(res => res.data);
};

//Update language
export const updateLanguage = (body, id) => {
  return axios({
    method: 'patch',
    url: `${apiBaseUrl}/api/v2/candidate/languages/${id}`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json; charset=UTF-8',
      Accept: 'application/json'
    },
    data: body
  }).then(res => res.data);
};

//Delete language
export const deleteLanguage = (id, body) => {
  return axios({
    method: 'delete',
    url: `${apiBaseUrl}/api/v2/candidate/languages/${id}`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json; charset=UTF-8',
      Accept: 'application/json',
    },
    data: body
  }).then(res => res.data);
};


export const createExperience = body => {
  return axios({
    method: 'post',
    url: `${apiBaseUrl}/api/v2/candidate/work-experiences`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    data: body
  }).then(res => res.data);
};

//Update language
export const updateExperience = (body, id) => {
  return axios({
    method: 'patch',
    url: `${apiBaseUrl}/api/v2/candidate/work-experiences/${id}`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json; charset=UTF-8',
      Accept: 'application/json'
    },
    data: body
  }).then(res => res.data);
};

//Delete language
export const deleteExperience = (id, user_id) => {
  return axios({
    method: 'delete',
    url: `${apiBaseUrl}/api/v2/candidate/work-experiences/${id}`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json; charset=UTF-8',
      Accept: 'application/json',
    },
    data: JSON.stringify({ user_id })
  }).then(res => res.data);
};


export const createSkill = body => {
  return axios({
    method: 'post',
    url: `${apiBaseUrl}/api/v2/candidate/computer-skills`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    data: body
  }).then(res => res.data);
};

//Update language
export const updateSkill = (body, id) => {
  return axios({
    method: 'patch',
    url: `${apiBaseUrl}/api/v2/candidate/computer-skills/${id}`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json; charset=UTF-8',
      Accept: 'application/json'
    },
    data: body
  }).then(res => res.data);
};

//Delete language
export const deleteSkill = (id, user_id) => {
  return axios({
    method: 'delete',
    url: `${apiBaseUrl}/api/v2/candidate/computer-skills/${id}`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json; charset=UTF-8',
      Accept: 'application/json',
    },
    data: JSON.stringify({user_id})
  }).then(res => res.data);
};

export const updateLicence = (body) => {
  return axios({
    method: 'patch',
    url: `${apiBaseUrl}/api/v2/candidate/update-driver-licence`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json; charset=UTF-8',
      Accept: 'application/json',
    },
    data: body
  }).then(res => res.data);
};

export const updateOptionalFields = body => {
  return axios({
    method: 'patch',
    url: `${apiBaseUrl}/api/v2/candidate/fields/update`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json; charset=UTF-8',
      Accept: 'application/json'
    },
    data: body
  }).then(res => res.data);
};



export const updateEminent = (body, company_id) => {
  return axios({
    method: 'post',
    url: `${apiBaseUrl}/api/v2/admin/companies/${company_id}/set-eminent`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json; charset=UTF-8',
      Accept: 'application/json'
    },
    data: body
  }).then(res => res.data);
};

export const updateCompany = (body) => {
  return axios({
    method: 'patch',
    url: `${apiBaseUrl}/api/v2/company/update`,
    headers: {
      Authorization: token,
      'Content-Type': 'application/json; charset=UTF-8',
      Accept: 'application/json'
    },
    data: body
  }).then(res => res.data);

}