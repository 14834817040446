import { Box, withStyles } from '@material-ui/core';

export const BoxWrapper = withStyles(() => ({
  root: {
    width: '100%',
    maxWidth: ({ size = 'small' }) =>
      ({ small: 360, medium: 570, large: 1000 }[size]),
    background: 'linear-gradient(0deg,#f1f4f7,#fff)',
    display: 'block',
    borderRadius: 10,
    boxShadow: '0 0 10px rgb(0 0 0 / 20%)'
  }
}))(Box);

export const TitleBoxWrapper = withStyles(() => ({
  root: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 5px',
    borderBottom: '1px solid rgba(0,0,0,.2)',
    '& h2': {
      padding: '0px 15px'
    }
  }
}))(Box);

export const BodyBoxWrapper = withStyles(() => ({
  root: {
    padding: 20
  }
}))(Box);
