import React, { useState } from 'react';
import { EyeIcon, XIcon } from '../../../components/Icons';
import { CustomInput2 } from '../../../components/Inputs';
import { toast } from 'react-toastify';
import {
  updateUserData,
  updateUserProfileImage
} from '../../../redux/actions/userActions';
import { connect } from 'react-redux';

const Password = props => {
  const [values, setValues] = useState({
    old_password: '',
    new_password: '',
    confirm_password: ''
  });
  const [passwords, setPassords] = useState({
    show_old: false,
    show_new: false,
    show_confirm: false
  });

  const closeModal = prop => event => {
    props.closeModal(prop);
  };

  const handleSubmit = e => {
    e.preventDefault();

    for (const property in values) {
      if (values[property].length < 8) {
        toast.warn('Šifra mora imati najmanje 8 karaktera.');
        return;
      }

      if (values.new_password !== values.confirm_password) {
        toast.warn('Šifre se ne poklapaju.');
        return;
      }
    }
    props.updateUser({ ...props.data, password: values.confirm_password });
    props.closeModal('password');
  };

  const showPassword = (e, prop) => {
    setPassords({ ...passwords, [prop]: !passwords[prop] });
  };

  const handleInput = (event, prop) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  return (
    <div
      className="modal-bg"
      style={{ display: props.modal ? 'block' : 'none' }}
    >
      <div className="modal">
        <div className="modal-header">
          <h5>Promjena šifre</h5>
          <button className="close-btn" onClick={closeModal('password')}>
            <XIcon />
          </button>
        </div>

        <div className="modal-body">
          <div className="box box-100 mb-20 eye-icon-div">
            <CustomInput2
              label="Stara šifra"
              placeholder="Upišite ovdje"
              name="old_password"
              onChange={handleInput}
              value={values.old_password}
              className="input-class"
              type={!passwords.show_old ? 'password' : 'text'}
            />
            <EyeIcon
              onClick={e => showPassword(e, 'show_old')}
              width="30"
              height="30"
              className="eye-icon"
              color={passwords.show_old ? 'gray' : 'black'}
            />
          </div>

          <div className="box box-100 mb-20 eye-icon-div">
            <CustomInput2
              label="Nova šifra"
              placeholder="Upišite ovdje"
              name="new_password"
              onChange={handleInput}
              value={values.new_password}
              className="input-class"
              type={!passwords.show_new ? 'password' : 'text'}
            />
            <EyeIcon
              onClick={e => showPassword(e, 'show_new')}
              width="30"
              height="30"
              className="eye-icon"
              color={passwords.show_new ? 'gray' : 'black'}
            />
          </div>

          <div className="box box-100 mb-20 eye-icon-div">
            <CustomInput2
              label="Potvrdite šifru"
              placeholder="Upišite ovdje"
              name="confirm_password"
              onChange={handleInput}
              value={values.confirm_password}
              className="input-class"
              type={!passwords.show_confirm ? 'password' : 'text'}
            />
            <EyeIcon
              onClick={e => showPassword(e, 'show_confirm')}
              width="30"
              height="30"
              className="eye-icon"
              color={passwords.show_confirm ? 'gray' : 'black'}
            />
          </div>

          <button
            className="all-btns blue-btn w-full upper"
            onClick={handleSubmit}
          >
            Promijenite
          </button>
        </div>
      </div>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    data: state.user
  };
}

const mapDispatchToProps = {
  updateUser: updateUserData
};

export default connect(mapStateToProps, mapDispatchToProps)(Password);
