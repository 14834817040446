import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function dashboardReducer(state = initialState.ads, action) {
  switch (action.type) {
    case types.LOAD_ALL_ADS_SUCCESS:
      return action.data;
    case types.LOAD_ALL_ADS_FAILURE:
      return {};
    default:
      return state;
  }
}
