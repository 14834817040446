import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Spinner } from '../../components/Common';
import { fetchCommonData, fetchUser } from '../../redux/v2/global/slice';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

const AuthWrapper = ({ children }) => {
  const [loaded, setLoaded] = useState(false);

  const dispatch = useDispatch();

  const groupFetch = async () => {
    const token = localStorage.getItem('Authorization');
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    // Get common data
    await dispatch(fetchCommonData());

    // Get current user
    await dispatch(fetchUser());

    setLoaded(true);
  };

  useEffect(() => {
    groupFetch();
  }, []);

  return loaded ? (
    <React.Fragment>{children}</React.Fragment>
  ) : (
    <div className="loader-wrapper">
      <Spinner />
    </div>
  );
};

export default AuthWrapper;
