import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function searchCompanies(
  state = initialState.search_companies,
  action
) {
  switch (action.type) {
    case types.LOAD_SEARCHED_COMPANIES_SUCCESS:
      return action.data;
    case types.LOAD_SEARCHED_COMPANIES_FAILURE:
      return [];
    default:
      return state;
  }
}
