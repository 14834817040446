import axios from 'axios';
import { apiBaseUrl } from '../../config';

export const login = data => axios.post(`${apiBaseUrl}/api/v2/login`, data).then(x => x.data);

export const register = data =>
  axios.post(`${apiBaseUrl}/api/v2/register`, data);

export const resetPassword = data =>
  axios.post(`${apiBaseUrl}/api/v2/reset-password`, data);

export const logout = () => axios.post(`${apiBaseUrl}/api/v2/logout`);

export const changePassword = data =>
  axios.post(`${apiBaseUrl}/api/v2/change-password`, data);

const AuthAPI = {
  login,
  register,
  resetPassword,
  logout,
  changePassword
};

export default AuthAPI;
