import { Box, withStyles } from '@material-ui/core';

export const InputCardWrapper = withStyles(() => ({
  root: {
    borderRadius: 10,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    padding: '18.5px 14px',
    position: 'relative',
    '& legend.ic-legend': {
      position: 'absolute',
      left: 10,
      top: -7,
      fontSize: 12,
      background: '#fff',
      padding: '0px 5px',
      color: 'rgba(0, 0, 0, 0.54)'
    }
  }
}))(Box);
