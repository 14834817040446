import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function dashboardReducer(
  state = initialState.dashboard,
  action
) {
  switch (action.type) {
    case types.LOAD_DASHBOARD_DATA_SUCCESS:
      return action.data;
    case types.LOAD_DASHBOARD_DATA_FAILURE:
      return {};
    default:
      return state;
  }
}
