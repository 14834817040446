import React, { useState, useEffect } from 'react';

const Calendar = props => {
  const today = new Date();
  const months = [
    'Januar',
    'Februar',
    'Mart',
    'April',
    'Maj',
    'Jun',
    'Jul',
    'Avgust',
    'Septembar',
    'Octobar',
    'Novembar',
    'Decembar'
  ];
  const days = ['Ned', 'Pon', 'Uto', 'Sre', 'Čet', 'Pet', 'Sub'];
  const [month, setMonth] = useState(new Date().getMonth());
  const [year, setYear] = useState(new Date().getFullYear());
  const [startDateSet, toggleStartDateSet] = useState(true);
  const [endDateSet, toggleEndDateSet] = useState(false);
  const [startDate, setStartDate] = useState(props.start ? props.start : today);
  const [endDate, setEndDate] = useState(props.end ? props.end : '');

  useEffect(() => {
    if (props.start) {
      setStartDate(props.start);
    }

    if (props.end) {
      setEndDate(props.end);
    }
  }, [props.start, props.end]);

  const renderDayNames = () => {
    let daysHeader = [];
    days.forEach((e, index) => {
      daysHeader.push(
        <th data-days={e} key={index}>
          <span>{e}</span>
        </th>
      );
    });
    return daysHeader;
  };

  const setPreviousMonth = () => {
    if (month === 0) {
      setMonth(11);
      setYear(year - 1);
    } else {
      setMonth(month - 1);
    }
  };

  const setNextMonth = () => {
    if (month === 11) {
      setMonth(0);
      setYear(year + 1);
    } else {
      setMonth(month + 1);
    }
  };

  const handleSelect = (day, month, year) => {
    const startDateTime = new Date(startDate).getTime();
    const pickDateTime = new Date(year, month, day).getTime();

    if (startDateSet && !endDateSet) {
      if (startDateTime < pickDateTime) {
        toggleEndDateSet(true);
        setEndDate(new Date(year, month, day));
        props.setDate(startDate, new Date(year, month, day));
      } else {
        setStartDate(new Date(year, month, day));
        setEndDate(null);
        toggleEndDateSet(false);
      }
    } else {
      toggleStartDateSet(true);
      toggleEndDateSet(false);
      setStartDate(new Date(year, month, day));
      setEndDate(null);
    }
  };

  const getClass = (year, month, day) => {
    let classNames = '';
    const time = new Date(year, month, day).getTime();

    if (
      new Date(startDate).getFullYear() === year &&
      new Date(startDate).getMonth() === month &&
      new Date(startDate).getDate() === day
    ) {
      classNames = classNames + ' selected selected-start';
    }
    if (
      endDate &&
      new Date(endDate).getFullYear() === year &&
      new Date(endDate).getMonth() === month &&
      new Date(endDate).getDate() === day
    ) {
      classNames = classNames + ' selected selected-end';
    }
    if (
      endDate &&
      new Date(startDate).getTime() < time &&
      new Date(endDate).getTime() > time
    ) {
      classNames = classNames + ' selected';
    }
    return classNames;
  };

  const renderDays = () => {
    const firstDayInMonthIndex = new Date(year, month, 1).getDay();
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    let days = [];
    let weeks = [];
    if (firstDayInMonthIndex > 0) {
      for (let i = 0; i < firstDayInMonthIndex; i++) {
        days.push(<td key={`pre-${i}`}></td>);
      }
    }
    for (let i = 1; i <= daysInMonth; i++) {
      days.push(
        <td
          key={`day-${i}`}
          data-day={i}
          data-month={month}
          data-year={year}
          data-month-name={months[month]}
          className={`date-picker ${getClass(year, month, i)}`}
          onClick={e => handleSelect(i, month, year)}
        >
          <span>{i}</span>
        </td>
      );
    }
    for (let i = 0; i < 7 - ((firstDayInMonthIndex + daysInMonth) % 7); i++) {
      days.push(<td key={`pre-${i}`}></td>);
    }
    for (let i = 0; i < days.length / 7; i++) {
      weeks.push(<tr key={i}>{days.slice(i * 7, (i + 1) * 7)}</tr>);
    }
    return weeks;
  };

  return (
    <div className="box calendar-box-30">
      <p>
        <strong>Izaberite period trajanja oglasa</strong> <span>*</span>
      </p>
      <div className="container-calendar">
        <div className="calendar-header">
          <h3>
            {months[month]}/{year}
          </h3>
          <div className="button-container-calendar">
            <button id="previous" onClick={setPreviousMonth}>
              <svg viewBox="0 0 320 512">
                <path
                  fill="currentColor"
                  d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"
                ></path>
              </svg>
            </button>
            <button id="next" onClick={setNextMonth}>
              <svg viewBox="0 0 320 512">
                <path
                  fill="currentColor"
                  d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                ></path>
              </svg>
            </button>
          </div>
        </div>

        <table className="table-calendar" id="calendar" data-lang="en">
          <thead id="thead-month">
            <tr>{renderDayNames()}</tr>
          </thead>
          <tbody id="calendar-body">{renderDays()}</tbody>
        </table>
      </div>
    </div>
  );
};
export default Calendar;
