export const UPDATE_COMPANY_REQUEST = 'UPDATE_COMPANY_REQUEST';
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';
export const UPDATE_COMPANY_FAILURE = 'UPDATE_COMPANY_FAILURE';

export const UPDATE_EMINENT_REQUEST = 'UPDATE_EMINENT_REQUEST';
export const UPDATE_EMINENT_SUCCESS = 'UPDATE_EMINENT_SUCCESS';
export const UPDATE_EMINENT_FAILURE = 'UPDATE_EMINENT_FAILURE';

export const UPDATE_LICENCE_REQUEST = 'UPDATE_LICENCE_REQUEST';
export const UPDATE_LICENCE_SUCCESS = 'UPDATE_LICENCE_SUCCESS';
export const UPDATE_LICENCE_FAILURE = 'UPDATE_LICENCE_FAILURE';

export const CREATE_AD_REQUEST = 'CREATE_AD_REQUEST';
export const CREATE_AD_SUCCESS = 'CREATE_AD_SUCCESS';
export const CREATE_AD_FAILURE = 'CREATE_AD_FAILURE';

export const UPDATE_OPTIONAL_FIELDS_REQUEST = 'UPDATE_OPTIONAL_FIELDS_REQUEST';
export const UPDATE_OPTIONAL_FIELDS_SUCCESS = 'UPDATE_OPTIONAL_FIELDS_SUCCESS';
export const UPDATE_OPTIONAL_FIELDS_FAILURE = 'UPDATE_OPTIONAL_FIELDS_FAILURE';

export const LOAD_USER_REQUEST = 'LOAD_USER_REQUEST';
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';
export const LOAD_USER_FAILURE = 'LOAD_USER_FAILURE';

export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';

export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';

export const LOAD_NOTIFICATIONS_REQUEST = 'LOAD_NOTIFICATIONS_REQUEST';
export const LOAD_NOTIFICATIONS_SUCCESS = 'LOAD_NOTIFICATIONS_SUCCESS';
export const LOAD_NOTIFICATIONS_FAILURE = 'LOAD_NOTIFICATIONS_FAILURE';

export const LOAD_SINGLE_MESSAGE_REQUEST = 'LOAD_SINGLE_MESSAGE_REQUEST';
export const LOAD_SINGLE_MESSAGE_SUCCESS = 'LOAD_SINGLE_MESSAGE_SUCCESS';
export const LOAD_SINGLE_MESSAGE_FAILURE = 'LOAD_SINGLE_MESSAGE_FAILURE';

export const LOAD_DASHBOARD_DATA_REQUEST = 'LOAD_DASHBOARD_DATA_REQUEST';
export const LOAD_DASHBOARD_DATA_SUCCESS = 'LOAD_DASHBOARD_DATA_SUCCESS';
export const LOAD_DASHBOARD_DATA_FAILURE = 'LOAD_DASHBOARD_DATA_FAILURE';

export const LOAD_SINGLE_USER_DATA_REQUEST = 'LOAD_SINGLE_USER_DATA_REQUEST';
export const LOAD_SINGLE_USER_DATA_SUCCESS = 'LOAD_SINGLE_USER_DATA_SUCCESS';
export const LOAD_SINGLE_USER_DATA_FAILURE = 'LOAD_SINGLE_USER_DATA_FAILURE';

export const LOAD_ALL_USERS_DATA_REQUEST = 'LOAD_ALL_USERS_DATA_REQUEST';
export const LOAD_ALL_USERS_DATA_SUCCESS = 'LOAD_ALL_USERS_DATA_SUCCESS';
export const LOAD_ALL_USERS_DATA_FAILURE = 'LOAD_ALL_USERS_DATA_FAILURE';

export const VIEW_SINGLE_MESSAGE_REQUEST = 'VIEW_SINGLE_MESSAGE_REQUEST';
export const VIEW_SINGLE_MESSAGE_SUCCESS = 'VIEW_SINGLE_MESSAGE_SUCCESS';
export const VIEW_SINGLE_MESSAGE_FAILURE = 'VIEW_SINGLE_MESSAGE_FAILURE';

export const SEND_SINGLE_MESSAGE_REQUEST = 'SEND_SINGLE_MESSAGE_REQUEST';
export const SEND_SINGLE_MESSAGE_SUCCESS = 'SEND_SINGLE_MESSAGE_SUCCESS';
export const SEND_SINGLE_MESSAGE_FAILURE = 'SENDW_SINGLE_MESSAGE_FAILURE';

export const VIEW_NOTIFICATION_REQUEST = 'VIEW_NOTIFICATION_REQUEST';
export const VIEW_NOTIFICATION_SUCCESS = 'VIEW_NOTIFICATION_SUCCESS';
export const VIEW_NOTIFICATION_FAILURE = 'VIEW_NOTIFICATION_FAILURE';

export const LOAD_MESSAGES_REQUEST = 'LOAD_MESSAGES_REQUEST';
export const LOAD_MESSAGES_SUCCESS = 'LOAD_MESSAGES_SUCCESS';
export const LOAD_MESSAGES_FAILURE = 'LOAD_MESSAGES_FAILURE';

export const LOAD_CONVERSATION_REQUEST = 'LOAD_CONVERSATION_REQUEST';
export const LOAD_CONVERSATION_SUCCESS = 'LOAD_CONVERSATION_SUCCESS';
export const LOAD_CONVERSATION_FAILURE = 'LOAD_CONVERSATION_FAILURE';

export const LOAD_ALL_ADS_REQUEST = 'LOAD_ALL_ADS_REQUEST';
export const LOAD_ALL_ADS_SUCCESS = 'LOAD_ALL_ADS_SUCCESS';
export const LOAD_ALL_ADS_FAILURE = 'LOAD_ALL_ADS_FAILURE';

export const LOAD_ALL_COUNTRIES_REQUEST = 'LOAD_ALL_COUNTRIES_REQUEST';
export const LOAD_ALL_COUNTRIES_SUCCESS = 'LOAD_ALL_COUNTRIES_SUCCESS';
export const LOAD_ALL_COUNTRIES_FAILURE = 'LOAD_ALL_COUNTRIES_FAILURE';

export const LOAD_ALL_CITIES_REQUEST = 'LOAD_ALL_CITIES_REQUEST';
export const LOAD_ALL_CITIES_SUCCESS = 'LOAD_ALL_CITIES_SUCCESS';
export const LOAD_ALL_CITIES_FAILURE = 'LOAD_ALL_CITIES_FAILURE';

export const LOAD_ALL_ADDINGS_REQUEST = 'LOAD_ALL_ADDINGS_REQUEST';
export const LOAD_ALL_ADDINGS_SUCCESS = 'LOAD_ALL_ADDINGS_SUCCESS';
export const LOAD_ALL_ADDINGS_FAILURE = 'LOAD_ALL_ADDINGS_FAILURE';

export const LOAD_ACTIVE_CANDIDATES_REQUEST = 'LOAD_ACTIVE_CANDIDATES_REQUEST';
export const LOAD_ACTIVE_CANDIDATES_SUCCESS = 'LOAD_ACTIVE_CANDIDATES_SUCCESS';
export const LOAD_ACTIVE_CANDIDATES_FAILURE = 'LOAD_ACTIVE_CANDIDATES_FAILURE';

export const LOAD_ARCHIVED_CANDIDATES_REQUEST =
  'LOAD_ARCHIVED_CANDIDATES_REQUEST';
export const LOAD_ARCHIVED_CANDIDATES_SUCCESS =
  'LOAD_ARCHIVED_CANDIDATES_SUCCESS';
export const LOAD_ARCHIVED_CANDIDATES_FAILURE =
  'LOAD_ARCHIVED_CANDIDATES_FAILURE';

export const LOAD_ACTIVE_USERS_REQUEST = 'LOAD_ACTIVE_USERS_REQUEST';
export const LOAD_ACTIVE_USERS_SUCCESS = 'LOAD_ACTIVE_USERS_SUCCESS';
export const LOAD_ACTIVE_USERS_FAILURE = 'LOAD_ACTIVE_USERS_FAILURE';

export const LOAD_ARCHIVED_USERS_REQUEST = 'LOAD_ARCHIVED_USERS_REQUEST';
export const LOAD_ARCHIVED_USERS_SUCCESS = 'LOAD_ARCHIVED_USERS_SUCCESS';
export const LOAD_ARCHIVED_USERS_FAILURE = 'LOAD_ARCHIVED_USERS_FAILURE';

export const LOAD_ACTIVE_COMPANIES_REQUEST = 'LOAD_ACTIVE_COMPANIES_REQUEST';
export const LOAD_ACTIVE_COMPANIES_SUCCESS = 'LOAD_ACTIVE_COMPANIES_SUCCESS';
export const LOAD_ACTIVE_COMPANIES_FAILURE = 'LOAD_ACTIVE_COMPANIES_FAILURE';

export const LOAD_ARCHIVED_COMPANIES_REQUEST =
  'LOAD_ARCHIVED_COMPANIES_REQUEST';
export const LOAD_ARCHIVED_COMPANIES_SUCCESS =
  'LOAD_ARCHIVED_COMPANIES_SUCCESS';
export const LOAD_ARCHIVED_COMPANIES_FAILURE =
  'LOAD_ARCHIVED_COMPANIES_FAILURE';

export const LOAD_ACTIVE_ADS_REQUEST = 'LOAD_ACTIVE_ADS_REQUEST';
export const LOAD_ACTIVE_ADS_SUCCESS = 'LOAD_ACTIVE_ADS_SUCCESS';
export const LOAD_ACTIVE_ADS_FAILURE = 'LOAD_ACTIVE_ADS_FAILURE';

export const LOAD_ARCHIVED_ADS_REQUEST = 'LOAD_ARCHIVED_ADS_REQUEST';
export const LOAD_ARCHIVED_ADS_SUCCESS = 'LOAD_ARCHIVED_ADS_SUCCESS';
export const LOAD_ARCHIVED_ADS_FAILURE = 'LOAD_ARCHIVED_ADS_FAILURE';

export const LOAD_SINGLE_AD_REQUEST = 'LOAD_SINGLE_AD_REQUEST';
export const LOAD_SINGLE_AD_SUCCESS = 'LOAD_SINGLE_AD_SUCCESS';
export const LOAD_SINGLE_AD_FAILURE = 'LOAD_SINGLE_AD_FAILURE';

export const LOAD_SEARCH_CITIES_REQUEST = 'LOAD_SEARCH_CITIES_REQUEST';
export const LOAD_SEARCH_CITIES_SUCCESS = 'LOAD_SEARCH_CITIES_SUCCESS';
export const LOAD_SEARCH_CITIES_FAILURE = 'LOAD_SEARCH_CITIES_FAILURE';

export const SEND_GROUP_MESSAGE_REQUEST = 'SEND_GROUP_MESSAGE_REQUEST';
export const SEND_GROUP_MESSAGE_SUCCESS = 'SEND_GROUP_MESSAGE_SUCCESS';
export const SEND_GROUP_MESSAGE_FAILURE = 'SEND_GROUP_MESSAGE_FAILURE';

export const ARCHIVE_MULTIPLE_USERS_REQUEST = 'ARCHIVE_MULTIPLE_USERS_REQUEST';
export const ARCHIVE_MULTIPLE_USERS_SUCCESS = 'ARCHIVE_MULTIPLE_USERS_SUCCESS';
export const ARCHIVE_MULTIPLE_USERS_FAILURE = 'ARCHIVE_MULTIPLE_USERS_FAILURE';

export const DEACTIVATE_USER_REQUEST = 'DEACTIVATE_USER_REQUEST';
export const DEACTIVATE_USER_SUCCESS = 'DEACTIVATE_USER_SUCCESS';
export const DEACTIVATE_USER_FAILURE = 'DEACTIVATE_USER_FAILURE';

export const LOAD_SEARCHED_COMPANIES_REQUEST =
  'LOAD_SEARCHED_COMPANIES_REQUEST';
export const LOAD_SEARCHED_COMPANIES_SUCCESS =
  'LOAD_SEARCHED_COMPANIES_SUCCESS';
export const LOAD_SEARCHED_COMPANIES_FAILURE =
  'LOAD_SEARCHED_COMPANIES_FAILURE';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const UPDATE_PROFILE_IMAGE_REQUEST = 'UPDATE_PROFILE_IMAGE_REQUEST';
export const UPDATE_PROFILE_IMAGE_SUCCESS = 'UPDATE_PROFILE_IMAGE_SUCCESS';
export const UPDATE_PROFILE_IMAGE_FAILURE = 'UPDATE_PROFILE_IMAGE_FAILURE';

export const ARCHIVE_AD_REQUEST = 'ARCHIVE_AD_REQUEST';
export const ARCHIVE_AD_SUCCESS = 'ARCHIVE_AD_SUCCESS';
export const ARCHIVE_AD_FAILURE = 'ARCHIVE_AD_FAILURE';

export const UPDATE_USER_PROFILE_IMAGE_REQUEST =
  'UPDATE_USER_PROFILE_IMAGE_REQUEST';
export const UPDATE_USER_PROFILE_IMAGE_SUCCESS =
  'UPDATE_USER_PROFILE_IMAGE_SUCCESS';
export const UPDATE_USER_PROFILE_IMAGE_FAILURE =
  'UPDATE_USER_PROFILE_IMAGE_FAILURE';

export const UPDATE_USER_DATA_REQUEST = 'UPDATE_USER_DATA_REQUEST';
export const UPDATE_USER_DATA_SUCCESS = 'UPDATE_USER_DATA_SUCCESS';
export const UPDATE_USER_DATA_FAILURE = 'UPDATE_USER_DATA_FAILURE';

export const CREATE_USER_EDUCATION_REQUEST = 'CREATE_USER_EDUCATION_REQUEST';
export const CREATE_USER_EDUCATION_SUCCESS = 'CREATE_USER_EDUCATION_SUCCESS';
export const CREATE_USER_EDUCATION_FAILURE = 'CREATE_USER_EDUCATION_FAILURE';

export const DELETE_USER_EDUCATION_REQUEST = 'DELETE_USER_EDUCATION_REQUEST';
export const DELETE_USER_EDUCATION_SUCCESS = 'DELETE_USER_EDUCATION_SUCCESS';
export const DELETE_USER_EDUCATION_FAILURE = 'DELETE_USER_EDUCATION_FAILURE';

export const UPDATE_USER_EDUCATION_REQUEST = 'UPDATE_USER_EDUCATION_REQUEST';
export const UPDATE_USER_EDUCATION_SUCCESS = 'UPDATE_USER_EDUCATION_SUCCESS';
export const UPDATE_USER_EDUCATION_FAILURE = 'UPDATE_USER_EDUCATION_FAILURE';

export const CREATE_USER_EXPERIENCE_REQUEST = 'CREATE_USER_EXPERIENCE_REQUEST';
export const CREATE_USER_EXPERIENCE_SUCCESS = 'CREATE_USER_EXPERIENCE_SUCCESS';
export const CREATE_USER_EXPERIENCE_FAILURE = 'CREATE_USER_EXPERIENCE_FAILURE';

export const DELETE_USER_EXPERIENCE_REQUEST = 'DELETE_USER_EXPERIENCE_REQUEST';
export const DELETE_USER_EXPERIENCE_SUCCESS = 'DELETE_USER_EXPERIENCE_SUCCESS';
export const DELETE_USER_EXPERIENCE_FAILURE = 'DELETE_USER_EXPERIENCE_FAILURE';

export const UPDATE_USER_EXPERIENCE_REQUEST = 'UPDATE_USER_EXPERIENCE_REQUEST';
export const UPDATE_USER_EXPERIENCE_SUCCESS = 'UPDATE_USER_EXPERIENCE_SUCCESS';
export const UPDATE_USER_EXPERIENCE_FAILURE = 'UPDATE_USER_EXPERIENCE_FAILURE';

export const CREATE_COMPUTER_SKILL_REQUEST = 'CREATE_COMPUTER_SKILL_REQUEST';
export const CREATE_COMPUTER_SKILL_SUCCESS = 'CREATE_COMPUTER_SKILL_SUCCESS';
export const CREATE_COMPUTER_SKILL_FAILURE = 'CREATE_COMPUTER_SKILL_FAILURE';

export const DELETE_COMPUTER_SKILL_REQUEST = 'DELETE_COMPUTER_SKILL_REQUEST';
export const DELETE_COMPUTER_SKILL_SUCCESS = 'DELETE_COMPUTER_SKILL_SUCCESS';
export const DELETE_COMPUTER_SKILL_FAILURE = 'DELETE_COMPUTER_SKILL_FAILURE';

export const UPDATE_COMPUTER_SKILL_REQUEST = 'UPDATE_COMPUTER_SKILL_REQUEST';
export const UPDATE_COMPUTER_SKILL_SUCCESS = 'UPDATE_COMPUTER_SKILL_SUCCESS';
export const UPDATE_COMPUTER_SKILL_FAILURE = 'UPDATE_COMPUTER_SKILL_FAILURE';
