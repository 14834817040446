import { withStyles } from '@material-ui/core';
import { Pagination as MuiPagination } from '@material-ui/lab';

export const PaginationWrapper = withStyles(({ theme }) => ({
  root: {
    display: 'block',
    width: 'auto',
    '& li:not(:last-child)': {
      marginRight: 10
    },
    '& .MuiButtonBase-root': {
      fontSize: 16,
      padding: 0,
      width: 40,
      height: 40,
      margin: 0,
      borderRadius: 10,
      border: 'solid 1px rgba(0, 0, 0, 0.2)',
      display: 'flex',
      '&.MuiPaginationItem-page': {
        color: '#6b6b6b',
        '&.Mui-selected': {
          fontWeight: 600,
          color: '#2897fb',
        }
      },
      '& .MuiSvgIcon-root': {
        width: 32,
        height: 32
      }
    }
  }
}))(MuiPagination);
