import { TextField, withStyles } from '@material-ui/core';

export const InputWrapper = withStyles(() => ({
  root: {
    width: '100%',
    fontSize: 16,
    borderRadius: 10,
    '& .MuiOutlinedInput-root': {
      borderRadius: 10
    }
  }
}))(TextField);
