import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function addingsReducer(state = initialState.addings, action) {
  switch (action.type) {
    case types.LOAD_ALL_ADDINGS_SUCCESS:
      return action.data;
    case types.LOAD_ALL_ADDINGS_FAILURE:
      return {};
    default:
      return state;
  }
}
