import React, { useState, useEffect } from 'react';
import { CustomInput2, CustomDropdown } from '../../../components/Inputs';
import {
  getAllCities,
  getAllCountries
} from '../../../redux/actions/countryActions';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Spinner } from '../../../components/Common';
import {
  UploadCloudIcon,
  PenIcon,
  DropdownIcon2,
  SaveIcon,
  XIcon
} from '../../../components/Icons';
import { apiBaseUrl, imagePlaceholder } from '../../../api/config';
import Confirmation from '../modals/confirmation';
import { loadUser, updateUserData } from '../../../redux/actions/userActions';
import { Title } from '../../../components/Title';

const EditProfile = ({
  getAllCountries,
  getAllCities,
  match,
  cities,
  countries,
  getUser,
  user,
  updateUser,
  ...props
}) => {
  const [userProfile, setUserProfile] = useState({});
  const [modals, setModals] = useState({
    confirmation: false
  });
  const [locations, setLocations] = useState({ cities: [], countries: [] });

  useEffect(() => {
    setUserProfile(user);
  }, []);

  const showModal = prop => event => {
    setModals({ ...modals, [prop]: true });
  };

  const closeModal = (prop, answer) => {
    setModals({ ...modals, [prop]: false });

    if (answer) {
      console.log('true');
    }

    if (answer && prop === 'confirmation') {
      history.goBack();
    }
  };

  let params = match.params;
  let history = useHistory();

  useEffect(() => {
    getAllCountries();
    setUserProfile(user);
    if (user) {
      getAllCities({ id: user.country_id });
      setLocations({ countries: countries, cities: cities });
    }
  }, [params]);

  const handleInput = (e, name) => {
    setUserProfile({ ...userProfile, [name]: e.target.value });
  };

  const handleDropdown = (e, name) => {
    let value = parseInt(e.target.value);

    if (name === 'country_id') {
      console.log('tu sam');
      getAllCities({ id: value });
      setLocations({ ...locations, cities: cities });
    }

    setUserProfile({ ...userProfile, [name]: e.target.value });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    const values = {
      full_name: userProfile.full_name,
      company_description: userProfile.company_description,
      company_activity: userProfile.company_activity,
      contact_person: userProfile.contact_person,
      contact_person_position: userProfile.contact_person_position,
      contact_phone: userProfile.phone,
      contact_mail: userProfile.email,
      website: userProfile.web_address,
      instagram: userProfile.instagram,
      facebook: userProfile.facebook,
      country_id: userProfile.country_id,
      city_id: userProfile.city_id,
      address: userProfile.address,
      pib: userProfile.pib,
      pdv: userProfile.pdv
    };

    await updateUser({ ...values });
    await getUser();
    history.goBack();
  };

  return userProfile?.full_name !== null ? (
    <main id="mainContent">
      <Title title="Izmena profila"/>
      <div id="close-area"></div>
      <header>
        <div className="title-box">
          <h1>Izmjeni profil</h1>
          <p>Sve informacije o profilu kompanije</p>
        </div>
        {/*<div className="btn-wrapper">
            <button className="all-btns blue-btn  btn-order-sm-last">
              Galerija kompanije
            </button>
            <button className="all-btns blue-btn btn-order-sm-last">
              Blog kompanije
            </button>
    </div>*/}
      </header>

      <div className="container-flex">
        <div className="container-half bs">
          <div className="container-header">
            <h2>Osnovne informacije</h2>
          </div>

          <div className="container-body">
            <div className="input-wrapper wrapper-w-100">
              <CustomInput2
                label="Naziv kompanije"
                placeholder="Upišite ovdje"
                name="full_name"
                onChange={handleInput}
                value={userProfile.full_name}
                className="input-class"
                req={true}
              />
            </div>

            <div className="input-wrapper wrapper-w-100">
              <CustomInput2
                label="Djelatnost kompanije"
                placeholder="Upišite ovdje"
                name="company_activity"
                onChange={handleInput}
                value={userProfile.company_activity}
                className="input-class"
                req={true}
              />
            </div>

            <div className="input-wrapper wrapper-w-100">
              <p>
                <strong>Opis kompanije</strong> <span>*</span>
              </p>

              <div
                contentEditable="true"
                dangerouslySetInnerHTML={{
                  __html: `<p> ${
                    userProfile.company_description
                      ? userProfile.company_description
                      : '  '
                  } </p>`
                }}
                onChange={e => handleInput(e, 'company_description')}
                name="company_description"
                value={userProfile.company_description}
                className="input-class div-new-company"
              />
            </div>
            <div
              className={`input-wrapper wrapper-w-50  ${
                userProfile.profile_image ? ' uploaded ' : ' '
              }`}
            >
              <p>
                <strong>Logotip kompanije</strong>
              </p>
              <input type="file" id="uploadLogo" style={{ display: 'none' }} />
              <button id="uploadLogo" className="all-btns blue-btn upper">
                <UploadCloudIcon />
                Dodajte
              </button>
              <div className="preview-logo-box">
                <img
                  src={
                    userProfile.profile_image
                      ? userProfile.profile_image
                      : imagePlaceholder
                  }
                  alt="company logo"
                />
                <button id="editLogo">
                  <PenIcon />
                </button>
              </div>

              <div className="about-company-more-details mt-20">
                <div className="employee-numbers">
                  <CustomInput2
                    label="Broj zaposlenih"
                    placeholder="0"
                    name="number_of_employees_from"
                    onChange={handleInput}
                    value={userProfile.number_of_employees_from}
                    className="input-class  "
                    type="number"
                  />

                  <CustomInput2
                    label="  -  "
                    placeholder="0"
                    name="number_of_employees_to"
                    onChange={handleInput}
                    value={userProfile.number_of_employees_to}
                    className="input-class "
                    type="number"
                    min={userProfile.number_of_employees_from}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* kontakt informacije */}
        <div className="container-half bs">
          <div className="container-header">
            <h2>Kontakt informacije</h2>
          </div>

          <div className="container-body">
            <div className="input-wrapper wrapper-w-50">
              <CustomInput2
                label="Kontakt osoba"
                placeholder="Upišite ovdje"
                name="company_users[0].contact_person"
                onChange={handleInput}
                value={
                  userProfile.company_users && userProfile.company_users[0]
                    ? userProfile.company_users[0].contact_person
                    : ''
                }
                className="input-class"
                req={true}
              />
            </div>

            <div className="input-wrapper wrapper-w-50">
              <CustomInput2
                label="Pozicija kontakt osobe"
                placeholder="Upišite ovdje"
                name="company_users[0].contact_person_position"
                onChange={handleInput}
                value={
                  userProfile.company_users && userProfile.company_users[0]
                    ? userProfile.company_users[0].contact_person_position
                    : ''
                }
                className="input-class"
                req={true}
              />
            </div>

            <div className="input-wrapper wrapper-w-50">
              <CustomInput2
                label="Kontakt telefon"
                placeholder="Upišite ovdje"
                name="phone"
                onChange={handleInput}
                value={
                  userProfile.phone}
                className="input-class"
                req={true}
              />
            </div>

            <div className="input-wrapper wrapper-w-50">
              <CustomInput2
                label="Kontakt email"
                placeholder="Upišite ovdje"
                name="email"
                onChange={handleInput}
                value={
                  userProfile.email}
                className="input-class"
                req={true}
              />
            </div>

            <div className="input-wrapper wrapper-w-50">
              <CustomInput2
                label="Web adresa"
                placeholder="Upišite ovdje"
                name="website"
                onChange={handleInput}
                value={userProfile.website}
                className="input-class"
                req={true}
              />
            </div>

            <div className="input-wrapper wrapper-w-50">
              <CustomInput2
                label="Facebook profil"
                placeholder="Upišite ovdje"
                name="facebook"
                onChange={handleInput}
                value={userProfile.facebook}
                className="input-class"
                req={true}
              />
            </div>

            <div className="input-wrapper wrapper-w-50">
              <CustomInput2
                label="Instagram profil"
                placeholder="Upišite ovdje"
                name="instagram"
                onChange={handleInput}
                value={userProfile.instagram}
                className="input-class"
                req={true}
              />
            </div>

            <div className="input-wrapper wrapper-w-50">
              <p>
                <strong>Država</strong> <span>*</span>
              </p>
              <div className="select-wrapper">
                <CustomDropdown
                  className="select"
                  name="country_id"
                  children={locations.countries}
                  onChange={handleDropdown}
                  value={userProfile.country_id}
                />
                <DropdownIcon2 className="select-arrow" />
              </div>
            </div>

            <div className="input-wrapper wrapper-w-50">
              <p>
                <strong>Grad</strong> <span>*</span>
              </p>
              <div className="select-wrapper">
                <CustomDropdown
                  className="select"
                  name="city_id"
                  children={locations.cities}
                  onChange={handleDropdown}
                  value={userProfile.city_id}
                />
                <DropdownIcon2 className="select-arrow" />
              </div>
            </div>

            <div className="input-wrapper wrapper-w-50">
              <CustomInput2
                label="Adresa kompanije"
                placeholder="Upišite ovdje"
                name="address"
                onChange={handleInput}
                value={userProfile.address}
                className="input-class"
              />
            </div>

            <div className="input-wrapper wrapper-w-50">
              <CustomInput2
                label="PIB"
                placeholder="Upišite ovdje"
                name="pib"
                onChange={handleInput}
                value={userProfile.pib}
                className="input-class"
                req={true}
              />
            </div>

            <div className="input-wrapper wrapper-w-50">
              <CustomInput2
                label="PDV"
                placeholder="Upišite ovdje"
                name="pdv"
                onChange={handleInput}
                value={userProfile.pdv}
                className="input-class"
                req={true}
              />
            </div>
          </div>
        </div>

        {/*Paketi */}
        <div className="container-half current-package">
          {/*<div className="text-wrapper">
                <p>Trenutni paket profila:</p>
                <h2>BESPLATNI PAKET</h2>
              </div>
              <button
                className="all-btns blue-btn upper"
                onClick={(e) => this.goTo(e, "/package")}
              >
                <PackageIcon />
                Izmjeni paket
                </button>*/}
        </div>

        {/* del/save */}
        <div className="container-half btns-container">
          <button
            className="all-btns gray-btn upper"
            onClick={showModal('confirmation')}
          >
            <XIcon />
            Otkažite
          </button>
          <button className="all-btns blue-btn upper" onClick={handleSubmit}>
            <SaveIcon />
            Sačuvajte
          </button>
        </div>
      </div>

      <Confirmation
        name="confirmation"
        modal={modals.confirmation}
        closeModal={closeModal}
        title="OTKAŽITE"
        text="Da li ste sigurni da želite da otkažete izmjene?"
      />
    </main>
  ) : (
    <main id="mainContent">
      <div className="spinner-container" style={{ marginTop: '40%' }}>
        <Spinner />
      </div>
    </main>
  );
};

function mapStateToProps(state) {
  return {
    countries: state.countries,
    cities: state.cities,
    user: state.user ? state.user : null
  };
}

const mapDispatchToProps = {
  getAllCountries: getAllCountries,
  getAllCities: getAllCities,
  getUser: loadUser,
  updateUser: updateUserData
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
