import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function messagesReducer(state = initialState.messages, action) {
  switch (action.type) {
    case types.LOAD_MESSAGES_SUCCESS:
      return action.data;
    case types.LOAD_MESSAGES_FAILURE:
      return {};
    default:
      return state;
  }
}
