import React from 'react';
import { DatePickerWrapper } from './styles';

const DatePicker = ({ ...props }) => {
  return (
    <DatePickerWrapper
      variant="inline"
      inputVariant="outlined"
      format="DD/MM/YYYY"
      {...props}
    />
  );
};

export default DatePicker;
