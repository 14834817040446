import { IconButton, Modal as MuiModal } from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import React from 'react';
import { BodyBoxWrapper, BoxWrapper, TitleBoxWrapper } from './styles';

const muiModalStyle = {
    position: 'fixed',
    zIndex: 1300,
    right: 0,
    bottom: 0,
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}

const Modal = ({ title, open, onClose, children, size }) => {
    return (
      <MuiModal open={open} onClose={onClose} style={muiModalStyle}>
        <BoxWrapper size={size}>
          <TitleBoxWrapper>
            <h2>{ title }</h2>
            <IconButton onClick={onClose}>
              <Cancel fontSize="large" />
            </IconButton>
          </TitleBoxWrapper>
          <BodyBoxWrapper>{children}</BodyBoxWrapper>
        </BoxWrapper>
      </MuiModal>
    );
}

export default Modal;