import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import {
  DashboardIcon,
  PersonTagIcon,
  BriefcaseIcon,
  MegaphoneIcon,
  SettingsIcon,
  ProfileIcon,
  LogoutIcon
} from './Icons';
import localizedStrings from '../utils/localizedStrings';
import { ImageAvatar } from './Common';
import { useDispatch } from 'react-redux';
import { globalActions } from '../redux/v2/global';




const Navigation = ({ mobileMenu }) => {

  const dispatch = useDispatch();
  const history = useHistory();

  const logout = () => {
    dispatch(globalActions.logout());
    history.push('/login')
  }

  return (
    <div className="nav-admin-wrapper">
      <nav
        className={
          mobileMenu ? 'nav-items xl-resize open-menu' : 'nav-items xl-resize'
        }
      >
        <a href="/" className="profile-mobile">
          <ImageAvatar />
          <p>{'TEST'}</p>
        </a>
        <NavLink exact to="/">
          <DashboardIcon />
          <p>{localizedStrings.navigation.dashboard}</p>
        </NavLink>

        <details>
          <summary>
            <ProfileIcon />
            <p>{localizedStrings.navigation.users}</p>
          </summary>
          <div className="sublist">
            <NavLink to="/active-users" className="submenu">
              {localizedStrings.navigation.active_users}
            </NavLink>
            <NavLink to="/archived-users" className="submenu">
              {localizedStrings.navigation.archived_users}
            </NavLink>
          </div>
        </details>

        <details>
          <summary>
            <BriefcaseIcon />
            <p>{localizedStrings.navigation.companies}</p>
          </summary>
          <div className="sublist">
            <NavLink to="/active-companies" className="submenu">
              {localizedStrings.navigation.active_companies}
            </NavLink>
            <NavLink to="/archived-companies" className="submenu">
              {localizedStrings.navigation.archived_companies}
            </NavLink>
          </div>
        </details>

        <details>
          <summary>
            <MegaphoneIcon />
            <p>{localizedStrings.navigation.ads}</p>
          </summary>
          <div className="sublist">
            <NavLink to="/create-ad" className="submenu">
              {localizedStrings.navigation.create_ad}
            </NavLink>
            <NavLink to="/active-ads" className="submenu">
              {localizedStrings.navigation.active_ads}
            </NavLink>
            <NavLink to="/archived-ads" className="submenu">
              {localizedStrings.navigation.archived_ads}
            </NavLink>
          </div>
        </details>

        <details>
          <summary>
            <PersonTagIcon />
            <p>{localizedStrings.navigation.candidates}</p>
          </summary>
          <div className="sublist">
            <NavLink to="/active-candidates" className="submenu">
              {localizedStrings.navigation.active_candidates}
            </NavLink>
            <NavLink to="/archived-candidates" className="submenu">
              {localizedStrings.navigation.archived_candidates}
            </NavLink>
          </div>
        </details>
        {/* <NavLink to="/profile">
        <ProfileIcon />
        <p>{localizedStrings.navigation.profile}</p>
      </NavLink> */}
        <NavLink to="/settings">
          <SettingsIcon />
          <p>{localizedStrings.navigation.settings}</p>
        </NavLink>
        <a className="logout" href="/" onClick={logout}>
          <LogoutIcon />
          <p>Odjavi se</p>
        </a>
      </nav>
    </div>
  );
};

export default Navigation;
