import React, { useEffect, useState } from 'react';
import localizedStrings from '../utils/localizedStrings';
import {
  MegaphoneIcon,
  BriefcaseIcon,
  ProfileIcon,
  PersonTagIcon,
  RightBlueArrowIcon,
  DropdownIcon,
  Gradient,
  CarouselLeftIcon,
  CarouselRightIcon
} from '../components/Icons';
import { Link, useHistory } from 'react-router-dom';
import { getDate } from '../utils/commonFunctions';
import { imagePlaceholder } from '../api/config';
import { Title } from '../components/Title';
import Months from '../components/Months';
import { AdminAPI } from '../api/v2';
import { useSelector } from 'react-redux';
import Button from '../components/button/index'

const yearToday = new Date().getFullYear();
const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

const Dashboard = () => {
  const [state, setState] = useState({});

  const { user } = useSelector(state => state.global);

  const history = useHistory();

  const fetchDashboardData = async () => {
    const {
      ads_count,
      ads_statistics_per_mouth,
      candidates_count,
      companies_count,
      new_companies,
      top_companies,
      users_count
    } = await AdminAPI.dashboard(yearToday);

    
    const step = 150 / (Math.max(...ads_statistics_per_mouth) || 0);
    const monthly_data = ads_statistics_per_mouth.map((x, y) => ({
      key: y + 1,
      data: x,
      name: months[y],
      size: step * x
    }));
    
    setState({
      ...state,
      ads_count,
      ads_statistics_per_mouth,
      candidates_count,
      companies_count,
      new_companies,
      top_companies,
      users_count,
      monthly_data
    });
  };

  useEffect(() => {
    fetchDashboardData();
  }, []);

  // const [adsStats, setAdsStats] = useState([]);
  // const [tempAdsStats, setTempAdsStats] = useState([]);
  // const [monthNumber, setMonthNumber] = useState(0);
  // const currentYear = new Date().getFullYear();
  // const history = useHistory();
  // const width = window.innerWidth;


  // useEffect(() => {
  //   const months = [
  //     { key: '1', data: ads_statistics_per_mouth[0], name: 'JAN' },
  //     { key: '2', data: ads_statistics_per_mouth[1], name: 'FEB' },
  //     { key: '3', data: ads_statistics_per_mouth[2], name: 'MAR' },
  //     { key: '4', data: ads_statistics_per_mouth[3], name: 'APR' },
  //     { key: '5', data: ads_statistics_per_mouth[4], name: 'MAJ' },
  //     { key: '6', data: ads_statistics_per_mouth[5], name: 'JUN' },
  //     { key: '7', data: ads_statistics_per_mouth[6], name: 'JUL' },
  //     { key: '8', data: ads_statistics_per_mouth[7], name: 'AVG' },
  //     { key: '9', data: ads_statistics_per_mouth[8], name: 'SEP' },
  //     { key: '10', data: ads_statistics_per_mouth[9], name: 'OKT' },
  //     { key: '11', data: ads_statistics_per_mouth[10], name: 'NOV' },
  //     { key: '12', data: ads_statistics_per_mouth[11], name: 'DEC' }
  //   ];

  //   const parseAdsData = ads => {
  //     let tempArr = months;

  //     if (ads && ads.length > 0) {
  //       ads.forEach(e => {
  //         let index = tempArr.findIndex(
  //           elem => elem.key === e.month.toString()
  //         );
  //         tempArr[index].data = e.data;
  //       });
  //     }
  //     setAdsStats(tempArr);

  //     if (width < 400) {
  //       setTempAdsStats(tempArr.slice(0, 3));
  //     } else if (width < 700) {
  //       setTempAdsStats(tempArr.slice(0, 5));
  //     } else {
  //       setTempAdsStats(tempArr);
  //     }
  //   };
  //   parseAdsData(data.ads);
  // }, [ads_statistics_per_mouth, width]);

  // const previousMonth = () => {
  //   let max = width < 400 ? 3 : 5;
  //   let index = monthNumber;

  //   if (index - max < 0) return;

  //   setTempAdsStats(adsStats.slice(index - max, index));
  //   setMonthNumber(index - max);
  // };

  // const nextMonth = () => {
  //   let max = width < 400 ? 3 : 5;
  //   let index = monthNumber;

  //   if (index + max > 12) return;

  //   setTempAdsStats(adsStats.slice(max + index, 2 * max + index));
  //   setMonthNumber(max + index);
  // };

  const goTo = (e, url) => {
    history.push(url);
    window.scrollTo({
      top: 0,
    });
  };

  return (
    <main id="mainContent" className="justify-between">
      <Title title="Dashboard" />
      <Gradient />
      <div id="close-area"></div>
      <header>
        <div className="title-box">
          <h1>{localizedStrings.dashboard.title}</h1>
          <p>
            {localizedStrings.dashboard.welcome} {user.full_name}!
          </p>
        </div>
      </header>
      <div className="box-container m-container new-ads new-order-1 bg-gradient-wb">
        <div className="title-box">
          <h2>{localizedStrings.dashboard.new_company}</h2>
        </div>

        <div className="ads-box-wrapper mt-n15">
          {state.new_companies?.map(item => (
            <Link to={`/company/${item.id}/preview`} className="rad-box" key={item.id}>
              <div className="company-img">
                <img
                  src={
                    item.profile_image ? item.profile_image : imagePlaceholder
                  }
                  alt="ad logo"
                />
              </div>
              <div className="company-desc">
                <h3>{item.full_name ? item.full_name : ' - '}</h3>
                <p>
                  {[item?.city?.name, item?.country?.name_short]
                    .filter(x => ![null, undefined, ''].includes(x))
                    .join(', ')}
                </p>
              </div>
              <div className="company-created-at">
                <span>
                  {localizedStrings.dashboard.date_created}:{' '}
                  {getDate(item.created_at)}
                </span>
              </div>
            </Link>
          ))}
        </div>
      </div>

      <div class="swipe-wrapper">
        <div class="line"></div>
        <div class="swipe">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="exchange-alt"
            class="svg-inline--fa fa-exchange-alt fa-w-16"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="M0 168v-16c0-13.255 10.745-24 24-24h360V80c0-21.367 25.899-32.042 40.971-16.971l80 80c9.372 9.373 9.372 24.569 0 33.941l-80 80C409.956 271.982 384 261.456 384 240v-48H24c-13.255 0-24-10.745-24-24zm488 152H128v-48c0-21.314-25.862-32.08-40.971-16.971l-80 80c-9.372 9.373-9.372 24.569 0 33.941l80 80C102.057 463.997 128 453.437 128 432v-48h360c13.255 0 24-10.745 24-24v-16c0-13.255-10.745-24-24-24z"
            ></path>
          </svg>
          <span>SWIPE</span>
        </div>
        <div class="line"></div>
      </div>

      <div className="box-container s-container company-s-container new-order-3 bg-gradient-wb">
        <div className="ico-side">
          <ProfileIcon />
        </div>
        <div className="stats-side">
          <h5>{localizedStrings.dashboard.sum_users}</h5>
          <h6 className="big">{state.users_count}</h6>
          <Button onClick={e => goTo(e, '/active-users')}>
            {localizedStrings.dashboard.show_list}
          </Button>
          {/* <button
            onClick={e => goTo(e, '/active-users')}
            className="all-btns blue-btn upper hide-on-mobile"
          >
            {localizedStrings.dashboard.show_list}
          </button> */}
        </div>
      </div>
      <div className="box-container s-container company-s-container new-order-4 bg-gradient-wb">
        <div className="ico-side">
          <BriefcaseIcon />
        </div>
        <div className="stats-side">
          <h5>{localizedStrings.dashboard.sum_companies}</h5>
          <h6 className="big">{state.companies_count}</h6>
          <Button onClick={e => goTo(e, '/active-companies')}>
            {localizedStrings.dashboard.show_list}
          </Button>
          {/* <button
            onClick={e => goTo(e, '/active-companies')}
            className="all-btns blue-btn upper hide-on-mobile"
          >
            {localizedStrings.dashboard.show_list}
          </button> */}
        </div>
      </div>
      <div className="box-container m-container stats-box new-order-2 bg-gradient-wb">
        <div className="title-box">
          <h2>{localizedStrings.dashboard.stats_title}</h2>
          <div className="select-wrapper">
            <select name="year" id="year" defaultValue={yearToday}>
              <option value={yearToday}>{yearToday}</option>
            </select>
            <DropdownIcon className="select-arrow" />
          </div>

          <div className="mobile-select">
            <button className="active">{yearToday}</button>
          </div>
        </div>

        <div className="calendar-wrapper">
          <Months data={state.monthly_data} />

          
        </div>
      </div>
      <div className="box-container s-container company-s-container new-order-5 bg-gradient-wb">
        <div className="ico-side">
          <PersonTagIcon />
        </div>
        <div className="stats-side">
          <h5>{localizedStrings.dashboard.sum_employees}</h5>
          <h6 className="big">{state.candidates_count}</h6>
          <Button onClick={e => goTo(e, '/active-candidates')}>
            {localizedStrings.dashboard.show_all}
          </Button>
          {/* <button
            onClick={e => goTo(e, '/active-candidates')}
            className="all-btns blue-btn upper hide-on-mobile"
          >
            {localizedStrings.dashboard.show_all}
          </button> */}
        </div>
      </div>
      <div className="box-container s-container company-s-container new-order-6 bg-gradient-wb">
        <div className="ico-side">
          <MegaphoneIcon />
        </div>
        <div className="stats-side">
          <h5>{localizedStrings.dashboard.sum_ads}</h5>
          <h6 className="big">{state.ads_count}</h6>
          <Button onClick={e => goTo(e, '/active-ads')}>
            {localizedStrings.dashboard.show_all}
          </Button>
          {/* <button
            onClick={e => goTo(e, '/active-ads')}
            className="all-btns blue-btn upper hide-on-mobile"
          >
            {localizedStrings.dashboard.show_all}
          </button> */}
        </div>
      </div>
      <div className="box-container l-container new-candidates-box new-candidates-box-mob admin-table add-scroll new-order-7 bg-gradient-wb">
        <div className="left-side left-side-mob">
          <BriefcaseIcon className="hide-on-mobile" />
          <h2>{localizedStrings.dashboard.top_companies_title}</h2>
          <h5>{localizedStrings.dashboard.top_companies_desc}</h5>
        </div>
        <div className="right-side">
          {/* Main row */}
          <div className="row">
            <p className="column m-column row-title">
              {localizedStrings.dashboard.top_companies_th_company}
            </p>
            <p className="column m-column row-title">
              {localizedStrings.dashboard.top_companies_th_area}
            </p>
            <p className="column m-column row-title">
              {localizedStrings.dashboard.top_companies_th_country}
            </p>
            <p className="column m-column row-title">
              {localizedStrings.dashboard.top_companies_th_city}
            </p>
            <p className="column m-column row-title">
              {localizedStrings.dashboard.top_companies_th_application}
            </p>
            <p className="column m-column row-title"></p>
          </div>

          {state.top_companies ? (
            state.top_companies.map((item, index) => (
              <div className="row stats-row" key={index}>
                <p className="column m-column bold">
                  <Link to={`/company/${item.id}/preview`}>
                    {item.full_name ? item.full_name : '-'}
                  </Link>
                </p>
                <p className="column m-column light">
                  {item.company_activity
                    ? item.company_activities
                      ? item.company_activities.name
                      : '-'
                    : '-'}
                </p>
                <p className="column m-column light">
                  {item.country && item.country.name ? item.country.name : '-'}
                </p>
                <p className="column m-column light">
                  {item.city && item.city.name ? item.city.name : '-'}
                </p>
                <p className="column m-column light">
                  {item.apply_count ? item.apply_count : 0}
                </p>
                <div className="column m-column hvr-link">
                  <Link to={`/company/${item.id}/preview`} className="bold upper">
                    {localizedStrings.dashboard.look}
                  </Link>
                  <RightBlueArrowIcon />
                </div>
              </div>
            ))
          ) : (
            <p className="no-new-ads-2">
              {localizedStrings.dashboard.no_companies}
            </p>
          )}
        </div>

        <div className="right-side status-mob">
          {state.top_companies ? (
            state.top_companies.map((item, index) => (
              <div className="row stats-row" key={index}>
                <div
                  className="stats-item"
                  onClick={e => goTo(e, `/company/${item.id}/preview`)}
                >
                  <p className="column m-column row-title">
                    {localizedStrings.dashboard.top_companies_th_company}
                  </p>

                  <div className="column m-column bold">
                    {item.full_name ? item.full_name : '-'}
                  </div>
                </div>

                <div
                  className="stats-item"
                  onClick={e => goTo(e, `/company/${item.id}/preview`)}
                >
                  <p className="column m-column row-title">
                    {localizedStrings.dashboard.top_companies_th_area}
                  </p>
                  <div className="column m-column light">
                    {item.company_activity
                      ? item.company_activities
                        ? item.company_activities.name
                        : '-'
                      : '-'}
                  </div>
                </div>

                <div
                  className="stats-item"
                  onClick={e => goTo(e, `/company/${item.id}/preview`)}
                >
                  <p className="column m-column row-title">
                    {localizedStrings.dashboard.top_companies_th_country}
                  </p>
                  <div className="column m-column light">
                    {item.country && item.country.name
                      ? item.country.name
                      : '-'}
                  </div>
                </div>

                <div
                  className="stats-item"
                  onClick={e => goTo(e, `/company/${item.id}/preview`)}
                >
                  <p className="column m-column row-title">
                    {localizedStrings.dashboard.top_companies_th_city}
                  </p>
                  <div className="column m-column light">
                    {item.city && item.city.name ? item.city.name : '-'}
                  </div>
                </div>

                <div
                  className="stats-item"
                  onClick={e => goTo(e, `/company/${item.id}/preview`)}
                >
                  <p className="column m-column row-title">
                    {localizedStrings.dashboard.top_companies_th_application}
                  </p>
                  <div className="column m-column light">
                    {item.apply_count ? item.apply_count : 0}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="no-new-ads-2">
              {localizedStrings.dashboard.no_companies}
            </p>
          )}
        </div>
      </div>

      <div class="swipe-wrapper swipe-wrapper-status-mob">
        <div class="line"></div>
        <div class="swipe">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="exchange-alt"
            class="svg-inline--fa fa-exchange-alt fa-w-16"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="M0 168v-16c0-13.255 10.745-24 24-24h360V80c0-21.367 25.899-32.042 40.971-16.971l80 80c9.372 9.373 9.372 24.569 0 33.941l-80 80C409.956 271.982 384 261.456 384 240v-48H24c-13.255 0-24-10.745-24-24zm488 152H128v-48c0-21.314-25.862-32.08-40.971-16.971l-80 80c-9.372 9.373-9.372 24.569 0 33.941l80 80C102.057 463.997 128 453.437 128 432v-48h360c13.255 0 24-10.745 24-24v-16c0-13.255-10.745-24-24-24z"
            ></path>
          </svg>
          <span>SWIPE</span>
        </div>
        <div class="line"></div>
      </div>
    </main>
  );
};

export default Dashboard;
