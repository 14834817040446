import { IconButton } from '@material-ui/core';
import { Close, CloudUpload, Delete, Edit, GetApp, Replay, Save, Videocam } from '@material-ui/icons';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { AdminAPI, CandidateAPI, CompanyAPI, GlobalAPI } from '../../api/v2';
import Button from '../../components/button/index';
import DatePicker from '../../components/datepicker/index';
import Input from '../../components/input';
import InputCard from '../../components/inputcard';
import RichText from '../../components/RichText';
import Select from '../../components/select';
import { Title } from '../../components/Title';
import localizedStrings from '../../utils/localizedStrings';
import Modal from '../../components/modal/index';
import { toast } from 'react-toastify';
import moment from 'moment';
import { apiBaseUrl } from '../../api/config';

const EditCandidate = () => {
  const [values, setValues] = useState({
    full_name: '',
    education_level_id: '',
    email: '',
    gender_id: '',
    country_id: '',
    city_id: '',
    address: '',
    phone: '',
    computer_skills: [],
    languages: [],
    work_experiences: [],
    documents: []
  });

  const { id } = useParams();

  const { common } = useSelector(state => state.global);
  const [educationLevels, setEducationLevels] = useState([]);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [files, setFiles] = useState({
    profile_image: ''
  })

  const { user } = useSelector(state =>state.global);

  const [loaded, setLoaded] = useState(false);

  const [cancelModal, setCancelModal] = useState(false);
  const onCancelModalClose = () => setCancelModal(false);

  const [submitModal, setSubmitModal] = useState(false);
  const onSubmitModalClose = () => setSubmitModal(false);

  const [deleteModal, setDeleteModal] = useState(false);
  const onDeleteModalClose = () => setDeleteModal(false);

  const history = useHistory();

  const goTo = x => {
    history.push(x);
  };

  const onCancel = () => {
    history.push('/active-candidates');
  };

  const getCandidate = async () => {
    const {
      full_name,
      education_level,
      email,
      gender,
      country,
      city,
      address,
      phone,
      computer_skills,
      languages,
      work_experiences,
      documents,
      profile_image
    } = await CandidateAPI.get(id);
    

    const formattedEducationLevels = common.education_levels.map(x => ({
      value: x.id,
      label: x.name
    }));
    setEducationLevels(formattedEducationLevels);
    await getCountries();
    await getCities(country.id);
    setValues({
      full_name,
      education_level_id: education_level.id,
      email,
      gender_id: gender.id,
      country_id: country.id,
      city_id: city.id,
      address,
      phone,
      computer_skills,
      languages,
      work_experiences,
      documents
    });
    setFiles({...files, profile_image })
    setLoaded(true);
  };

  const getCountries = async () => {
    const res = await GlobalAPI.countries();
    const formattedCountries = res.map(x => ({ value: x.id, label: x.name }));
    setCountries(formattedCountries);
  };

  const getCities = async country_id => {
    if (country_id) {
      setValues({ ...values, country_id });
      const res = await GlobalAPI.cities(country_id);
      const formattedCities = res.map(x => ({ value: x.id, label: x.name }));
      setCities(formattedCities);
    } else {
      setCities([]);
      setValues({ ...values, country_id: '', city_id: '' });
    }
  };

  useEffect(() => {
    getCandidate();
  }, []);

  //   const deleteAd = async () => {
  //     try {
  //       await AdminAPI.deleteAd(id);
  //       toast.success('Oglas je uspešno obrisan');
  //       history.push('/active-ads');
  //     } catch (e) {
  //       toast.error('Došlo je do greške');
  //     }
  //   };

  useEffect(() => {
    console.log(values);
  },[values])

  const uploadRef = useRef();
  const uploadCvVideo = async (e) => {
    try {
      const user_id = user.id;
      const file = e.target.files[0];
      const res = await CompanyAPI.updateProfileVideo(file, user_id);
      toast.success('Video je uspešno uploadovan');
      console.log(res);
    } catch(e) {
      toast.error("Došlo je do greške")
    }
  }

  return (
    <Fragment>
      <main id="mainContent" className="xl-resize">
        <Title title="Pregled kandidata" />
        <header>
          <div className="title-box">
            <h1>Pregled kandidata</h1>
            <p>Informacije o kandidatu</p>
          </div>
        </header>

        <div className="add-ad-container">
          <h2 className="title">Pregled kandidata</h2>
          {loaded ? (
            <Fragment>
              <div className="candidate-main">
                <div className="candidate-profile-row">
                  <div className="candidate-image-holder">
                    <img
                      src={
                        files.profile_image ||
                        '/images/mockup/profile-img-placeholder.png'
                      }
                      alt={values.full_name}
                    />
                  </div>
                  <div className="candidate-profile-info">
                    <h1>{values.full_name}</h1>
                    {values.birth_year ? (
                      <p>{moment(values.birth_year).format('DD.MM.YYYY')}</p>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className="candidate-row">
                  <div className="add-ad-half">
                    <div className="add-ad-full-info">
                      <Input
                        label="Ime i prezime"
                        className="force-space-b"
                        value={values.full_name}
                        onChange={full_name =>
                          setValues({ ...values, full_name })
                        }
                      />
                      <Select
                        label="Pol"
                        className="force-space-b"
                        value={values.gender_id}
                        onChange={gender_id =>
                          setValues({ ...values, gender_id })
                        }
                        options={[
                          { value: 1, label: 'Muški' },
                          { value: 2, label: 'Ženski' }
                        ]}
                      />
                      <Input
                        label="Kontakt email"
                        className="force-space-b"
                        value={values.email}
                        onChange={email => setValues({ ...values, email })}
                      />
                      <Select
                        label="Obrazovanje"
                        className="force-space-b"
                        value={values.education_level_id}
                        onChange={education_level_id =>
                          setValues({ ...values, education_level_id })
                        }
                        options={educationLevels}
                      />
                      <Select
                        label="Država"
                        className="force-space-b"
                        value={values.country_id}
                        onChange={getCities}
                        options={countries}
                      />
                      <Select
                        label="Grad"
                        className="force-space-b"
                        value={values.city_id}
                        onChange={city_id => setValues({ ...values, city_id })}
                        options={cities}
                      />
                      <Input
                        label="Ulica i broj prebivališta"
                        className="force-space-b"
                        value={values.address}
                        onChange={address => setValues({ ...values, address })}
                      />
                      <Input
                        label="Kontakt telefon"
                        className="force-space-b"
                        value={values.phone}
                        onChange={phone => setValues({ ...values, phone })}
                      />
                      <InputCard
                        label="Rad na računaru"
                        className="force-space-b"
                      >
                        {values.computer_skills
                          ?.map(x => x.computer_skill_name.name)
                          .filter(x => ![null, undefined, ''].includes(x))
                          .join(', ') || '/'}
                      </InputCard>
                      <InputCard
                        label="Strani jezici"
                        className="force-space-b"
                      >
                        {values.languages
                          ?.map(x => x.languages_name)
                          .filter(x => ![null, undefined, ''].includes(x))
                          .join(', ') || '/'}
                      </InputCard>
                      <InputCard
                        label="Radno iskustvo"
                        className="force-space-b"
                      >
                        {[
                          values.work_experiences
                            ?.map(x =>
                              moment(x.end_date).diff(x.start_date, 'days')
                            )
                            .filter(x => ![NaN, undefined, null].includes(x))
                            .reduce((a, b) => a + b, 0) / 365
                        ]
                          .map(x =>
                            x
                              ? x < 1
                                ? 'Manje od godinu dana'
                                : `${Math.round(x)} godina/e`
                              : 'Bez radnog iskustva'
                          )
                          .join()}
                      </InputCard>
                    </div>
                  </div>
                  <div className="add-ad-half">
                    <div className="add-ad-row-info">
                      <span>CV Dokument</span>
                      {values.documents.map((x, y) => (
                        <a
                          href={`${apiBaseUrl}/${x.document_link}`}
                          download
                          className="candidate-link"
                          key={y}
                        >
                          <GetApp />
                          {x.document_name}
                        </a>
                      ))}
                      {!values.documents.length ? '/' : ''}
                    </div>
                    <div className="add-ad-row-info">
                      <span>
                        CV Video biografija
                        <div
                          className="upload-video-label"
                          onClick={() => uploadRef.current.click()}
                        >
                          <IconButton className="add-ad-row-actions-icon">
                            <input
                              type="file"
                              hidden
                              ref={uploadRef}
                              onChange={uploadCvVideo}
                            />
                            <CloudUpload />
                          </IconButton>
                          Upload video
                        </div>
                      </span>
                      <div className="candidate-video">
                        {values.cv_video ? (
                          <video src={values.cv_video.video} controls></video>
                        ) : (
                          <img src="/images/mockup/video-placeholder.png" />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="add-ad-actions">
                <Button
                  className="button-inline"
                  onClick={() => goTo(`/candidate/${id}/edit`)}
                >
                  <Edit className="hide-on-mobile-sm" />
                  Izmeni kandidata
                </Button>
                <Button
                  grayscale
                  className="button-inline"
                  onClick={() => goTo('/active-candidates')}
                >
                  <Replay className="hide-on-mobile-sm" />
                  Nazad
                </Button>
              </div>
            </Fragment>
          ) : (
            <div className="edit-ad-loading">
              <h1>Oglas se učitava</h1>
              <p>Molimo sačekajte</p>
            </div>
          )}
        </div>
      </main>
    </Fragment>
  );
};

export default EditCandidate;
