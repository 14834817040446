import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function archivedCandidatesReducer(
  state = initialState.archivedCandidates,
  action
) {
  switch (action.type) {
    case types.LOAD_ARCHIVED_CANDIDATES_SUCCESS:
      return action.data;
    case types.LOAD_ARCHIVED_CANDIDATES_FAILURE:
      return [];
    default:
      return state;
  }
}
