export function getDate(date) {
  return date
    ? date.substring(8, 10) +
        '.' +
        date.substring(5, 7) +
        '.' +
        date.substring(0, 4) +
        '.'
    : ' - ';
}

export function getDateTime(date) {
  return date
    ? date.substring(8, 10) +
        '.' +
        date.substring(5, 7) +
        '.' +
        date.substring(0, 4) +
        ' /  ' +
        date.substring(11, 13) +
        ':' +
        date.substring(13, 16)
    : ' - ';
}

export function getFullNameDate(date) {
  const monthNames = {
    '01': 'JANUAR',
    '02': 'FEBRUAR',
    '03': 'MART',
    '04': 'APRIL',
    '05': 'MAJ',
    '06': 'JUN',
    '07': 'JUL',
    '08': 'AVGUST',
    '09': 'SEPTEMBAR',
    10: 'OKTOBAR',
    11: 'NOVEMBAR',
    12: 'DECEMBAR'
  };
  return date
    ? date.substring(8, 10) +
        '.' +
        monthNames[date.substring(5, 7)] +
        ' ' +
        date.substring(0, 4) +
        '.'
    : ' - ';
}

export function getWorkExperiencePeriod(experience) {
  if (!experience || experience.length === 0) return 0;
  let monthsNumber = 0;
  experience.forEach(exp => {
    let startDate = new Date(exp.start_date);
    let endDate = new Date(exp.end_date);
    monthsNumber =
      monthsNumber +
      (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24 * 30);
  });
  return parseInt(monthsNumber / 12) || 0;
}

export function setDate(dateElements) {
  return (
    dateElements.year + '-' + (dateElements.month + 1) + '-' + dateElements.day
  );
}

export const getYearsInput = () => {
  let now = parseInt(new Date().getFullYear());
  let years = [];
  for (let i = now, k = 0; i > parseInt(now) - 100; i--, k++) {
    let data = {};
    data = {
      id: (now - k).toString(),
      name: (now - k).toString()
    };
    years.push(data);
  }

  return years;
};

export const getMonthsInput = () => {
  const months = [
    { id: '01', name: 'Januar' },
    { id: '02', name: 'Februar' },
    { id: '03', name: 'Mart' },
    { id: '04', name: 'April' },
    { id: '05', name: 'Maj' },
    { id: '06', name: 'Jun' },
    { id: '07', name: 'Jul' },
    { id: '08', name: 'Avgust' },
    { id: '09', name: 'Septembar' },
    { id: '10', name: 'Oktobar' },
    { id: '11', name: 'Novembar' },
    { id: '12', name: 'Decembar' }
  ];

  return months;
};

export const getDaysInput = (month, year) => {
  let days_by_month = new Date(
    year || `${new Date().getFullYear()}`,
    month || `${new Date().getMonth()}`,
    0
  ).getDate();

  const days = [];

  for (let i = 1; i <= days_by_month; i++) {
    let data = {};

    let val = i.toString();
    if (i.toString().length === 1) {
      val = '0' + i;
    }
    data = {
      id: val,
      name: val
    };
    days.push(data);
  }

  return days;
};

export const getEducationTitles = data => {
  let temp = '';

  if (data && data.length > 0) {
    data.forEach((item, index) => {
      if (index === data.length - 1) temp += item.title;
      else temp += item.title + ', ';
    });
  }

  return temp;
};

export const getExperience = data => {
  let temp = 0;
  if (data && data.length > 0) {
    let work_days = 0;
    data.forEach(e => {
      let start_date = e.start_date;
      let end_date = e.end_date;
      let s_date = new Date(start_date.replace('-', '/'));
      let e_date = new Date(end_date.replace('-', '/'));
      let days = Math.ceil(Math.abs(s_date - e_date) / (1000 * 60 * 60 * 24));
      work_days += days;
    });

    let k = 1;
    for (let i = 0; i < work_days; i++) {
      if (i === 365 * k) {
        k++;
        temp++;
      }
    }
  }
  console.log(temp);
  if (temp === 0) return 'Bez radnog iskustva';
  return temp + '. godina';
};

export const getLanguagesUser = data => {
  let temp = '';

  if (data && data.length > 0) {
    data.forEach((item, index) => {
      if (index === data.length - 1)
        temp += item.languages_name ? item.languages_name : '';
      else temp += item.languages_name ? item.languages_name + ', ' : '';
    });
  }
  return temp;
};

export const getTechSkills = data => {
  let temp = '';

  if (data && data.length > 0) {
    data.forEach((item, index) => {
      if (index === data.length - 1)
        temp += item.computer_skill_name ? item.computer_skill_name.name : '';
      else
        temp += item.computer_skill_name
          ? item.computer_skill_name.name + ', '
          : ' ';
    });
  }

  return temp;
};

export const getLicense = data => {
  let temp = '';

  if (data && data.length > 0) {
    data.forEach((item, index) => {
      if (index === data.length - 1)
        temp += item.drivers_licence_category_name
          ? item.drivers_licence_category_name
          : '';
      else
        temp += item.drivers_licence_category_name
          ? item.drivers_licence_category_name + ', '
          : ' ';
    });
  }

  return temp;
};
