import React, { useState, useEffect } from 'react';
import { DropdownIcon2, XIcon } from '../../../components/Icons';
import { ComboBox } from '../../../components/Inputs';
import { getAllAddings } from '../../../redux/actions/userActions';
import {
  getSearchCities,
  getAllCountries
} from '../../../redux/actions/countryActions';
import { connect } from 'react-redux';

const Filter = props => {
  const [values, setValues] = useState({ country: '', city: '' });
  const [autocomplete, setAutocomplete] = useState({
    countries: [],
    cities: [],
    jobs: []
  });

  useEffect(() => {
    props.getAllCountries();
    props.getAllAddings();
    setAutocomplete({
      ...autocomplete,
      countries: props.countries,
      jobs: props.jobs
    });
  }, []);

  const handleAutoCompleteSelect = async (e, value, name) => {
    console.log(value);
    setValues({ ...values, [name]: value });
    if (name === 'country') {
      const cities = await props.getSearchCities({ country_id: value.id });
      setAutocomplete({ ...autocomplete, cities });
    }
  };

  // const handleAutoComplete = (e, name) => {
  //   console.log('CHANGE: ',e,name);
  //   let value = e.target.value;

  //   if (name === 'city') {
  //     props.getSearchCities({ term: value });
  //     setAutocomplete({ ...autocomplete, cities: props.cities });
  //   }
  // };

  const handleSubmit = e => {
    e.preventDefault();
    props.closeModal('filter', values);
  };

  const resetFilters = () => {
    setValues({
      country: '',
      city: '',
      domain: ''
    });
    props.closeModal('filter', null);
  };
  return (
    <div
      id="filters"
      className="modal-bg"
      style={{ display: props.modal ? 'block' : 'none' }}
    >
      <div className="modal s-modal">
        <div className="modal-header">
          <h5>Filteri</h5>
          <button className="close-btn" onClick={() => props.closeModal('filter', null)}>
            <XIcon />
          </button>
        </div>
        <div className="modal-body justify-between">
          <div className="box box-100">
            <div className="select-wrapper">
              <ComboBox
                className="select"
                options={props.countries || []}
                name="country"
                label="name"
                value={values.country}
                onChangeAutocomplete={handleAutoCompleteSelect}
                comboLabel="Izaberite državu"
              />
              <DropdownIcon2 className="select-arrow " />
            </div>
          </div>
          <div className="box box-100">
            <div className="select-wrapper">
              <ComboBox
                className="select"
                options={props.cities || []}
                name="city"
                label="name"
                value={values.city}
                onChangeAutocomplete={handleAutoCompleteSelect}
                comboLabel="Izaberite grad"
              />
              <DropdownIcon2 className="select-arrow " />
            </div>
          </div>
          {/* 
          FILTER BY JOB TYPE - NEEDS TO ADD LATER
          <div className="box box-100">
            <div className="select-wrapper">
              <ComboBox
                className="select"
                options={props.jobs || []}
                name="domain"
                label="name"
                value={values.domain}
                onChangeAutocomplete={handleAutoCompleteSelect}
              />
              <DropdownIcon2 className="select-arrow " />
            </div>
          </div> */}

          <button
            className="all-btns blue-btn w-full mb-20 upper"
            onClick={handleSubmit}
          >
            Filtriranje
          </button>
          <button
            className="all-btns upper no-bg w-full"
            onClick={resetFilters}
          >
            Resetujte filtere
          </button>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  //TODO: replace this with real data

  const { search_cities, countries, addings } = state;
  return {
    cities: search_cities,
    countries: countries,
    jobs: addings.type_of_works
  };
}

const mapDispatchToProps = {
  getSearchCities: getSearchCities,
  getAllCountries: getAllCountries,
  getAllAddings: getAllAddings
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
