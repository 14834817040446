import { IconButton } from '@material-ui/core';
import { Close, Delete, Edit, Save } from '@material-ui/icons';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { AdminAPI, CompanyAPI, GlobalAPI } from '../../api/v2';
import Button from '../../components/button/index';
import DatePicker from '../../components/datepicker/index';
import Input from '../../components/input';
import InputCard from '../../components/inputcard';
import RichText from '../../components/RichText';
import Select from '../../components/select';
import { Title } from '../../components/Title';
import localizedStrings from '../../utils/localizedStrings';
import Modal from '../../components/modal/index';
import { toast } from 'react-toastify';
import moment from 'moment';

const PreviewAd = () => {
  const [ad, setAd] = useState(null);

  const { id } = useParams();

  const { common } = useSelector(state => state.global);

  const [loaded, setLoaded] = useState(false);

  const [cancelModal, setCancelModal] = useState(false);
  const onCancelModalClose = () => setCancelModal(false);

  const [submitModal, setSubmitModal] = useState(false);
  const onSubmitModalClose = () => setSubmitModal(false);

  const history = useHistory();

  const goTo = x => {
    history.push(x);
  };

  const onCancel = () => {
    history.push('/active-ads');
  };

  const getAd = async () => {
    const res = await AdminAPI.showAd(id);
    console.log('OGLAS: ', res);
    setAd(res);
    setLoaded(true);
  };

  useEffect(() => {
    getAd();
  }, []);

  return (
    <Fragment>
      <main id="mainContent" className="xl-resize">
        <Title title="Pregled oglasa" />
        <header>
          <div className="title-box">
            <h1>Pregled oglasa</h1>
            <p>Informacije o oglasu</p>
          </div>
        </header>

        <div className="add-ad-container">
          <h2 className="title">{ad?.title ? ad.title : 'Pregled oglasa'}</h2>
          {loaded ? (
            <Fragment>
              <div className="add-ad-main">
                <div className="add-ad-half">
                  <div className="add-ad-image-holder">
                    <img src={ad.company.profile_image} alt={ad.title} />
                  </div>
                  <div className="add-ad-full-info">
                    <div className="add-ad-row-info">
                      <span>Naslov oglasa</span> {ad.title || '/'}
                    </div>
                    <div className="add-ad-row-info">
                      <span>Naziv kompanije</span>{' '}
                      {ad.company?.full_name || '/'}
                    </div>
                    <div className="add-ad-row-info">
                      <span>Tip posla</span> {ad.type_of_work?.name || '/'}
                    </div>
                    <div className="add-ad-row-info">
                      <span>Stepen stručne spreme</span>
                      {
                        (
                          common.education_levels.find(
                            x => x.id === ad.education_level_id
                          ) || { name: '/' }
                        ).name
                      }
                    </div>
                    <div className="add-ad-row-info">
                      <span>Lokacija</span>{' '}
                      {[ad.city?.name, ad.country?.name]
                        .filter(x => ![null, undefined, ''].includes(x))
                        .join(', ') || '/'}
                    </div>
                    <div className="add-ad-row-info">
                      <span>Datum trajanja</span>{' '}
                      {`${moment(ad.start_date).format(
                        'DD.MM.YYYY'
                      )} - ${moment(ad.end_date).format('DD.MM.YYYY')}`}
                    </div>
                    <div className="add-ad-row-info">
                      <span>Broj prijavljenih kandidata</span>{' '}
                      {ad.number_of_applications ||
                        'Nema prijavljenih kandidata'}
                    </div>
                  </div>
                </div>
                <div className="add-ad-half">
                  <InputCard className="force-space-b">
                    <RichText viewOnly value={ad.description} />
                  </InputCard>
                  <div className="add-ad-questions-main">
                    <h4>Pitanja kandidatima</h4>
                    <div className="add-ad-questions">
                      {ad.questions?.map((x, y) => (
                        <div key={y} className="add-ad-row-info">
                          <span>{y + 1}# Pitanje</span>- {x.text_question}
                        </div>
                      ))}
                      {!ad.questions?.length ? (
                        <div className="add-ads-no-questions">
                          <h1>Nema pitanja</h1>
                          <p>U oglasu se ne nalaze pitanja</p>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="add-ad-actions">
                <Button
                  className="button-inline"
                  onClick={() => goTo(`/ad/${id}/edit`)}
                >
                  <Edit className="hide-on-mobile-sm" />
                  Izmeni oglas
                </Button>
                <Button
                  grayscale
                  className="button-inline"
                  onClick={() => setCancelModal(true)}
                >
                  <Close className="hide-on-mobile-sm" />
                  Otkaži
                </Button>
              </div>
            </Fragment>
          ) : (
            <div className="edit-ad-loading">
              <h1>Oglas se učitava</h1>
              <p>Molimo sačekajte</p>
            </div>
          )}
        </div>
      </main>
      <Modal
        title="Otkaži"
        open={cancelModal}
        onClose={onCancelModalClose}
        size="medium"
      >
        <CancelModal onCancel={onCancel} onClose={onCancelModalClose} />
      </Modal>
      <Modal
        title="Postavi oglas"
        open={submitModal}
        onClose={onSubmitModalClose}
        size="medium"
      >
        <SubmitModal onSubmit={() => {}} onClose={onSubmitModalClose} />
      </Modal>
    </Fragment>
  );
};

const CancelModal = ({ onClose, onCancel }) => {
  const processCancel = () => {
    onClose();
    onCancel();
  };

  return (
    <Fragment>
      <p className="modal-center-text">
        Da li ste sigurni da želite da otkažete radnju?
      </p>
      <div className="d-flex justify-between">
        <Button className="force-space-r" onClick={processCancel}>
          Da
        </Button>
        <Button grayscale onClick={onClose}>
          Ne
        </Button>
      </div>
    </Fragment>
  );
};

const SubmitModal = ({ onClose, onSubmit }) => {
  const processSubmit = async () => {
    await onSubmit();
    onClose();
  };

  return (
    <Fragment>
      <p className="modal-center-text">
        Da li ste sigurni da ste želite da sačuvate oglas?
      </p>
      <div className="d-flex justify-between">
        <Button className="force-space-r" onClick={processSubmit}>
          Da
        </Button>
        <Button grayscale onClick={onClose}>
          Ne
        </Button>
      </div>
    </Fragment>
  );
};

export default PreviewAd;
