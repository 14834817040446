import { InputWrapper } from './styles';

const Input = ({
  color = 'primary',
  variant = 'outlined',
  onChange,
  ...props
}) => {
  const onValue = e => {
    if (onChange) onChange(e.target.value);
  };

  return (
    <InputWrapper
      color={color}
      variant={variant}
      onChange={onValue}
      {...props}
    />
  );
};

export default Input;
