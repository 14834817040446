import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import {
  DropdownIcon2,
  FiltersIcon,
  FolderIcon,
  PenIcon,
  PlusIcon,
  SearchIcon,
  ProfileIcon,
  MegaphoneIcon
} from '../../components/Icons';
import { CustomInput2 } from '../../components/Inputs';
import Pagination from '../../components/pagination/index';
import NewTable from '../../components/table/index';
import { Title } from '../../components/Title';
import { AdminAPI, CompanyAPI, GlobalAPI } from '../../api/v2';
import moment from 'moment';
import Switch from '../../components/switch';
import Modal from '../../components/modal';
import { IconButton } from '@material-ui/core';
import { EditOutlined, Email, Folder } from '@material-ui/icons';
import { Fragment } from 'react';
import Select from '../../components/select';
import Button from '../../components/button/index';
import { useRef } from 'react';
import Input from '../../components/input';
import { toast } from 'react-toastify';

var searchTimeout;

const ActiveCompanies = () => {
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(0);
  const [selection, setSelection] = useState([]);
  const [search, setSearch] = useState('');
  const [state, setState] = useState({
    role: 'company',
    limit: 5,
    offset: 0,
    term: '',
    is_archived: 0
  });
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState({
    order_dir: 'asc',
    order_by: 'id'
  });

  // MODALS
  const [filterModal, setFilterModal] = useState({
    open: false
  });
  const onFilterModalClose = () =>
    setFilterModal({ ...filterModal, open: false });

  // MODALS
  const [archiveModal, setArchiveModal] = useState({
    open: false,
    archiveId: 0
  });
  const onArchiveModalClose = () =>
    setArchiveModal({ ...archiveModal, open: false });

  // MODALS
  const [bulkArchiveModal, setBulkArchiveModal] = useState({
    open: false,
    archiveIds: []
  });
  const onBulkArchiveModalClose = () =>
    setBulkArchiveModal({ ...bulkArchiveModal, open: false });

  const searchRef = useRef();
  useEffect(() => {
    searchRef.current = search;
  }, [search]);

  let history = useHistory();

  const handleSearch = e => {
    setSearch(e.target.value);
    if (searchTimeout) clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      setState({ ...state, term: searchRef.current });
    }, 300);
  };

  const onRequestSort = (e, key) => {
    setSort(
      sort.order_by === key
        ? { ...sort, order_dir: sort.order_dir === 'asc' ? 'desc' : 'asc' }
        : { ...sort, order_by: key, order_dir: 'asc' }
    );
  };

  const archive = async () => {
    await AdminAPI.userSetArchived(archiveModal.archiveId, true);
    setState({ ...state });
  };

  const setEminent = async (e, x) => {
    console.log(e, x);
    await CompanyAPI.setEminentStatus(x.id, e);
  };

  const getActiveCompanies = async params => {
    const { data, meta } = await AdminAPI.users(params);

    const mappedUsers = data.map(
      ({
        created_at,
        full_name,
        role,
        email,
        eminent,
        city,
        country,
        ...x
      }) => ({
        ...x,
        full_name: <Link to={`/company/${x.id}/preview`}>{full_name}</Link>,
        email: <a href={`mailto: ${email}`}>{email}</a>,
        created_at: moment(created_at).format('DD-MM-YYYY'),
        location: [city?.name, country?.name_short]
          .filter(x => ![undefined, null, ''].includes(x))
          .join(', '),
        role: { admin: 'Admin', employee: 'Kandidat', company: 'Kompanija' }[
          role
        ],
        eminent: (
          <Switch
            key={`eminent_${x.id}`}
            checked={eminent ? true : false}
            setChecked={e => setEminent(e, x)}
          />
        ),
        edit: (
          <IconButton
            key={`edit_${x.id}`}
            onClick={() => goTo(`/company/${x.id}/edit`)}
            style={{ fontSize: 24, margin: '0 auto', display: 'block' }}
          >
            <EditOutlined fontSize="inherit" />
          </IconButton>
        ),
        ads: (
          <IconButton
            key={`ads_${x.id}`}
            style={{ fontSize: 24, margin: '0 auto', display: 'block' }}
            onClick={() => goTo(`/company-ads/${x.id}`)}
          >
            <MegaphoneIcon width={24} height={24} />
          </IconButton>
        ),
        archive: (
          <IconButton
            key={`archive_${x.id}`}
            style={{ fontSize: 24, margin: '0 auto', display: 'block' }}
            onClick={() =>
              setArchiveModal({
                ...archiveModal,
                open: true,
                archiveId: x.id
              })
            }
          >
            <Folder />
          </IconButton>
        )
      })
    );

    setUsers(mappedUsers);
    setTotal(meta.total);
  };

  useEffect(() => {
    getActiveCompanies({ ...state, ...sort, offset: (page - 1) * state.limit });
  }, [state, sort, page]);

  const goTo = x => history.push(x);

  const onSelection = e => {
    setSelection(e);
  };

  const bulkArchive = async () => {
    await AdminAPI.usersSetArchived(selection.map(x => x.id));
    setState({ ...state, offset: 0 });
    setSelection([]);
  };

  useEffect(() => {
    if (users.length === 0 && page > 1) {
      setPage(page - 1);
    }
  }, [users]);

  return (
    <main id="mainContent" className="xl-resize main-active-companies">
      <Title title="Aktivni korisnici" />
      <header>
        <div className="title-box">
          <h1>Kompanije</h1>
          <p>Upravljanje kompanijama</p>
        </div>

        <div className="btn-wrapper">
          <Button className="button-inline" disabled={!selection.length}>
            <Email className="hide-on-mobile-sm" />
            Grupna poruka ({selection.length})
          </Button>
          {/* <button
            className="all-btns blue-btn upper btn-order-sm-last"
            onClick={() => {}}
          ></button> */}
          <Button
            grayscale
            className="button-inline"
            disabled={!selection.length}
            onClick={() =>
              setBulkArchiveModal({ ...bulkArchiveModal, open: true })
            }
          >
            <Folder className="hide-on-mobile-sm" />
            Arhiviraj selektovano ({selection.length})
          </Button>
        </div>

        <div className="search-box-wrapper">
          <div className="wrapper justify-between w-select-10">
            <div className="select-wrapper select-row-wrapper">
              <select
                className="select select-row-num"
                name="num"
                onChange={e =>
                  setState({ ...state, limit: Number(e.target.value) })
                }
              >
                <option value="5" defaultValue>
                  Prikaži 5
                </option>
                <option value="10">Prikaži 10</option>
                <option value="25">Prikaži 25</option>
                <option value="50">Prikaži 50</option>
              </select>
              <DropdownIcon2 className="select-arrow" />
            </div>
          </div>

          <div className="wrapper max-width-search-input">
            <div className="search-wrapper">
              <CustomInput2
                label=""
                placeholder="Pronađi korisnika..."
                name="search"
                onChange={handleSearch}
                value={search}
                className="input-class"
                type="text"
              />
              <button className="search">
                <SearchIcon fill="currentColor" />
              </button>
            </div>
            <button
              className="settings-btn"
              onClick={() => setFilterModal({ ...filterModal, open: true })}
            >
              <FiltersIcon fill="currentColor" />
            </button>
          </div>
        </div>
      </header>

      <NewTable
        onSelection={onSelection}
        head={[
          { key: 'id', id: 1, name: 'ID', sort: "id" },
          { key: 'full_name', id: 2, name: 'Naziv kompanije', sort: "full_name" },
          { key: 'phone', id: 3, name: 'Telefon' },
          { key: 'email', id: 4, name: 'Email', sort: "email" },
          { key: 'location', id: 5, name: 'Lokacija' },
          { key: 'created_at', id: 6, name: 'Datum', sort: "created_at" },
          { key: 'eminent', id: 7, name: 'Istakni', center: true },
          { key: 'edit', id: 8, name: 'Izmjeni', center: true },
          { key: 'ads', id: 9, name: 'Oglasi', center: true },
          { key: 'archive', id: 10, name: 'Arhiviraj', center: true }
        ]}
        body={users}
        sort={sort}
        onRequestSort={onRequestSort}
      />

      <div className="pagination-right mt-20">
        {state ? (
          <Pagination
            count={Math.ceil(total / state.limit)}
            page={page}
            setPage={setPage}
          />
        ) : (
          ''
        )}
      </div>

      <Modal
        title="Filteri"
        open={filterModal.open}
        onClose={onFilterModalClose}
      >
        <FilterModal
          state={state}
          setState={setState}
          setPage={setPage}
          onClose={onFilterModalClose}
        />
      </Modal>

      <Modal
        title="Arhiviranje"
        open={archiveModal.open}
        onClose={onArchiveModalClose}
        size="medium"
      >
        <ArchiveModal archive={archive} onClose={onArchiveModalClose} />
      </Modal>

      <Modal
        title="Arhiviranje"
        open={bulkArchiveModal.open}
        onClose={onBulkArchiveModalClose}
        size="medium"
      >
        <ArchiveModal archive={bulkArchive} onClose={onBulkArchiveModalClose} />
      </Modal>
    </main>
  );
};

const FilterModal = ({ state, setState, setPage, onClose }) => {
  const [values, setValues] = useState({
    city_id: '',
    country_id: ''
  });

  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);

  const getCountries = async () => {
    const res = await GlobalAPI.countries();
    const formattedCountries = res.map(x => ({ value: x.id, label: x.name }));
    setCountries(formattedCountries);
  };

  const getCities = async country_id => {
    if (country_id) {
      setValues({ ...values, country_id });
      const res = await GlobalAPI.cities(country_id);
      const formattedCities = res.map(x => ({ value: x.id, label: x.name }));
      setCities(formattedCities);
      console.log(res);
    } else {
      setCities([]);
      setValues({ ...values, country_id: '', city_id: '' });
    }
  };

  const filter = () => {
    onClose();
    setPage(1);
    setState({ ...state, ...values });
  };

  const resetFilters = () => {
    onClose();
    setPage(1);
    setState({ ...state, country_id: null, city_id: null });
  };

  useEffect(() => {
    getCountries();
  }, []);

  return (
    <Fragment>
      <Select
        className="force-space-b"
        label="Izaberite državu"
        value={values.country_id}
        onChange={country_id => getCities(country_id)}
        options={countries}
      />
      <Select
        className="force-space-b"
        label="Izaberite grad"
        value={values.city_id}
        onChange={city_id => setValues({ ...values, city_id })}
        options={cities}
      />
      <Button className="force-space-b" onClick={filter}>
        Filtriranje
      </Button>
      <Button variant="text" onClick={resetFilters}>
        Resetuj Filtere
      </Button>
    </Fragment>
  );
};

const ArchiveModal = ({ onClose, archive }) => {
  const processArchive = async () => {
    await archive();
    onClose();
  };

  return (
    <Fragment>
      <p className="modal-center-text">
        Da li ste sigurni da želite da arhivirate izabrane stavke?
      </p>
      <div className="d-flex justify-between">
        <Button className="force-space-r" onClick={processArchive}>
          Da
        </Button>
        <Button grayscale onClick={onClose}>
          Ne
        </Button>
      </div>
    </Fragment>
  );
};

export default ActiveCompanies;
