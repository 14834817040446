import * as types from './actionTypes';
import * as jobsApi from '../../api/jobs';

export function createAdRequest() {
  return { type: types.CREATE_AD_REQUEST };
}

export function createAdSuccess(data) {
  return { type: types.CREATE_AD_SUCCESS, data };
}

export function createAdFailure(error) {
  return { type: types.CREATE_AD_FAILURE, error };
}

export function loadAllAdsRequest() {
  return { type: types.LOAD_ALL_ADS_REQUEST };
}

export function loadAllAdsSuccess(data) {
  return { type: types.LOAD_ALL_ADS_SUCCESS, data };
}

export function loadAllAdsFailure(error) {
  return { type: types.LOAD_ALL_ADS_FAILURE, error };
}

export function loadSingleAdRequest() {
  return { type: types.LOAD_SINGLE_AD_REQUEST };
}

export function loadSingleAdSuccess(data) {
  return { type: types.LOAD_SINGLE_AD_SUCCESS, data };
}

export function loadSingleAdFailure(error) {
  return { type: types.LOAD_SINGLE_AD_FAILURE, error };
}

export function archiveAdRequest() {
  return { type: types.ARCHIVE_AD_REQUEST };
}

export function archiveAdSuccess(data) {
  return { type: types.ARCHIVE_AD_SUCCESS, data };
}

export function archiveAdFailure(error) {
  return { type: types.ARCHIVE_AD_FAILURE, error };
}

/************ ACTIVE ADS **********************/

export function loadActiveAdsRequest() {
  return { type: types.LOAD_ACTIVE_ADS_REQUEST };
}

export function loadActiveAdsSuccess(data) {
  return { type: types.LOAD_ACTIVE_ADS_SUCCESS, data };
}

export function loadActiveAdsFailure(error) {
  return { type: types.LOAD_ACTIVE_ADS_FAILURE, error };
}
/**********************************************/

/************ ARCHIVE ADS **********************/

export function loadArchivedAdsRequest() {
  return { type: types.LOAD_ARCHIVED_ADS_REQUEST };
}

export function loadArchivedAdsSuccess(data) {
  return { type: types.LOAD_ARCHIVED_ADS_SUCCESS, data };
}

export function loadArchivedAdsFailure(error) {
  return { type: types.LOAD_ARCHIVED_ADS_FAILURE, error };
}
/**********************************************/

export function getAllAds(data) {
  return function (dispatch) {
    dispatch(loadAllAdsRequest());
    return jobsApi
      .getAdminCompaniesAd(data)
      .then(res => {
        if (res.success) {
          dispatch(loadAllAdsSuccess(res));
        }
      })
      .catch(error => {
        dispatch(loadAllAdsFailure(error));
      });
  };
}

export function getAllActiveAds(data) {
  return function (dispatch) {
    dispatch(loadActiveAdsRequest());
    return jobsApi
      .getAdminCompaniesAd({ ...data, is_archived: 0 })
      .then(res => {
        if (res.data) {
          dispatch(loadActiveAdsSuccess(res));
        }
      })
      .catch(error => {
        dispatch(loadActiveAdsFailure(error));
      });
  };
}

export function getAllArchivedAds(data) {
  return function (dispatch) {
    dispatch(loadArchivedAdsRequest());
    return jobsApi
      .getAdminCompaniesAd({ ...data, is_archived: 1 })
      .then(res => {
        if (res.data) {
          dispatch(loadArchivedAdsSuccess(res));
        }
      })
      .catch(error => {
        dispatch(loadArchivedAdsFailure(error));
      });
  };
}

export function getSingleAd(data) {
  return function (dispatch) {
    dispatch(loadSingleAdRequest());
    return jobsApi
      .getSingleAd(data)
      .then(res => {
        if (res.data) {
          dispatch(loadSingleAdSuccess(res));
        }
      })
      .catch(error => {
        dispatch(loadSingleAdFailure(error));
      });
  };
}

export function archiveAd(data) {
  return function (dispatch) {
    dispatch(archiveAdRequest());
    return jobsApi
      .archiveAd(data)
      .then(res => {
        if (res.success) {
          dispatch(archiveAdSuccess(res));
        }
      })
      .catch(error => {
        dispatch(archiveAdFailure(error));
      });
  };
}

export function createAd(data) {
  return function (dispatch) {
    dispatch(createAdRequest());
    return jobsApi
      .createAd(data)
      .then(res => {
        if (res.success) {
          dispatch(createAdSuccess(res));
        }
      })
      .catch(error => {
        dispatch(createAdFailure(error));
      });
  };
}
