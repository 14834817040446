import React from 'react';
import { ButtonWrapper } from './styles';

const Button = ({
  children,
  color = 'primary',
  variant = 'contained',
  grayscale,
  ...props
}) => {
  return (
    <ButtonWrapper variant={variant} color={color} grayscale={grayscale} {...props}>
      {children}
    </ButtonWrapper>
  );
};

export default Button;