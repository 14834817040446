import React, { useEffect, useState, useRef } from 'react';
import localizedStrings from '../utils/localizedStrings';
import { CustomInput2 } from '../components/Inputs';
import { PaperPlaneIcon, SearchIcon } from '../components/Icons';
import {
  getMessages,
  sendMessage,
  getConversation
} from '../redux/actions/userActions';
import { connect } from 'react-redux';

const Messages = ({
  messages,
  loadMessages,
  userID,
  conversation,
  profile_image,
  sendSingleMessage,
  loadConversation
}) => {
  const [params, setParams] = useState({ offset: 0, limit: 5 });
  const [message_body, setMessageBody] = useState('');
  const [company_name, setCompanyName] = useState('');
  const [senderID, setSenderID] = useState(null);

  const bottom = useRef(null);

  useEffect(() => {
    loadMessages(params);
  }, [params, loadMessages]);

  useEffect(() => {
    bottom.current.scrollIntoView({ behavior: 'smooth' });
  }, [conversation]);

  const handleInput = e => {
    setMessageBody(e.target.value);
  };

  const openConversation = (e, item) => {
    const data = {
      sender_id: item.sender_id,
      offset: 0,
      limit: 5
    };
    setCompanyName(item.full_name);
    setSenderID(item.sender_id);
    loadConversation(data);
  };

  const sendMsg = () => {
    const data = {
      title: 'test',
      text: message_body,
      sender_id: senderID
    };
    sendSingleMessage(data);
    setMessageBody('');
  };

  const searchMessages = (e, name) => {
    let value = e.target.value;
    setParams(prevState => {
      return { ...prevState, offset: 0, term: value };
    });
  };

  return (
    <main id="mainContent" className="xl-resize">
      <div id="close-area"></div>
      <header>
        <div className="title-box">
          <h1>{localizedStrings.messages.title}</h1>
          <p>{localizedStrings.messages.description}</p>
        </div>
      </header>

      <div className="container-flex">
        <div className="container-half w-lg-100 bs">
          <div className="container-header container-header-wrap">
            <h2>{localizedStrings.messages.header}</h2>
            <div className="search-box-wrapper">
              <div className="wrapper">
                <div className="search-wrapper">
                  <input
                    type="text"
                    placeholder={localizedStrings.messages.placeholder}
                    className="input-class"
                    onChange={searchMessages}
                  />
                  <button className="search">
                    <SearchIcon />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="container-body height-550">
            <div className="messages-preview-all">
              {messages.length > 0 ? (
                messages.map((e, index) => {
                  return (
                    <button
                      className={`show-msg-btn    ${e.seen === 1 ? ' ' : ' unread '
                        } ${e.sender_id === senderID ? ' active' : ' '}`}
                      key={index}
                      onClick={event => openConversation(event, e)}
                    >
                      <div
                        className={`logo-box   ${e.seen === 1 ? ' ' : ' unread '
                          }  ${e.sender_id === senderID ? ' active' : ' '}`}
                      >
                        <img
                          src={
                            e.profile_image
                              ? e.profile_image
                              : '/images/mockup/profile-img-placeholder.png'
                          }
                          alt="logo"
                        />
                      </div>
                      <div className="msg-preview">
                        <h5>{e.full_name ? e.full_name : ' - '}</h5>
                        <p>{e.text ? e.text : ' - '}</p>
                      </div>
                      <p className="date">
                        {' '}
                        {e.created_at
                          ? e.created_at.substring(8, 10) +
                          '.' +
                          e.created_at.substring(5, 7) +
                          '.' +
                          e.created_at.substring(0, 4) +
                          '  u  ' +
                          e.created_at.substring(11, 16)
                          : ' - '}
                      </p>
                    </button>
                  );
                })
              ) : (
                <p>{localizedStrings.messages.no_messages}</p>
              )}
            </div>
          </div>
        </div>

        <div className="container-half w-lg-100 bs">
          <div className="container-header">
            <h2>{company_name ? company_name : ' - '}</h2>
          </div>
          <div className="container-body height-550">
            <div id="msgID-1" className="conversation">
              {conversation.length > 0 ? (
                conversation.map((e, index) => {
                  return (
                    <div
                      className={`${userID === e.created_by ? 'reviever' : 'sender'
                        }`}
                      key={index}
                    >
                      <p>{e.text ? e.text : ' - '}</p>
                      <div className="img-date-placeholder">
                        <div className="img-box">
                          <img
                            src={
                              e.profile_image
                                ? e.profile_image
                                : '/images/mockup/profile-img-placeholder.png'
                            }
                            alt="logo"
                          />
                        </div>
                        <p className="date">
                          {e.created_at
                            ? e.created_at.substring(8, 10) +
                            '.' +
                            e.created_at.substring(5, 7) +
                            '.' +
                            e.created_at.substring(0, 4) +
                            '  u  ' +
                            e.created_at.substring(11, 16)
                            : ' - '}
                        </p>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>{localizedStrings.messages.no_messages}</p>
              )}
              <span ref={bottom}></span>
            </div>

            <div className="write-message-wrapper">
              <div className="img-wrapper">
                <img
                  src={
                    profile_image
                      ? profile_image
                      : '/images/mockup/profile-img-placeholder.png'
                  }
                  alt="profile "
                />
              </div>

              <CustomInput2
                name="message_body"
                value={message_body}
                className="textarea-message"
                onChange={handleInput}
              />

              {/* <div
                contentEditable="true"
                dangerouslySetInnerHTML={{
                  __html: `${message_body ? message_body : ''}`,
                }}
                onChange={handleInput}
                name="message_body"
                value={message_body}
                className="textarea-message textarea-message-div"
              /> */}

              <button
                name="send"
                id="sendMessage"
                className="send-btn"
                onClick={sendMsg}
              >
                <PaperPlaneIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

function mapStateToProps(state) {
  return {
    profile_image: state.user ? state.user.profile_image : null,
    messages: state.messages.messages || [],
    userID: state.user ? state.user.id : null,
    conversation: [...state.conversation.messages].reverse() || []
  };
}

const mapDispatchToProps = {
  loadMessages: getMessages,
  sendSingleMessage: sendMessage,
  loadConversation: getConversation
};

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
