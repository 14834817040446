import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function singleAd(state = initialState.single_ad, action) {
  switch (action.type) {
    case types.LOAD_SINGLE_AD_SUCCESS:
      return action.data;
    case types.LOAD_SINGLE_AD_FAILURE:
      return {};
    default:
      return state;
  }
}
