import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function archivedUsersReducer(
  state = initialState.archivedUsers,
  action
) {
  switch (action.type) {
    case types.LOAD_ARCHIVED_USERS_SUCCESS:
      return action.data;
    case types.LOAD_ARCHIVED_USERS_FAILURE:
      return [];
    default:
      return state;
  }
}
