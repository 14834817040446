import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { useHistory } from 'react-router';
import { AdminAPI, AuthAPI, GlobalAPI } from '../../../api/v2';
import initialState from './initialState';

export const fetchUser = createAsyncThunk('admin/getInfo', async () => {
  const response = await AdminAPI.getInfo();
  return response;
});

export const fetchCommonData = createAsyncThunk(
  'global/commonData',
  async () => {
    const response = await GlobalAPI.commonData();
    return response;
  }
);

export const slice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    logout: async state => {
      await AuthAPI.logout();
      state.user = null;
      axios.defaults.headers.common['Authorization'] = null;
      localStorage.removeItem('Authorization');
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.user = action.payload;
    });
    builder.addCase(fetchCommonData.fulfilled, (state, action) => {
      state.common = action.payload;
    });
  }
});

export const { actions } = slice;

export default slice.reducer;
